import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DeleteOutline } from '@mui/icons-material';
import { v4 as uuid } from 'uuid';


// api
import { addMaintenanceSubService, addHousekeepingSubService } from '../../api/admin/service';

const initialState = {
  serviceId: '',
  subServices: []
};


export default function AddSubServiceDialog({
  dialogOpen,
  setDialogOpen,
  action,
  setSnackbarMessage,
  setSnackbarOpen,
  housekeepingService,
  maintenanceService,
  setHousekeepingService,
  setMaintenanceService
}) {
  const theme = useTheme();

  const [inputs, setInputs] = useState(initialState);
  console.log('chk', housekeepingService, maintenanceService);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([{ id: uuid() }]);

  const { hospitalAccount } = useSelector((state) => state.account)

  const [subSectionItems, setSubSectionItems] = useState([])


  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log('submit', inputs);

    setIsLoading(true)
    let postData = inputs.subServices
    if(action === 'housekeeping' && housekeepingService.find((item) => item.serviceId === inputs.serviceId)?.subServices?.length)
    {
      console.log('hks');
      postData = [...inputs.subServices, ...housekeepingService.find((item) => item.serviceId === inputs.serviceId)?.subServices] 
    }
    if(action === 'maintenance' && maintenanceService.find((item) => item.serviceId === inputs.serviceId)?.subServices?.length)
    {
      console.log('ms', [...inputs.subServices, ...maintenanceService.find((item) => item.serviceId === inputs.serviceId)?.subServices]);
      postData = [...inputs.subServices, ...maintenanceService.find((item) => item.serviceId === inputs.serviceId)?.subServices] 
    }
    try {
      const { data } = action === 'housekeeping' ? await addHousekeepingSubService(hospitalAccount._id, { serviceId: inputs.serviceId, subServices: postData }) : await addMaintenanceSubService(hospitalAccount._id, {serviceId: inputs.serviceId, subServices: postData  })
      if (data.success) {
        // console.log({ data });
        if( action === 'housekeeping')
        {
          setHousekeepingService(data?.houseKeepingData?.services)
        }
        else{
          setMaintenanceService(data?.maintenanceData?.services)
        }
        setIsLoading(false);

        setDialogOpen(false);
        setSnackbarMessage({ msg: data.message, success: false });
        setSnackbarOpen(true);
      } else {
        console.log('here');
        setIsLoading(false);
        setSnackbarMessage({ msg: data.message, success: false });
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.log('there');
      setIsLoading(false);
      setSnackbarMessage({ msg: error.message, success: false });
      setSnackbarOpen(true);
    }
  };
  const handleSelect = (event) => {
    console.log(event.target.value);
    setInputs({ ...inputs, serviceId: event.target.value })
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleInputChange = (e, item) => {
    console.log(item);
    setSubSectionItems(item)
   };
   
  useEffect(() => {
    console.log({subSectionItems});
    setInputs({...inputs, subServices: subSectionItems.map((item) => ({subServiceTitle : item}))})
   }, [subSectionItems])

  useEffect(() => {
    console.log({housekeepingService} , {maintenanceService});
    setInputs(initialState);
  }, [dialogOpen])


  return (
    <Box>
      <Dialog open={dialogOpen} onClose={handleClose} maxWidth={600}>

        <form onSubmit={handleSubmit}>
          <DialogContent>

            <Stack spacing={2} >
              <Typography variant='h5'>Add {action === 'housekeeping' ? 'Housekeeping' : 'Maintenance'} Sub service</Typography>
            </Stack>
            <FormControl fullWidth variant="standard">
              <InputLabel id="demo-simple-select-label">Choose Section Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={inputs.serviceId}
                label="Choose Account"
                onChange={handleSelect}
              >
                {action === 'housekeeping' ?

                  housekeepingService.map((item) =>
                    <MenuItem key={item.serviceId} value={item.serviceId}>{item.serviceTitle}</MenuItem>
                  )

                  :

                  maintenanceService.map((item) =>
                    <MenuItem key={item.serviceId} value={item.serviceId}>{item.serviceTitle}</MenuItem>
                  )

                }
              </Select>
            </FormControl>

            <Box my={2}  >
              
              <Autocomplete
                multiple
                freeSolo
                id="tags-outlined"
                options={[]}
                onChange={handleInputChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Add sub service"
                  />
                )}
              />
              <Typography variant='subtitle2' color='red'>*Enter to add sub service items</Typography>
            </Box>


          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant='contained'>Cancel</Button>
            <LoadingButton loading={isLoading} type='submit' variant='contained'>Submit</LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  )
}
