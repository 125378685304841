import {
  SET_ACCOUNT_STATE,
} from "../constants/action-type";


export const setAccountStateAction = (payload) => {
  return {
    type: SET_ACCOUNT_STATE,
    payload,
  };
};
