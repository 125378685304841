import React from 'react'

import { CircularProgress, MenuItem, Popover, Typography } from '@mui/material'
import Iconify from '../iconify'

const MenuPopover = ({ menuOpen, setMenuOpen, selectedItem, handleDelete, handleSuspend, handleDialogOpen, from }) => {

    const handleCloseMenu = () => {
        setMenuOpen(false);
    }

    return (
        <Popover
            open={Boolean(menuOpen)}
            anchorEl={menuOpen}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
                sx: {
                    p: 1,
                    width: 140,
                    '& .MuiMenuItem-root': {
                        px: 1,
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                },
            }}
        >

            <MenuItem onClick={() => {handleCloseMenu() 
                handleDialogOpen(selectedItem, 'EDIT')}}>
                <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                Edit
            </MenuItem>
            {(from !== 'superAdminList' && from === 'adminUserList') &&
                <MenuItem onClick={() => {handleCloseMenu() 
                    handleSuspend(selectedItem._id)}}>
                    <Iconify icon={'streamline:interface-edit-view-off-disable-eye-eyeball-hide-off-view'} sx={{ mr: 2 }} />
                    {selectedItem.status === 'active' ? "Suspend" : "Activate"}
                </MenuItem>
            }{(from !== 'superAdminList' && from !== 'adminUserList') &&
                <MenuItem onClick={() => {handleCloseMenu() 
                    handleSuspend(selectedItem._id)}}>
                    <Iconify icon={'streamline:interface-edit-view-off-disable-eye-eyeball-hide-off-view'} sx={{ mr: 2 }} />
                    Suspend
                </MenuItem>}
            <MenuItem sx={{ color: 'error.main' }} onClick={() => {handleCloseMenu() 
            handleDelete(selectedItem._id)}}>
                <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                {false ? <CircularProgress size={20} /> : <Typography>Delete</Typography>}
            </MenuItem>
        </Popover>
    )
}

export default MenuPopover