import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import axios from 'axios';
import { useSelector } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// @mui
import { styled } from '@mui/material/styles';
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Box,
    Rating,
    CircularProgress,
    Chip,
    TableHead,
    Collapse,
} from '@mui/material';
import { PhoneInTalk, Rowing } from '@mui/icons-material';
import Row from '@mui/material/Paper';
import { LoadingButton } from '@mui/lab';

import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import QrCodeIcon from '@mui/icons-material/QrCode';
// dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// Rating Icons
import PropTypes from 'prop-types';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import CustomSnackBar from '../../components/snackbar';
import MenuPopover from '../../components/popover/menuPopover';
// sections
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import QRLicenseDialog from '../../sections/dialogs/qrLicenseDialog';
import MuteDialog from '../../sections/dialogs/MuteDialog';

// api
import { getQRLicense, exportQRLicense, getFeedback } from '../../api/superadmin/license';
// ----------------------------------------------------------------------


const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
        color: theme.palette.action.disabled
    },
}));

const customIcons = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon color="error" fontSize="10px" />,
        label: 'Very Dissatisfied',
    },
    2: {
        icon: <SentimentDissatisfiedIcon color="error" fontSize="10px" />,
        label: 'Dissatisfied',
    },
    3: {
        icon: <SentimentSatisfiedIcon color="warning" fontSize="10px" />,
        label: 'Neutral',
    },
    4: {
        icon: <SentimentSatisfiedAltIcon color="success" fontSize="10px" />,
        label: 'Satisfied',
    },
    5: {
        icon: <SentimentVerySatisfiedIcon color="success" fontSize="10px" />,
        label: 'Very Satisfied',
    },
};

function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
    value: PropTypes.number.isRequired,
};




export default function Feedback() {

    const account = useSelector((state) => state.account.hospitalAccount)

    const [isLoading, setIsLoading] = useState(false)
    // snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState(null)

    const [open, setOpen] = useState(null)
    const [feedback, setFeedback] = useState(null)


    const toggle = () => {
        setOpen(open);
        // console.log(Feedbacks);
    };

    function Row(props) {
        const { item } = props;
        const [open, setOpen] = React.useState(false);
        return (
            <>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={item._id}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {item.mobileNo}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {item.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {item.updatedAt.split('T')[0]}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {item.callOptions && <PhoneInTalk fontSize='small'/>}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>

                                <>
                                    {/* <Typography fontWeight={1000} variant="h6" gutterBottom component="div">
                                                        USERNAME
                                                    </Typography> */}
                                    <Table size="small" aria-label="purchases">
                                        <TableHead  >

                                            <TableRow>
                                                <TableCell>Question</TableCell>
                                                
                                                <TableCell>Rating</TableCell>
                                                <TableCell>Overall Comments</TableCell>
                                            </TableRow>

                                        </TableHead>
                                        <TableBody>
                                            {item.responses?.map((fbresp,ind) =>
                                                <TableRow key={fbresp.responseId}>
                                                    <TableCell >{fbresp.question}</TableCell>
                                                   
                                                    <TableCell >
                                                        <StyledRating
                                                            name="rating"
                                                            value={Number(fbresp.rating)}
                                                            size='large'
                                                            readOnly
                                                            IconContainerComponent={IconContainer}
                                                            getLabelText={(value) => customIcons[value].label}
                                                            highlightSelectedOnly
                                                        />
                                                    </TableCell>
                                                    <TableCell>{item.comments[ind]}</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        )
    };

    const fetchData = async () => {
        setIsLoading(true)
        try {
            const { data } = await getFeedback(account._id)
            if (data.success) {

                setFeedback(data.response)
                console.log(feedback);
                setIsLoading(false);

            }
            else {
                setSnackbarMessage({ msg: "Request failed", success: false });
                setSnackbarOpen(true);
                setIsLoading(false);

            }

        }
        catch (error) {
            setIsLoading(false)
            console.log("err ", error);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])




    return (
        <>
            <Helmet>
                <title> Feedback | Teller One </title>
            </Helmet>

            {!isLoading ? (

                <Box sx={{ height: '100%' }}>

                    <Box >
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <Typography variant="h4" gutterBottom>
                                Feedback
                            </Typography>
                        </Stack>
                        <Box sx={{ backgroundColor: 'aliceblue' , borderRadius:2, borderColor:'ActiveBorder'}}>
                            {feedback?.map((item) =>
                                <Row key={item._id} item={item} />
                            )}
                        </Box>
                    </Box>
                </Box>

            ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}><CircularProgress /></Box>
            )
            }
        </>
    );
}
