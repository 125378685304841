
// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------
import user from '../../../assets/icons/navbar/ic_user.svg';

import icDashboard from '../../../assets/icons-new/ic-dashboard.svg';
import icAdmin from '../../../assets/icons-new/ic-admin.svg';
import icQr from '../../../assets/icons-new/ic-qr.svg';
import icAccount from '../../../assets/icons-new/ic-account.svg';
import icService from '../../../assets/icons-new/ic-service.svg';
import icRequest from '../../../assets/icons-new/ic-request.svg';
import icFeedback from '../../../assets/icons-new/ic-feedback.svg';
import icModule from '../../../assets/icons-new/ic-module.svg';
import icQuestion from '../../../assets/icons-new/ic-questions.svg';
import icInfo from '../../../assets/icons-new/ic-info.svg';
import icUser from '../../../assets/icons-new/ic-user.svg';

const icon = (name) => <SvgColor src={name} sx={{ width: 1, height: 1 }} />;

// super admin panel

export const adminHeadNavConfig = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: icon(icDashboard),
  },
  {
    title: 'Super Admins',
    path: '/superadmin',
    icon: icon(icUser),
  },
  {
    title: 'Accounts',
    path: '/accounts',
    icon: icon(icAccount),
  },
];

export const liceneseManagerNavConfig = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: icon(icDashboard),
  },
  {
    title: 'Accounts',
    path: '/accounts',
    icon: icon(icAccount),
  },
  {
    title: 'Customer Admin',
    path: '/adminmanager',
    icon: icon(icUser),
  },
  {
    title: 'QR License',
    path: '/qrlicense',
    icon: icon(icQr),
  },
  {
    title: 'Admin License',
    path: '/adminlicense',
    icon: icon(icAdmin),
  },
];

export const financeControllerNavConfig = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: icon(icDashboard),
  },
  {
    title: 'Accounts',
    path: '/accounts',
    icon: icon(icAccount),
  },
  {
    title: 'Customer Admin',
    path: '/adminmanager',
    icon: icon(icUser),
  },
  {
    title: 'QR License',
    path: '/qrlicense',
    icon: icon(icQr),
  },
  {
    title: 'Admin License',
    path: '/adminlicense',
    icon: icon(icAdmin),
  },
];

export const accountManagerNavConfig = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: icon(icDashboard),
  },
  {
    title: 'Accounts',
    path: '/accounts',
    icon: icon(icAccount),
  },
];

// admin panel

export const adminManagerNavConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: icon(icDashboard),
  },
  {
    title: 'Requests',
    path: '/requests',
    icon: icon(icRequest),
  },
  {
    title: 'QR License',
    path: '/qrlicense',
    icon: icon(icQr),
  },
  // {
  //   title: 'Modules',
  //   path: '/modules',
  //   icon: icon(icModule),
  // },
  {
    title: 'Feedbacks',
    path: '/feedback-response',
    icon: icon(icFeedback),
  },
  {
    title: 'Admin Users',
    path: '/adminuser',
    icon: icon(icUser),
  },
  {
    title: 'Hospital Information',
    path: '/hospital-information',
    icon: icon(icInfo),
  },
  {
    title: 'Services',
    path: '/service',
    icon: icon(icService),
  },
  {
    title: 'Feedback Questions',
    path: '/feedback',
    icon: icon(icQuestion),
  },
  
];

export const powerUserNavConfig = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: icon(icDashboard),
  },
  {
    title: 'Requests',
    path: '/requests',
    icon: icon(icRequest),
  }
];

export const userNavConfig = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: icon(icDashboard),
  },
  {
    title: 'Requests',
    path: '/requests',
    icon: icon(icRequest),
  }
];

export const viewerNavConfig = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: icon(icDashboard),
  },
  {
    title: 'Requests',
    path: '/requests',
    icon: icon(icRequest),
  },
  {
    title: 'Feedbacks',
    path: '/feedback-response',
    icon: icon(icFeedback),
  },
];

