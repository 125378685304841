import {
  
    SET_ACCOUNT_STATE,
  } from "../constants/action-type";

  

  const INIT_STATE = {
    hospitalAccount: ''
  };

  const AccountReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case SET_ACCOUNT_STATE:
        return {
          ...state,
          ...action.payload,
        };

      default:
        return state;
    }
  };

  export default AccountReducer;
