import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { addAdminUser, updateAdminUser } from '../../api/admin/adminuser';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  roleId: '',
  departmentIds: [],
  rooms: [],
  contactNo: '',
  city: '',
  state: '',
  country: '',
  zip: '',
};

export default function AdminUserDialog({
  dialogOpen,
  setDialogOpen,
  setDialogOpen1,
  selectedItem,
  action,
  setSnackbarMessage,
  setSnackbarOpen,
  setAdminUser,
  services,
  qrLicense
}) {

  // console.log('ins', services, qrLicense);
  const commonData = useSelector((state) => state.adminUser.commonData)
  const hospitalId = useSelector((state) => state.account.hospitalAccount._id)
  const theme = useTheme();
  const [inputs, setInputs] = useState(initialState);
  const [serviceTitle, setServiceTitle] = useState('');
  const [subRole, setSubRole] = useState('');
  const [errorMessage, setErrorMessage] = useState({
    name: 'Title cannot exceed 100 characters',
    usergroup: 'Please select atleast one usergroup',
    description: 'description cannot exceed 1000 characters',
  });
  const [tempInputs, setTempInputs] = useState();
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validate = () => {

    if (inputs.name.length > 100) {
      setSnackbarMessage({ msg: 'Validation Failed, Title cannot exceed 100 characters', success: false })
      setSnackbarOpen(true)
      return false;
    }
    if (inputs.description.length > 1000) {
      setSnackbarMessage({ msg: 'Validation Failed, Message cannot exceed 1000 characters', success: false })
      setSnackbarOpen(true)
      return false;
    }
    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const inputData = {...inputs}
    inputData.departmentIds = inputData.departmentIds.map((item) => item.serviceId)
    console.log({inputData});
    setIsLoading(true)

    try {
      const { data } = action === 'CREATE' ? await addAdminUser(inputData) : await updateAdminUser(selectedItem._id, inputData)
      if (data.success) {
        console.log({ data });
        setAdminUser(data.hospitalUsers)
        setIsLoading(false);
        setSnackbarMessage({ msg: data.message, success: true });
        setSnackbarOpen(true);
        setDialogOpen(false);

      } else {
        setIsLoading(false);
        setSnackbarMessage({ msg: data.message, success: false });
        setSnackbarOpen(true);
      }
    } catch (error) {
      setIsLoading(false);
      setSnackbarMessage({ msg: error.message, success: false });
      setSnackbarOpen(true);
    }
  };

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value })
  };

  const handleSelectRole = (event) => {
    setInputs({ ...inputs, roleId: event.target.value })
  };

  const handleSelectDepartment = (event) => {
    setInputs({ ...inputs, departmentIds: event.target.value })
  };

  const handleSelectRooms = (event) => {
    setInputs({ ...inputs, rooms: event.target.value })
  };

  const handleClose = () => {
    setDialogOpen(false);
  };
  const handleSkip = () => {
    setDialogOpen(false);
    setDialogOpen1(true);
  };
  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
    setTempInputs({ ...tempInputs, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    // console.log('in hook', services.filter((item) => selectedItem.departmentIds.includes("6503e2a7f2e335eb18e1fc24")));
    if (action !== 'CREATE') {
      // console.log('am in', selectedItem, services);
      setInputs({
        firstName: selectedItem.firstName,
        lastName: selectedItem.lastName,
        email: selectedItem.email,
        role: selectedItem.role,
        roleId: selectedItem.roleId,
        departmentIds: services.filter((item) => selectedItem.departmentIds.includes(item.serviceId)),
        rooms: selectedItem.rooms,
        contactNo: selectedItem.contactNo,
        city: selectedItem.city,
        hospitalId
      })

    }
    else {
      setInputs({ ...initialState, hospitalId });

    }
  }, [dialogOpen])

  return (
    <Box>
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>{action === 'CREATE' ? 'Create Admin User' : 'Edit Admin User'} </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent >

            <TextField
              autoFocus
              margin="dense"
              name="firstName"
              label="First Name"
              type="text"
              fullWidth
              variant="standard"
              value={inputs.firstName}
              onChange={handleInputChange}
             
            />

            <TextField

              margin="dense"
              name="lastName"
              label="Last Name"
              type="text"
              fullWidth
              variant="standard"
              value={inputs.lastName}
              onChange={handleInputChange}
            />

            <TextField
              margin="dense"
              name="email"
              label="Email"
              type="email"
              fullWidth
              variant="standard"
              value={inputs.email}
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              name="contactNo"
              label="Phone"
              inputProps={{ maxLength: 10 }}
              fullWidth
              variant="standard"
              value={inputs.contactNo}
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              name="city"
              label="City"
              type="text"
              fullWidth
              variant="standard"
              value={inputs.city}
              onChange={handleInputChange}
            />

            <Stack spacing={2}>           
              <FormControl variant="standard"  sx={{ width: 500 }}>
                <InputLabel id="demo-simple-select-standard-label">Role</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  value={inputs.roleId}
                  onChange={handleSelectRole}
                  label="Role"
                >
                  {commonData.adminPanelRoles.map((item) => (
                    <MenuItem key={item.adminPanelRoleId} value={item.adminPanelRoleId}>{item.adminPanelRoleName}</MenuItem>
                  ))}

                </Select>
              </FormControl>
              {(inputs.roleId === '649e66f4b41beae4e716c29b' || inputs.roleId === '649e6703b41beae4e716c29e') &&
                <>
                 
                  <FormControl variant='standard' sx={{ width: 500 }}>
                    <InputLabel id="demo-multiple-chip-label">Select Services</InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      value={inputs.departmentIds}
                      onChange={handleSelectDepartment}
                      input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value.serviceid} label={value.serviceTitle} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                       {services.map((item) => (
                        <MenuItem key={item.serviceid} value={item} style={getStyles(item.serviceTitle, inputs.departmentIds, theme)}>{item.serviceTitle}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl variant='standard' sx={{ width: 500 }}>
                    <InputLabel id="demo-multiple-chip-label">Select Rooms</InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      value={inputs.rooms}
                      onChange={handleSelectRooms}
                      input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {[... new Set(qrLicense.map(x => x.roomNo))].map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          style={getStyles(name, inputs.rooms, theme)}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>}
                </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant='contained' >Cancel</Button>
            <LoadingButton loading={isLoading} type='submit' variant='contained' >{action === 'CREATE' ? 'Create' : 'Update'}</LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  )
}
