// Login Api
export const LOGIN_URL = "/admin/login";
export const LOGOUT_URL = "/admin/logout";
export const SEND_EMAIL_URL = "/admin/forget-password";
export const RESET_PASSWORD_URL = "/admin/reset-password";

// Dashboard Api
export const DASHBOARD_DATA_API_URL = "/dashboard";

// Accounts Api
export const ACCOUNTS_API_URL = "/hospital-account";

// Super Admin APIs
export const SUPER_ADMIN_API_URL = "/super-admin";

// Admin Manager APIs
export const ADMIN_MANAGER_API_URL = "/admin-manager";

// Admin User APIs
export const ADMIN_USER_API_URL = "/hospital-user";

export const COMMON_DATA_API_URL = "/common-data";

export const ACCOUNT_SUMMARY_API_URL = "/hospital-summary";

// QR License Api

export const QR_LICENSE_API_URL = "/qrcode" 

// Feedback Api

export const FEEDBACK_API_URL = "/feedback-response" 

// Add Services Api

export const ADD_SERVICES_API_URL = "/hk-maintenance/services"

// Add Sub Services Api

export const ADD_SUB_SERVICES_API_URL = "/hk-maintenance/sub-services"

// Admin License Api

export const ADMIN_LICENSE_API_URL = "/admin-license" 

// Account Api

export const GET_ACCOUNT_ITEM_URL = "/hospital-account"

export const HOSPITAL_INFORMATION_API_URL = "/hospital-information"

export const SPECIALITY_API_URL = "/specialties"

export const DOCTOR_API_URL = "/doctors"

export const UPLOAD_AMENITY_API_URL = "/uploadAmenitiesImages"

export const DELETE_AMENITY_API_URL = "/deleteAmenitiesImage"

export const UPLOAD_WHATS_NEW_API_URL = "/uploadWhatsNewImages"

export const DELETE_WHATS_NEW_API_URL = "/deleteWhatsNewImage"



export const FEEDBACK_SECTION_API_URL = "/feedback-section-type"

export const FEEDBACK_QUESTION_API_URL = "/feedback-question"

export const HOUSEKEEPING_SERVICE_API_URL = "/hk-maintenance/housekeeping-services"

export const HOUSEKEEPING_SUB_SERVICE_API_URL = "/hk-maintenance/housekeeping-sub-services"

export const MAINTENANCE_SERVICE_API_URL = "/hk-maintenance/maintenance-services"

export const MAINTENANCE_SUB_SERVICE_API_URL = "/hk-maintenance/maintenance-sub-services"

export const SERVICES_API_URL = "/hk-maintenance"

export const REQUEST_API_URL = "/hk-maintenance-request" 

export const REQUEST_STATUS_API_URL = "/hk-maintenance-request/change-status"

export const REQUEST_DATA_API_URL = "/admin-dashboard"
