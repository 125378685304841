import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Box, CircularProgress, Button, Stack, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField } from '@mui/material';
// components
import StarIcon from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';
import { DeleteOutline } from '@mui/icons-material';
import DeleteIcon from '../../assets/icons-new/ic-delete.svg';
import EditIcon from '../../assets/icons-new/ic-edit.svg';

// sections
import { addHousekeepingService, addHousekeepingSubService, addMaintenanceService, addMaintenanceSubService, getSerivces } from '../../api/admin/service';
import CustomSnackBar from '../../components/snackbar';
import AddServieceDialog from '../../sections/dialogs/addServiceDialog';
import AddSubServiceDialog from '../../sections/dialogs/addSubServiceDialog';

const getColor = ['secondary', 'info', 'warning', 'error']
// ----------------------------------------------------------------------

export default function ServiceSection() {
  const theme = useTheme();
  const { hospitalAccount } = useSelector((state) => state.account)

  const [isLoading, setIsLoading] = useState(false)

  const [housekeepingService, setHousekeepingService] = useState(false)
  const [houseKeepingSubService, setHouseKeepingSubService] = useState(false)
  const [maintenanceService, setMaintenanceService] = useState(false)
  const [maintenanceSubService, setMaintenanceSubService] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState(null)
  const [action, setAction] = useState('');
  const [dialogOpen1, setDialogOpen1] = useState(false);
  const [dialogOpen2, setDialogOpen2] = useState(false);
  const [editState, setEditState] = useState(false)
  const [tempValue, setTempValue] = useState(false)
  const [selectedId, setSelectedId] = useState(false)

  // Dialog
  const handleDialogOpen1 = (str) => {
    console.log('am in');
    setAction(str)
    setDialogOpen1(true);
  };

  // Dialog
  const handleDialogOpen2 = (str) => {
    console.log('am here');

    setAction(str)
    setDialogOpen2(true);
  };

  const handleEdit = (id, title, action) => {
    console.log('am here', id);
    setTempValue(title)
    setEditState(true);
    setSelectedId(id)

  };

  const handleEditSubmit = async (id, action) => {
    console.log('am edit here', id);
    let postData = [];
    if (action === 'housekeeping' && housekeepingService.length) {
      postData = housekeepingService.map((item) => { if (item.serviceId === id) { return { ...item, serviceTitle: tempValue } } return item })
    }
    if (action === 'maintenance' && maintenanceService.length) {
      postData = maintenanceService.map((item) => { if (item.serviceId === id) { return { ...item, serviceTitle: tempValue } } return item })
    }
    try {
      const { data } = action === 'housekeeping' ? await addHousekeepingService(hospitalAccount._id, { serviceType: "housekeeping", services: postData }) : await addMaintenanceService(hospitalAccount._id, { serviceType: "maintenance", services: postData })
      if (data.success) {
        if (action === 'housekeeping') {
          setHousekeepingService(data.services.services)
        }
        else {
          setMaintenanceService(data.services.services)
        }
        setEditState(false)
        setSnackbarMessage({ msg: data.message, success: false });
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage({ msg: data.message, success: false });
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage({ msg: error.message, success: false });
      setSnackbarOpen(true);
    }
  };

  const handleDelete = async (id, action) => {
    console.log('am here', id, action);
    let postData = [];
    if (action === 'housekeeping' && housekeepingService.length) {
      postData = housekeepingService.filter((item) => item.serviceId !== id)
    }
    if (action === 'maintenance' && maintenanceService.length) {
      postData = maintenanceService.filter((item) => item.serviceId !== id)
    }

    try {
      const { data } = action === 'housekeeping' ? await addHousekeepingService(hospitalAccount._id, { serviceType: "housekeeping", services: postData }) : await addMaintenanceService(hospitalAccount._id, { serviceType: "maintenance", services: postData })
      if (data.success) {
        if (action === 'housekeeping') {
          setHousekeepingService(data.services.services)
        }
        else {
          setMaintenanceService(data.services.services)
        }
        setSnackbarMessage({ msg: data.message, success: false });
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage({ msg: data.message, success: false });
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage({ msg: error.message, success: false });
      setSnackbarOpen(true);
    }
  };

  const handleSubDelete = async (id, subId, action) => {
    console.log('am here', id, action);
    let postData = [];
    if (action === 'housekeeping' && housekeepingService.length) {
      postData = housekeepingService.find((item) => item.serviceId === id).subServices.filter((it) => it.subServiceId !== subId)
      // postData = housekeepingService.map((item) => { if (item.serviceId === id) { return { ...item, subServices: item.subServices.filter((it) => it.subServiceId !== subId) } } return item })
      console.log({ postData });
    }
    if (action === 'maintenance' && maintenanceService.length) {
      postData = maintenanceService.find((item) => item.serviceId === id).subServices.filter((it) => it.subServiceId !== subId)

      // postData = maintenanceService.map((item) => { if (item.serviceId === id) { return { ...item, subServices: item.subServices.filter((it) => it.subServiceId !== subId) } } return item })
    }

    try {
      const { data } = action === 'housekeeping' ? await addHousekeepingSubService(hospitalAccount._id, { serviceId: id, subServices: postData }) : await addMaintenanceSubService(hospitalAccount._id, { serviceId: id, subServices: postData })
      if (data.success) {
        if (action === 'housekeeping') {
          setHousekeepingService(data?.houseKeepingData?.services)
        }
        else {
          setMaintenanceService(data?.maintenanceData?.services)
        }
        setSnackbarMessage({ msg: data.message, success: false });
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage({ msg: data.message, success: false });
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage({ msg: error.message, success: false });
      setSnackbarOpen(true);
    }
  };


  const fetchData = async () => {
    setIsLoading(true)
    try {
      const { data } = await getSerivces(hospitalAccount._id);
      if (data.success) {
        console.log({ data });
        if (data.housekeeping) {
          setHousekeepingService(data.housekeeping.services)
        }
        if (data.maintenance) {
          setMaintenanceService(data.maintenance.services)
        }

      }

      console.log('data1', data);

      setIsLoading(false)
    }
    catch (e) {
      setIsLoading(false)
      // console.log({ e });
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title> Service | Teller One </title>
      </Helmet>
      <Box sx={{ height: '100%' }}>
        {!isLoading ?
          <>
            <Typography variant="h5" gutterBottom>
              Services
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
              <Typography variant="h6" >
                Housekeeping Services
              </Typography>
              <Box>
                <Button variant="contained" sx={{ borderRadius: 4, mr: 2 }} onClick={() => handleDialogOpen1('housekeeping')}>
                  Add Service
                </Button>
                {housekeepingService && housekeepingService.length !== 0 &&
                  <Button variant="contained" sx={{ borderRadius: 4 }} onClick={() => handleDialogOpen2('housekeeping')}>
                    Add Sub Service
                  </Button>}
              </Box>
            </Stack>

            <List
              sx={{ width: '100%', bgcolor: '#D9D9D9', borderRadius: 2 }}
              aria-label="contacts"
            >
              {housekeepingService.length ? housekeepingService.map((item) =>
                <ListItem disablePadding key={item.serviceId} sx={{ bgcolor: '#FFF', ml: 1, mt: 1,  borderRadius: 2,  width: '98%' }} >

                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', p: 1 }} >

                    <Box>

                      {(editState && selectedId === item.serviceId) ? <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                        <TextField value={tempValue} onChange={(e) => setTempValue(e.target.value)} />
                        <Button sx={{ ml: 2 }} onClick={() => handleEditSubmit(item.serviceId, 'housekeeping')} variant='contained'>Submit</Button>
                      </Box>
                        :
                        <ListItemText primary={item.serviceTitle} />}
                    </Box>
                    <Box >
                      {item.subServices.map((it, ind) =>
                        <Box key={ind} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#D9D9D9', width: 180, px: 2, py:1, mt: 1, borderRadius: 4 }}>
                          <Typography >   {it.subServiceTitle} </Typography>
                          <CloseIcon fontSize='10px' sx={{ cursor: 'pointer' }} onClick={() => handleSubDelete(item.serviceId, it.subServiceId, 'housekeeping')} />
                        </Box>
                      )}
                    </Box>
                    <Box display='flex'>
                      <ListItemIcon sx={{ cursor: 'pointer' }} onClick={() => handleEdit(item.serviceId, item.serviceTitle, 'housekeeping')}>
                        <img src={EditIcon} alt='ico' width={16} />
                      </ListItemIcon>
                      <ListItemIcon sx={{ cursor: 'pointer' }} onClick={() => handleDelete(item.serviceId, 'housekeeping')}>
                        <img src={DeleteIcon} alt='ico' width={16} />
                      </ListItemIcon>
                    </Box>


                  </Box>


                </ListItem>

              ) : <Typography>No Item</Typography>}

            </List>


            <Stack direction="row" alignItems="center" justifyContent="space-between" my={2}>
              <Typography variant="h6" >
                Maintenance Services
              </Typography>
              <Box>
                <Button variant="contained" sx={{ borderRadius: 4, mr: 2 }} onClick={() => handleDialogOpen1('maintenance')}>
                  Add Service
                </Button>
                {maintenanceService && maintenanceService.length !== 0 &&
                  <Button variant="contained" sx={{ borderRadius: 4 }} onClick={() => handleDialogOpen2('maintenance')}>
                    Add Sub Service
                  </Button>}
              </Box>
            </Stack>

            <List
              sx={{ width: '100%', bgcolor: '#D9D9D9', borderRadius: 2 }}
              aria-label="contacts"
            >
              {maintenanceService ? maintenanceService.map((item) =>
                <ListItem disablePadding key={item.serviceId} sx={{ bgcolor: '#FFF', ml: 1, mt: 1,  borderRadius: 2 ,  width: '98%'}} >

                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', p: 1 }} >

                    <Box>

                      {(editState && selectedId === item.serviceId) ? <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',}}>
                        <TextField value={tempValue} onChange={(e) => setTempValue(e.target.value)} />
                        <Button onClick={() => handleEditSubmit(item.serviceId, 'maintenance')} variant='contained' sx={{ml:2}}>Submit</Button>
                      </Box>
                        :
                        <ListItemText primary={item.serviceTitle} />}
                    </Box>
                    <Box >
                      {item.subServices.map((it, ind) =>
                        <Box key={ind} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#D9D9D9', width: 180,  px: 2, py:1, mt: 1, borderRadius: 4 }}>
                          <Typography >   {it.subServiceTitle} </Typography>
                          <CloseIcon fontSize='10px' sx={{ cursor: 'pointer' }} onClick={() => handleSubDelete(item.serviceId, it.subServiceId, 'maintenance')} />
                        </Box>
                      )}
                    </Box>
                    <Box display='flex'>
                      <ListItemIcon sx={{ cursor: 'pointer' }} onClick={() => handleEdit(item.serviceId, item.serviceTitle, 'maintenance')}>
                        <img src={EditIcon} alt='ico' width={16} />
                      </ListItemIcon>
                      <ListItemIcon sx={{ cursor: 'pointer' }} onClick={() => handleDelete(item.serviceId, 'maintenance')}>
                        <img src={DeleteIcon} alt='ico' width={16} />
                      </ListItemIcon>
                    </Box>


                  </Box>


                </ListItem>
              ) : <Typography>No Item</Typography>}
            </List>

            {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
              <Typography variant="h4" gutterBottom>
                Maintenance Sub Services
              </Typography>
              <Button variant="contained" onClick={() => handleDialogOpen2('maintenance')}>
                Add Maintenance Sub Service
              </Button>
            </Stack>
            <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
              aria-label="contacts"
            >
              { maintenanceService.map((item) => 
              <ListItem disablePadding key={item.serviceId}>
                <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                <Typography>{item.serviceTitle}</Typography>
              </ListItem>
             )}
              </List> */}
          </>
          :
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}><CircularProgress /></Box>}
      </Box>

      <AddServieceDialog dialogOpen={dialogOpen1} setDialogOpen={setDialogOpen1} setSnackbarMessage={setSnackbarMessage} setSnackbarOpen={setSnackbarOpen} action={action} setHousekeepingService={setHousekeepingService} setMaintenanceService={setMaintenanceService} housekeepingService={housekeepingService} maintenanceService={maintenanceService} />
      {dialogOpen2 && <AddSubServiceDialog dialogOpen={dialogOpen2} setDialogOpen={setDialogOpen2} setSnackbarMessage={setSnackbarMessage} setSnackbarOpen={setSnackbarOpen} housekeepingService={housekeepingService} maintenanceService={maintenanceService} action={action} setHousekeepingService={setHousekeepingService} setMaintenanceService={setMaintenanceService} />}
      <CustomSnackBar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarMessage={snackbarMessage && snackbarMessage.msg} success={snackbarMessage && snackbarMessage.success} />

    </>
  );
}
