import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { addAdminManager, updateAdminManager } from '../../api/superadmin/adminmanager';

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  hospitalName: '',
  hospitalId: '',
  contactNo: '',
  city: '',
  state: '',
  country: '',
  zip: '',
};

export default function AdminManagerDialog({
  dialogOpen,
  setDialogOpen,
  setDialogOpen1,
  selectedItem,
  action,
  setSnackbarMessage,
  setSnackbarOpen,
  setAdminManager,
  accounts
}) {
  const theme = useTheme();
  const loggedUser = useSelector((state) => state.adminUser)

  const [inputs, setInputs] = useState(initialState);
  const [role, setRole] = useState('');
  const [errorMessage, setErrorMessage] = useState({
    name: 'Title cannot exceed 100 characters',
    usergroup: 'Please select atleast one usergroup',
    description: 'description cannot exceed 1000 characters',
  });
  const [tempInputs, setTempInputs] = useState();
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validate = () => {

    if (inputs.name.length > 100) {
      setSnackbarMessage({ msg: 'Validation Failed, Title cannot exceed 100 characters', success: false })
      setSnackbarOpen(true)
      return false;
    }
    if (inputs.description.length > 1000) {
      setSnackbarMessage({ msg: 'Validation Failed, Message cannot exceed 1000 characters', success: false })
      setSnackbarOpen(true)
      return false;
    }
    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    // console.log('submit', inputs);
    // if (!validate()) {
    //   return;
    // }
    setIsLoading(true)
    // console.log(inputs);
    try {
      const { data } = action === 'CREATE' ? await addAdminManager(inputs) : await updateAdminManager(selectedItem._id, inputs)
      if (data.success) {
        // console.log({ data });
        setAdminManager(data.hospitalAdmins)
        setIsLoading(false);
        setSnackbarMessage({ msg: data.message, success: true });
        setSnackbarOpen(true);
        setDialogOpen(false);

      } else {
        setIsLoading(false);
        setSnackbarMessage({ msg: data.message, success: false });
        setSnackbarOpen(true);
      }
    } catch (error) {
      setIsLoading(false);
      setSnackbarMessage({ msg: error.message, success: false });
      setSnackbarOpen(true);
    }
  };

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value })
  };

  const handleSelect = (event) => {
    console.log('qw', event.target.value);
    setInputs({ ...inputs, hospitalId: event.target.value, hospitalName: event.target.value })
  };

  const handleClose = () => {
    setDialogOpen(false);
  };
  const handleSkip = () => {
    setDialogOpen(false);
    setDialogOpen1(true);
  };
  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
    setTempInputs({ ...tempInputs, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    // console.log('in hook', action, selectedItem);
    if (action !== 'CREATE') {
      console.log('am in', selectedItem);
      setInputs({
        firstName: selectedItem.firstName,
        lastName: selectedItem.lastName,
        email: selectedItem.email,
        hospitalId:selectedItem.hospitalId,
        hospitalName: selectedItem.hospitalId,
        contactNo: selectedItem.contactNo,
        city: selectedItem.city,
      })

    }
    else {
      setInputs(initialState);

    }
  }, [dialogOpen])

  return (
    <Box>
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>
          {action === 'CREATE' ? "Create" : "Edit"} Admin Manager
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>

            <TextField
              required
              margin="dense"
              name="firstName"
              label="First Name"
              type="text"
              fullWidth
              variant="standard"
              value={inputs.firstName}
              onChange={handleInputChange}
            />

            <TextField
              required
              margin="dense"
              name="lastName"
              label="Last Name"
              type="text"
              fullWidth
              variant="standard"
              value={inputs.lastName}
              onChange={handleInputChange}
            />

            <TextField
              required
              margin="dense"
              name="email"
              label="Email"
              type="email"
              fullWidth
              variant="standard"
              value={inputs.email}
              onChange={handleInputChange}
            />
            <TextField
              required
              margin="dense"
              name="contactNo"
              label="Phone"
              inputProps={{ maxLength: 10 }}
              fullWidth
              variant="standard"
              value={inputs.contactNo}
              onChange={handleInputChange}
            />
            <TextField
              required
              margin="dense"
              name="city"
              label="City"
              type="text"
              fullWidth
              variant="standard"
              value={inputs.city}
              onChange={handleInputChange}
            />
            {/* <TextField
              margin="dense"
              name="state"
              label="State"
              type="text"
              fullWidth
              variant="standard"
              value={inputs.state}
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              name="country"
              label="Phone"
              type="text"
              fullWidth
              variant="standard"
              value={inputs.country}
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              name="zip"
              label="Zip Code"
              type="text"
              fullWidth
              variant="standard"
              value={inputs.zip}
              onChange={handleInputChange}
            /> */}
            <FormControl variant="standard" sx={{ minWidth: 350 }}>
              <InputLabel id="demo-simple-select-standard-label">Hospital</InputLabel>
              <Select
                required
                labelId="demo-simple-select-standard-label"
                value={inputs.hospitalName}
                onChange={handleSelect}
                label="Hospital"
              >
                {accounts.map((item) =>
                  <MenuItem key={item.hospitalId} value={item.hospitalId}>{item.hospitalName}</MenuItem>
                )}

              </Select>
            </FormControl>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant='contained' >Cancel</Button>
            <LoadingButton loading={isLoading} type='submit' variant='contained' > {action === 'CREATE' ? "Create" : "Update"}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  )
}
