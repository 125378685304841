import { createApiConfig } from "../config";

import { ADMIN_USER_API_URL } from "../routes"

const apiConfig = createApiConfig();


// super admin crud
export const getAdminUser = () => apiConfig.get(ADMIN_USER_API_URL);

// CREATE admin User

export const addAdminUser = (data) => apiConfig.post(ADMIN_USER_API_URL, data);

// DELETE admin User

export const deleteAdminUser = (id) => apiConfig.delete(`${ADMIN_USER_API_URL}/${id}`);

// Update admin User

export const updateAdminUser = (id,data) => apiConfig.put(`${ADMIN_USER_API_URL}/${id}`, data);

export const suspendAdminUser = (id,data) => apiConfig.put(`${ADMIN_USER_API_URL}/status/${id}`, data);