import { createApiConfig } from "../config";

import { SERVICES_API_URL, HOUSEKEEPING_SERVICE_API_URL, HOUSEKEEPING_SUB_SERVICE_API_URL, MAINTENANCE_SERVICE_API_URL, MAINTENANCE_SUB_SERVICE_API_URL } from "../routes"

const apiConfig = createApiConfig();


export const getSerivces = (id) => apiConfig.get(`${SERVICES_API_URL}/${id}`);


export const addHousekeepingService = (id,data) => apiConfig.put(`${HOUSEKEEPING_SERVICE_API_URL}/${id}`, data);


export const addHousekeepingSubService = (id, data) => apiConfig.put(`${HOUSEKEEPING_SUB_SERVICE_API_URL}/${id}`, data);


export const addMaintenanceService = (id,data) => apiConfig.put(`${MAINTENANCE_SERVICE_API_URL}/${id}`, data);


export const addMaintenanceSubService = (id, data) => apiConfig.put(`${MAINTENANCE_SUB_SERVICE_API_URL}/${id}`, data);
