import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, Box, Grid, CircularProgress } from '@mui/material';

// lottie
import Lottie from "lottie-react";
import login from "../assets/lotties/secure-login.json";

// hooks
import useResponsive from '../hooks/useResponsive';
// components
import logo from '../assets/logo.svg';
import loginImage from '../assets/login.svg'
import loginAdminImage from '../assets/login-admin.svg'
import responsiveLoginImage from '../assets/responsive-login.svg'

// sections
import { LoginForm } from '../sections/auth/login';
import ForgetPassword from '../sections/auth/login/ForgetPassword';
import { getAccountItem } from '../api/auth';
import CustomSnackBar from '../components/snackbar';
import { setAccountStateAction } from '../appRedux/actions/account';


// ----------------------------------------------------------------------

const LoginPage = () => {
  const { user } = useParams()
  const dispatch = useDispatch();

  const mdUp = useResponsive('up', 'md');
  console.log(user)

  // snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState(null)

  const [loginState, setLoginState] = useState(true);
  const [account, setAccount] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const getAccount = async () => {
    // console.log(path);
    setIsLoading(true)
    try {
      const { data } = await getAccountItem(user);
      if (data.success) {
        setIsLoading(false)
        if (data.hospitalAccount.status !== 'active') {
          setSnackbarMessage({ msg: "Account is not active please contact admin", success: false });
          setSnackbarOpen(true);
          return
        }
        setAccount(data.hospitalAccount)
        dispatch(setAccountStateAction({
          hospitalAccount: data.hospitalAccount
        }))

      }
    }
    catch (e) {
      setIsLoading(false)
      // console.log(e);
    }
  }

  useEffect(() => {

    // getAccount();

  }, [])

  return (
    <>
      <Helmet>
        <title>Login | Teller One </title>
      </Helmet>
      <Grid container sx={{ height: '100vh', width: '100vw' }}>
        <Grid item xs={false} sm={false} md={6} lg={6} xl={6}>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <img src={user === 'superadmin' ? loginImage : loginAdminImage} alt='img' width={user === 'superadmin' ? 500 : 400} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%', mx: { xs: 2, sm: 8 }, position: 'relative' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Stack spacing={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: {xs:'flex-start',sm:'flex-end'}, width:'100%'}}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                  <img src={logo} alt='img' width={200} />
                  <Typography variant='caption'>powered by TELLERCOMM</Typography>
                </Box>
                <Typography variant='h2' sx={{ display: { xs: "none", sm: "block" } }}>Hey, Welcome back!</Typography>
              </Stack>
              <Box sx={{ display: { xs: "block", sm: "none" } }}>
                <img src={responsiveLoginImage} alt='img' height={200} />
              </Box>
            </Box>
            <LoginForm setLoginState={setLoginState} setSnackbarOpen={setSnackbarOpen} setSnackbarMessage={setSnackbarMessage} />
            <Box sx={{ position: 'absolute', left: '35%', bottom: '2%' }}
            >
              <Typography color='#B7B7B7' variant='body1'> © Tellercomm {new Date().getFullYear()}</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <CustomSnackBar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarMessage={snackbarMessage && snackbarMessage.msg} success={snackbarMessage && snackbarMessage.success} />
    </>
  );
}
export default LoginPage;