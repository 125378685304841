// ----------------------------------------------------------------------

const account = {
  displayName: 'Pravin Kumar',
  email: 'pravin@hos.com',
  role:'Admin',
  photoURL: '/assets/images/avatars/avatar_12.jpg',
};

export default account;
