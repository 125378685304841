import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import axios from 'axios';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  CircularProgress,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import CustomSnackBar from '../../components/snackbar';
import MenuPopover from '../../components/popover/menuPopover'
// sections
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import SuperAdminDialog from '../../sections/dialogs/superAdminDialog';

// mock
import USERLIST from '../../_mock/user';
// api
import { getSuperAdmin, deleteSuperAdmin } from '../../api/superadmin/superadmin';
import SuperAdminFilter from '../../sections/@dashboard/request/SuperAdminFilter';



// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'city', label: 'City', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function SuperAdminList() {

  const location = useLocation();
  console.log('here', location.state);
  // snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState(null)

  const [adminManager, setAdminManager] = useState(null);

  const [tempAdminManager, setTempAdminManager] = useState(null);

  const [isLoading, setIsLoading] = useState(false)

  const [selectedItem, setSelectedItem] = useState(false)

  const [dialogOpen, setDialogOpen] = useState(false);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const [menuOpen, setMenuOpen] = useState(false);

  const [action, setAction] = useState('');

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [filterData, setFilterData] = useState();


  const fetchData = async () => {
    setIsLoading(true)
    try {
      const { data } = await getSuperAdmin();
      setAdminManager(data.superAdmins)
      setTempAdminManager(data.superAdmins)
      setFilterData(location.state?.filter ? location.state?.filter : 'all')
      setIsLoading(false)
    }
    catch (e) {
      setIsLoading(false)
      // console.log({ e });
    }
  }

  // get admins list
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    console.log({filterData});
    if(adminManager)
    {
      handleFilterByStatus();
    }
   
  }, [filterData]);

 
  const handleFilterByStatus = () => {
    console.log('in fun');
  
      if(filterData === 'all' )
      {
        setAdminManager(tempAdminManager)
      }
      else{
          console.log('out', tempAdminManager?.filter((item) => item.status === filterData));
  
        setAdminManager(tempAdminManager?.filter((item) => item.role === filterData))
      }
    
  };

  const handleDelete = async (id) => {
    // console.log(id);
    setConfirmDialogOpen(true)
  }

  const handleConfirmDelete = async () => {
    // console.log(id);
    try {
      const { data } = await deleteSuperAdmin(selectedItem._id);
      setAdminManager(data.superAdmins)
      handleConfirmDialogClose()
      setSnackbarMessage({ msg: data.message, success: true });
      setSnackbarOpen(true);
    }
    catch (e) {
      setIsLoading(false)
      setSnackbarMessage({ msg: e.message, success: false });
      setSnackbarOpen(true);
    }
  }

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleOpenMenu = (event, item) => {
    // console.log(item);
    setSelectedItem(item)
    setMenuOpen(event.currentTarget);
  };

  // Dialog
  const handleDialogOpen = (item, str) => {
    if(item)
    {
      // console.log(item);
      setSelectedItem(item)
    }
    setAction(str)
    setDialogOpen(true);
  };



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;



  return (
    <>
      <Helmet>
        <title> Super Admin | Teller One </title>
      </Helmet>

      <Box sx={{ height: '100%' }}>
        {!isLoading ? (
          <Box >

            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                Super Admin
              </Typography>
              <Button variant="contained" sx={{borderRadius:2}} startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => handleDialogOpen(null, 'CREATE')}>
                Create Admin Manager
              </Button>
            </Stack>

            <Card elevation={12}>
              {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}
              <Stack direction="row" alignItems="center" justifyContent="flex-end" mx={5}>
              <SuperAdminFilter setFilterData = {setFilterData} filterData = {filterData} />
            </Stack>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 , }} >
                  <Table size='small'>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={USERLIST.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {adminManager && adminManager.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const { _id, firstName, lastName, email, role, city, status } = row;

                        return (
                          <TableRow hover key={_id} tabIndex={-1} role="checkbox" >
                            {/* <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                        </TableCell> */}

                            <TableCell component="th" >
                              <Stack direction="row" alignItems="center" spacing={2}>
                                {/* <Avatar alt={name} src={avatarUrl} /> */}
                                <Typography variant="subtitle2" noWrap>
                                  {firstName} {lastName}
                                </Typography>
                              </Stack>
                            </TableCell>


                            <TableCell align="left">{email}</TableCell>
                            <TableCell align="left">{role}</TableCell>
                            <TableCell align="left">{city}</TableCell>
                            <TableCell align="left">
                              <Label color={(status === "Inactive" && 'error') || 'success'}>{status}</Label>
                            </TableCell>

                            <TableCell align="right">
                              <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, row)}>
                                <Iconify icon={'eva:more-vertical-fill'} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                Not found
                              </Typography>

                              <Typography variant="body2">
                                No results found for &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                                <br /> Try checking for typos or using complete words.
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>

              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5,10,15]}
                component="div"
                count={adminManager?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>

          </Box>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}> <CircularProgress /> </Box>
        )}
      </Box>
      <Dialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm action"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete...?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} variant='contained' >Cancel</Button>
          <Button onClick={handleConfirmDelete} autoFocus color='error' variant='contained' >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <SuperAdminDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} setAdminManager={setAdminManager} setSnackbarMessage={setSnackbarMessage} setSnackbarOpen={setSnackbarOpen} selectedItem = {selectedItem} action = {action}/>
      <CustomSnackBar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarMessage={snackbarMessage && snackbarMessage.msg} success={snackbarMessage && snackbarMessage.success} />
      <MenuPopover menuOpen={menuOpen} setMenuOpen={setMenuOpen} handleDialogOpen={handleDialogOpen} handleDelete={handleDelete} selectedItem={selectedItem} from = {'superAdminList'} />
    </>
  );
}
