import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { addSpeciality, updateSpeciality } from '../../api/admin/hospitalInformation';

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  roleId: '',
  roleName: '',
  contactNo: '',
  city: '',
  state: '',
  country: '',
  zip: '',
};

export default function SpecialitiesDialog({
  dialogOpen,
  setDialogOpen,
  setDialogOpen1,
  selectedItem,
  action,
  setSnackbarMessage,
  setSnackbarOpen,
  setHospitalInformation,
}) {
  const account = useSelector((state) => state.account)
  const theme = useTheme();
  const [inputs, setInputs] = useState(initialState);
  const [tempInputs, setTempInputs] = useState();
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const { data } = action === 'CREATE' ? await addSpeciality(account.hospitalAccount._id, {specialty_name : inputs.specialityName}) : await updateSpeciality(account.hospitalAccount._id, selectedItem._id, {specialty_name : inputs.specialityName})
      if (data.success) {
        // console.log({ data });
        setHospitalInformation(data.hospitalInformation)
        setIsLoading(false);
        setSnackbarMessage({ msg: data.message, success: true });
        setSnackbarOpen(true);
        setDialogOpen(false);

      } else {
        setIsLoading(false);
        setSnackbarMessage({ msg: data.message, success: false });
        setSnackbarOpen(true);
      }
    } catch (error) {
      setIsLoading(false);
      setSnackbarMessage({ msg: error.message, success: false });
      setSnackbarOpen(true);
    }
  };

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value })
  };

  const handleSelect = (event) => {
    console.log(event.target.value);
    // setRole(event.target.value);
    setInputs({ ...inputs, roleId: event.target.value })
  };

  const handleClose = () => {
    setDialogOpen(false);
  };
  const handleSkip = () => {
    setDialogOpen(false);
    setDialogOpen1(true);
  };
  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
    setTempInputs({ ...tempInputs, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    console.log('in hook', action);
    if (action !== 'CREATE') {
      console.log('am in', selectedItem);
      setInputs({
        specialityName: selectedItem.specialty_name,
      })
    }
    else {
      setInputs(initialState);
    }
  }, [dialogOpen])

  return (
    <Box>
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>{action === 'CREATE' ? "Create" : "Edit"} Speciality</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>

            <TextField
              required
              autofocus
              margin="dense"
              name="specialityName"
              label="Speciality"
              type="text"
              fullWidth
              variant="standard"
              value={inputs.specialityName}
              onChange={handleInputChange}
            />

            

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant='contained' >Cancel</Button>
            <LoadingButton loading={isLoading} type='submit'  variant='contained' >{action === 'CREATE' ? "Create" : "Update"}</LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  )
}
