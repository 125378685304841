import { createApiConfig, createApiConfigForCommonData } from "../config";

import { ACCOUNT_SUMMARY_API_URL, COMMON_DATA_API_URL, DASHBOARD_DATA_API_URL, } from "../routes"

const apiConfig = createApiConfig();

// const apiConfigforCommonData = createApiConfigForCommonData(token);

// GET Account List

export const getDashboardData = () => apiConfig.get(DASHBOARD_DATA_API_URL);

export const getCommonData = (token) => createApiConfigForCommonData(token).get(COMMON_DATA_API_URL);

export const getCommonDataWithToken = () => apiConfig.get(COMMON_DATA_API_URL);

export const getAccountList= () => apiConfig.get(ACCOUNT_SUMMARY_API_URL);


