import { createApiConfig } from "../config";

import {ACCOUNTS_API_URL} from "../routes"

const apiConfig = createApiConfig();

// GET Account List

export const addAccount = (inputs) => apiConfig.post(ACCOUNTS_API_URL,inputs);

export const getAccounts = () => apiConfig.get(ACCOUNTS_API_URL);

export const exportAccounts = () => apiConfig.post(`${ACCOUNTS_API_URL}/export`, null, { responseType: 'blob' });

export const getAccount = (id) => apiConfig.get(`${ACCOUNTS_API_URL}/${id}`);

export const updateAccount = (id, inputs) => apiConfig.put(`${ACCOUNTS_API_URL}/${id}`, inputs);

export const approveAccount = (id, inputs) => apiConfig.put(`${ACCOUNTS_API_URL}/approve/${id}`, inputs);

export const deleteAccount = (id) => apiConfig.delete(`${ACCOUNTS_API_URL}/${id}`);

export const suspendAccount = (id) => apiConfig.put(`${ACCOUNTS_API_URL}/suspend/${id}`);

export const holdAccount = (id) => apiConfig.get(`${ACCOUNTS_API_URL}/hold/${id}`);
