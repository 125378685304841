import { createApiConfig, createApiConfigForFiles } from "../config";

import {QR_LICENSE_API_URL, ADMIN_LICENSE_API_URL, FEEDBACK_API_URL, SERVICES_API_URL, ACCOUNTS_API_URL} from "../routes"

const apiConfig = createApiConfig();

const apiConfigForFiles = createApiConfigForFiles();


// GET QR License List

export const addQRLicense = (inputs) => apiConfig.post(QR_LICENSE_API_URL,inputs);

export const getQRLicense = (id) => apiConfig.get(`${QR_LICENSE_API_URL}/${id}`);

export const getFeedback = (id) => apiConfig.get(`${FEEDBACK_API_URL}/${id}`);

export const getServices = (id) => apiConfig.get(`${SERVICES_API_URL}/${id}`);

export const getQRLicenseItem = (id) => apiConfig.get(`${QR_LICENSE_API_URL}/${id}`);

export const exportQRLicense= (id) => apiConfig.post(`${QR_LICENSE_API_URL}/export/${id}`,null, { responseType: 'blob' });

export const updateQRLicense = (id, inputs) => apiConfig.put(`${QR_LICENSE_API_URL}/${id}`, inputs);

export const deleteQRLIcense = (id) => apiConfig.delete(`${QR_LICENSE_API_URL}/${id}`);

// GET Admin License List

export const addAdminLicense = (inputs) => apiConfig.post(ADMIN_LICENSE_API_URL,inputs);

export const getAdminLicense = (id) => apiConfig.get(`${ADMIN_LICENSE_API_URL}/${id}`);

export const getAdminLicenseItem = (id) => apiConfig.get(`${ADMIN_LICENSE_API_URL}/${id}`);

export const updateAdminLicense = (id, inputs) => apiConfig.put(`${ADMIN_LICENSE_API_URL}/${id}`, inputs);



export const deleteAdminLIcense = (id) => apiConfig.delete(`${ADMIN_LICENSE_API_URL}/${id}`);

// modules

export const muteModules = (id,data) => apiConfig.put(`/account-module-status`, data);