import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';

import axios from 'axios';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import {
  AppCurrentVisits
} from '../sections/@dashboard/app';
// mock
import USERLIST from '../_mock/user';
// api
import { getAccounts } from '../api/superadmin/account';

// ----------------------------------------------------------------------


const ACCOUNT_MANAGER_TABLE_HEAD = [
  { id: 'name', label: 'Account Name', alignRight: false },
  { id: 'license_name', label: 'License Given', alignRight: false },
  { id: 'license_start_date', label: 'Start Date', alignRight: false },
  { id: 'license_end_date', label: 'End Date', alignRight: false },
  { id: 'location', label: 'Location', alignRight: false },
];
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function AccountManager() {

  const theme = useTheme();

  const [accounts, setAccounts] = useState(null);
  const [tempAccounts, setTempAccounts] = useState(null);
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  // get accounts list
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getAccounts();
      setAccounts(data.result)
      setTempAccounts(data.result)
      // console.log(data.result);
    }
    try {
      fetchData();
    }
    catch (e) {
      // console.log({ e });
    }
  }, []);

  const handleExport = () => {
    // console.log('export started');
  }


  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const handleFilter = (e) => {
    if(e.target.value === 'all') setTempAccounts(accounts)
    else setTempAccounts(accounts.filter((acc) => acc[e.target.value] === true))
  }

  return (
    <>
      <Helmet>
        <title> Accounts Manger | Teller One </title>
      </Helmet>
      {accounts &&
        <Container>

          {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Account List
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleExport}>
            Export
          </Button>
        </Stack> */}
          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Account Summary"
              subheader={`Total Accounts : ${accounts && accounts.length}`}
              chartData={[
                { label: 'Active', value: accounts.filter((acc) => acc.active === true).length },
                { label: 'Modified', value: accounts.filter((acc) => acc.modified === true).length },
                { label: 'Suspended', value: accounts.filter((acc) => acc.suspended === true).length },
                { label: 'Deleted', value: accounts.filter((acc) => acc.deleted === true).length },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>
          <Card>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} handleFilter = {handleFilter}/>

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={ACCOUNT_MANAGER_TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {tempAccounts.map((row) => {
                      const { _id, hospitalName, license, location, approvalStatus } = row;

                      return (
                        <TableRow hover key={_id} tabIndex={-1} role="checkbox" >
                          {/* <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                        </TableCell> */}

                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography variant="subtitle2" noWrap>
                                {hospitalName}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{license.length ? license.map((license) => (<p>{license.licenseName}</p>)) : <p>NIL</p>}</TableCell>
                          <TableCell align="left">{license.length ? license.map((license) => (<p>{license.startDate && license.startDate.split('T')[0]}</p>)) : <p>NIL</p>}</TableCell>
                          <TableCell align="left">{license.length ? license.map((license) => (<p>{license.endDate && license.endDate.split('T')[0]}</p>)) : <p>NIL</p>}</TableCell>
                          <TableCell align="left">{location}</TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>

            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={USERLIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>

        </Container>
      }
    </>
  );
}
