import React from 'react'

import { CircularProgress, MenuItem, Popover, Typography } from '@mui/material'
import Iconify from '../iconify'

const StatusPopover = ({ menuOpen, setMenuOpen, handleDialogOpen }) => {

    const handleCloseMenu = () => {
        setMenuOpen(false);
    }

    

    return (
        <Popover
            open={Boolean(menuOpen)}
            anchorEl={menuOpen}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
                sx: {
                    p: 1,
                    width: 140,
                    '& .MuiMenuItem-root': {
                        px: 1,
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                },
            }}
        >
                <MenuItem onClick={() => handleDialogOpen('inprogress')}>

                    Inprogress
                </MenuItem>
           
                <MenuItem onClick={() => handleDialogOpen('done')}>
                    Done
                </MenuItem>
            <MenuItem onClick={() => handleDialogOpen('hold')}>
                Hold
            </MenuItem>
        </Popover>
    )
}

export default StatusPopover