import { createApiConfig, createApiConfigForFiles } from "../config";

import { REQUEST_API_URL, REQUEST_STATUS_API_URL , REQUEST_DATA_API_URL } from "../routes"

const apiConfig = createApiConfig();

const apiConfigForFiles = createApiConfigForFiles()

export const getRequest = () => apiConfig.get(REQUEST_API_URL);

export const getRequestData = (id) => apiConfig.get(`${REQUEST_DATA_API_URL}/${id}`);

export const postRequestStatus = (id, data) => apiConfigForFiles.put(`${REQUEST_STATUS_API_URL}/${id}`, data);
