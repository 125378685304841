import { createApiConfig } from "../config";

import { FEEDBACK_SECTION_API_URL, FEEDBACK_QUESTION_API_URL } from "../routes"

const apiConfig = createApiConfig();


export const getFeedbackSection = (id) => apiConfig.get(`${FEEDBACK_SECTION_API_URL}/${id}`);

export const addFeedbackSection = (data) => apiConfig.post(FEEDBACK_SECTION_API_URL, data);


export const updateFeedbackSection = (id,data) => apiConfig.put(`${FEEDBACK_SECTION_API_URL}/${id}`, data);

export const getFeedbackQuestion = (id) => apiConfig.get(`${FEEDBACK_QUESTION_API_URL}/${id}`);

export const addFeedbackQuestion = (data) => apiConfig.post(FEEDBACK_QUESTION_API_URL, data);


export const updateFeedbackQuestion = (id,data) => apiConfig.put(`${FEEDBACK_QUESTION_API_URL}/${id}`, data);