import { applyMiddleware, combineReducers, configureStore} from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { composeWithDevTools } from "redux-devtools-extension";

import thunk from 'redux-thunk';
import logger from "redux-logger";
// import reducers
import AdminUserReducer from "../reducers/adminUser";
import AccountReducer from "../reducers/account";
// import NotificationReducer from "../reducers/Notifications";
// import chatReducer from "../reducers/Chat";
// import AuthReducer from "appRedux/reducers/Auth";
// import tournamentReducer from "appRedux/reducers/Tournament";
// import PostReducer from "../reducers/Post";
// import WelcomeScreenReducer from "../reducers/WelcomeScreen";
// import { reducer as UserProfileReducer } from "../reducers/UserProfile";

const persistConfig = {
  key: "store",
  storage,

};

const rootReducer = combineReducers({
  adminUser: AdminUserReducer,
  account : AccountReducer
});

const pReducer = persistReducer(persistConfig, rootReducer);
const middleware = applyMiddleware(thunk, logger);

export const store = configureStore({reducer : pReducer}, composeWithDevTools(middleware));
export const persistor = persistStore(store);
// export default store;

