import { createApiConfig, createApiConfigForFiles } from "../config";

import {  DELETE_AMENITY_API_URL, DELETE_WHATS_NEW_API_URL, DOCTOR_API_URL, HOSPITAL_INFORMATION_API_URL, SPECIALITY_API_URL, UPLOAD_AMENITY_API_URL, UPLOAD_WHATS_NEW_API_URL,      } from "../routes"

const apiConfig = createApiConfig();

const apiConfigForFiles = createApiConfigForFiles()

// hospital information crud
export const getHospitalInformation = (id) => apiConfig.get(`${HOSPITAL_INFORMATION_API_URL}/${id}`);


export const addHospitalInformation = (id, data) => apiConfigForFiles.post(`${HOSPITAL_INFORMATION_API_URL}/${id}`, data);


export const deleteHospitalInformation = (id) => apiConfig.delete(`${HOSPITAL_INFORMATION_API_URL}/${id}`);


export const updateHospitalInformation = (id,data) => apiConfigForFiles.put(`${HOSPITAL_INFORMATION_API_URL}/${id}`, data);

// speciality crud

export const addSpeciality = (hospitalId, data) => apiConfig.post(`${SPECIALITY_API_URL}/${hospitalId}`, data);


export const deleteSpeciality = (hospitalId,id) => apiConfig.delete(`${SPECIALITY_API_URL}/${hospitalId}/${id}`);

export const updateSpeciality = (hospitalId,id,data) => apiConfig.put(`${SPECIALITY_API_URL}/${hospitalId}/${id}`, data);

// doctors crud


export const addDoctor = (hospitalId, inputs) => apiConfig.post(`${DOCTOR_API_URL}/${hospitalId}`, inputs);


export const deleteDoctor = (hospitalId,id) => apiConfig.delete(`${DOCTOR_API_URL}/${hospitalId}/${id}`);


export const updateDoctor = (hospitalId,id,data) => apiConfig.put(`${DOCTOR_API_URL}/${hospitalId}/${id}`, data);

// amenity crud

export const addAmenity = (hospitalId, data) => apiConfigForFiles.post(`${UPLOAD_AMENITY_API_URL}/${hospitalId}`, data);

export const deleteAmenity = (hospitalId,id) => apiConfig.delete(`${DELETE_AMENITY_API_URL}/${hospitalId}/${id}`);

// whatsnew crud

export const addWhatsNew = (hospitalId, data) => apiConfigForFiles.post(`${UPLOAD_WHATS_NEW_API_URL}/${hospitalId}`, data);

export const deleteWhatsNew = (hospitalId,id) => apiConfig.delete(`${DELETE_WHATS_NEW_API_URL}/${hospitalId}/${id}`);
