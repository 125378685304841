import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// api
import { muteQRLicense } from '../../api/admin/qrlicense';
import { getRequest, postRequestStatus } from '../../api/admin/request';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(userGroupName, userGroup, theme) {
  return {
    fontWeight:
      userGroup.indexOf(userGroupName) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}
const initialState = {
  hours: '',
  mins: '',
};

export default function StatusDialog({
  dialogOpen,
  setDialogOpen,

  selectedId,
  selectedItem,
  setRequests,
  setSnackbarMessage,
  setSnackbarOpen,
  setLength
}) {
  const theme = useTheme();
  const formData = new FormData();

  const [inputs, setInputs] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState('');
  const [summary, setSummary] = useState([]);
  const [selectedModules, setSelectedModules] = useState()
  const loggedUser = useSelector((state) => state.adminUser)

  const [imageUrl, setImageUrl] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setFile(file)
    const reader = new FileReader();

    reader.onloadend = () => {
      setImageUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  // console.log(loggedUser);

  const { modules } = loggedUser.commonData

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    console.log('submit', summary, file);
    formData.append('workStatus', summary)
    formData.append('file', file)
    formData.append('status', selectedItem)
    try {
      const { data } = await postRequestStatus(selectedId && selectedId, formData);
      if(data.success)
      {
        const {data:requestData} = await getRequest();
        setRequests(requestData.requests)
      }
      setIsLoading(false)
      setSnackbarMessage({ msg: data.message, success: true });
      setSnackbarOpen(true);
      setDialogOpen(false)
    }
    catch (e) {
      setIsLoading(false)

      setSnackbarMessage({ msg: e.message, success: false });
      setSnackbarOpen(true);
    }
  }


  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: parseInt(e.target.value, 10) });
  };

  // useEffect(() => {
  //   // console.log('in hook', action, selectedItem);
  //   if (action !== 'CREATE') {
  //     // console.log('am in', selectedItem);
  //     setInputs({
  //       hospitalName: selectedItem.hospitalName,
  //       location: selectedItem.location,
  //       email: selectedItem.email,
  //       contactNo: selectedItem.contactNo,
  //       website: selectedItem.website,
  //       qrLicense: selectedItem.qrLicense,
  //       adminLicense: selectedItem.adminLicense,
  //     })

  //   }
  //   else {
  //     setInputs(initialState);
  //     setPersonName([]);
  //   }
  // }, [dialogOpen])

  return (
    <Box>
      <Dialog open={dialogOpen} onClose={handleClose}>

        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Typography variant='h6'>Add request summary</Typography>
            <TextField
              autoFocus
              margin="dense"
              name="summary"
              label="summary"
              fullWidth
              variant="standard"
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
            />
            <Box p={1} display='flex' justifyContent='space-between'>
              <Button  component="label" variant='contained'>
                Upload File
                <input hidden accept="image/*" multiple type="file" capture="environment" onChange={handleFileUpload} />
              </Button>
              {imageUrl && <img src={imageUrl} alt="pre" height={60} width={60} />}
            </Box>


          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant='contained' >Cancel</Button>
            <LoadingButton type='submit' loading={isLoading} variant='contained' >Submit</LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  )
}
