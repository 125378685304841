import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box, Button, Popover, Typography, Menu, MenuItem, Fade } from '@mui/material';
// components
import { ArrowDropDown } from '@mui/icons-material';
import { useState } from 'react';
import { useChart } from '../../../components/chart';

// ----------------------------------------------------------------------

AppWebsiteVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function AppWebsiteVisits({ title, subheader, chartLabels,chartData, allChartData, setCurrentChartData, ...other }) {

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState("Day-Wise");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (str) => {
    setSelectedItem(str === 'dayWise' ? 'Day-Wise' : str === 'monthWise' ? 'Month-Wise' : 'Year-Wise' )
    setCurrentChartData(allChartData[str])
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,

    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)}`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card {...other}>
      {/* <CardHeader title={title} subheader={subheader}  action={<Button sx={{mt:1, borderRadius:4}} variant='contained' endIcon={<ArrowDropDown />}>Monthly</Button>}/> */}
      <CardHeader title={title} subheader={subheader} action={<Button sx={{ mt: 1, borderRadius: 4 }} variant='contained' endIcon={<ArrowDropDown />} onClick={handleClick}>{selectedItem}</Button>} />
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}

      >
        <MenuItem onClick={() => handleSelect("dayWise")}>Day-Wise</MenuItem>
        <MenuItem onClick={() => handleSelect("monthWise")}>Month-Wise</MenuItem>
        <MenuItem onClick={() => handleSelect("yearWise")}>Year-Wise</MenuItem>

        {/* <MenuItem onClick={() => handleSelect("Biweekly")}>Biweekly</MenuItem>
        <MenuItem onClick={() => handleSelect("Weekly")}>Weekly</MenuItem> */}
      </Menu>
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
