import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import { Box, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import dayjs from 'dayjs';





const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function ImageDialog({ dialogOpen, setDialogOpen, imageFile }) {
  console.log({imageFile});
  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <Box>
      <Dialog open={dialogOpen} onClose={handleClose} >

        <img src={imageFile} alt='imageFile' />
      </Dialog>
    </Box>
  );
}