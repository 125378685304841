import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// api
import { addAccount, updateAccount } from '../../api/superadmin/account';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(userGroupName, userGroup, theme) {
  return {
    fontWeight:
      userGroup.indexOf(userGroupName) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}
const initialState = {
  hospitalName: '',
  location: '',
  email: '',
  contactNo: '',
  website: '',
  qrLicense: '',
  adminLicense: '',
  expiryDate: null,
  publishDate: null,
  accountManager:'650d2aab35e1cf639e4b9875',
  modules: [],
};

export default function AccountDialog({
  dialogOpen,
  setDialogOpen,

  setAccounts,
  selectedItem,
  action,
  setSnackbarMessage,
  setSnackbarOpen,
  setLength
}) {
  const theme = useTheme();

  const [inputs, setInputs] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [license, setLicense] = useState('');
  const [personName, setPersonName] = useState([]);
  const [selectedModules, setSelectedModules] = useState()
  const loggedUser = useSelector((state) => state.adminUser)

  // console.log(loggedUser);

  const { modules } = loggedUser.commonData
  const { accountManagers } = loggedUser.commonData

  const handleSubmit = async (e) => {
    e.preventDefault()

    // console.log('submit', inputs, selectedModules);

    setIsLoading(true)
    console.log(selectedItem._id);
    try {
      const { data } = action === 'CREATE' ? await addAccount({...inputs, puplishDate:dayjs(inputs.publishDate).format(), expiryDate:dayjs(inputs.expiryDate).format()}) : await updateAccount(selectedItem._id, {...inputs, puplishDate:dayjs(inputs.publishDate).format(), expiryDate:dayjs(inputs.expiryDate).format()})
      if (data.success) {
        // console.log({ data });
        setAccounts(data.hospitalAccounts)
        setIsLoading(false);
        setSnackbarMessage({ msg: data.message, success: true });
        setSnackbarOpen(true);
        setDialogOpen(false);


      } else {
        setIsLoading(false);
        setSnackbarMessage({ msg: data.message, success: false });
        setSnackbarOpen(true)
        console.log('submitb');
      }
    } catch (error) {
      setIsLoading(false);
      setSnackbarMessage({ msg: error.message, success: false });
      setSnackbarOpen(true);
      console.log('submitc')
    }
  };
  const handleSelect = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    // value.map((item) => (
    //   setSelectedModules([...selectedModules, {moduleId:item, moduleName:item}])
    // ))
    console.log('sv', event.target.value);
    setInputs({ ...inputs, modules: event.target.value });

  };

  const handleSelectAccountManager = (event) => {
    console.log(event.target.value);
    setInputs({ ...inputs, accountManager: event.target.value });

  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleDeleteChip = (e, chipToDelete) => {
    e.preventDefault();
    if (action === 'VIEW') { return; }
    const temp = inputs.usergroup.filter((ug) => ug !== chipToDelete)
    // console.log("clicked delete",  chipToDelete, temp);
    // setInputs({ ...inputs, usergroup: temp });
    // setTempInputs({ ...tempInputs, usergroup: temp });
  };
  const handlePublishDate = (newValue) => {
    // console.log(newValue, dayjs(newValue).format());
    setInputs({ ...inputs, publishDate: newValue })
  }
  const handleExpiryDate = (newValue) => {
    // console.log(newValue, dayjs(newValue));
    setInputs({ ...inputs, expiryDate: newValue })
  }
  const checkPublishDate = (date) => date < inputs.publishDate;
  useEffect(() => {
    // console.log('in hook', action, selectedItem);
    if (action !== 'CREATE') {
      console.log('am in', selectedItem);
      setInputs({
        hospitalName: selectedItem.hospitalName,
        accountManager:selectedItem.accountManager._id,
        location: selectedItem.location,
        email: selectedItem.email,
        contactNo: selectedItem.contactNo,
        website: selectedItem.website,
        qrLicense: selectedItem.qrLicense,
        adminLicense: selectedItem.adminLicense,
        modules: modules.filter((item) => item._id === selectedItem.modules.find((it) => it.moduleId === item._id && it.licenseStatus === 'active')?.moduleId).map((item) => item._id),
        publishDate: dayjs(selectedItem.startDate),
        expiryDate: dayjs(selectedItem.endDate)
      })

    }
    else {
      setInputs(initialState);
      setPersonName([]);
    }
  }, [dialogOpen])

  return (
    <Box>
      <Dialog open={dialogOpen} onClose={handleClose}>

        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Typography variant='h6'>{action === 'CREATE' ? "Create" : "Edit"} Account</Typography>
            <Stack spacing={2}>
              <TextField
                required
                margin="dense"
                name="hospitalName"
                label="Account Name"
                type="text"
                fullWidth
                variant="standard"
                value={inputs.hospitalName}
                onChange={handleInputChange}
              />
              <TextField
                required
                margin="dense"
                name="location"
                label="Location"
                type="text"
                fullWidth
                variant="standard"
                value={inputs.location}
                onChange={handleInputChange}
              />
              <TextField
                required
                margin="dense"
                name="email"
                label="Email"
                type="email"
                fullWidth
                variant="standard"
                value={inputs.email}
                onChange={handleInputChange}
              />
              <TextField
                required
                margin="dense"
                name="contactNo"
                label="Phone"
                inputProps={{ maxLength: 10 }}
                fullWidth
                variant="standard"
                value={inputs.contactNo}
                onChange={handleInputChange}
              />
              <TextField
                required
                margin="dense"
                name="website"
                label="Website"
                type="text"
                fullWidth
                variant="standard"
                value={inputs.website}
                onChange={handleInputChange}
              />

              <FormControl variant="standard" sx={{ minWidth: 350 }}>
                <InputLabel id="demo-simple-select-standard-label">Account Manager</InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-standard-label"
                  value={inputs.accountManager}
                  onChange={handleSelectAccountManager}
                  label="Hospital"
                >
                  {accountManagers?.map((item) =>
                    item.status === 'active' && <MenuItem key={item._id} value={item._id}>{item.email}</MenuItem>
                  )}

                </Select>
              </FormControl>


              <FormControl variant="standard" sx={{ width: 550 }}>
                <InputLabel id="demo-multiple-chip-label">Modules</InputLabel>
                <Select
                  required
                  labelId="demo-multiple-chip-label"
                  multiple
                  value={inputs.modules}
                  onChange={handleSelect}
                  // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                  renderValue={(selected) => (

                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>

                      {selected.map((value) => (
                        <Chip key={value} label={modules.find((it) => it._id === value)?.moduleName} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {modules.map((item) => (
                    <MenuItem
                      key={item._id}
                      value={item._id}
                      style={getStyles(item.moduleName, personName, theme)}
                    >
                      {item.moduleName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Box display="flex" alignItems="center" justifyContent="space-between">

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    required
                    label='Start Date'
                    format="DD/MM/YYYY"
                    disablePast
                    value={inputs.publishDate}
                    onChange={(newValue) => handlePublishDate(newValue)}
                  // disabled={action === 'VIEW'}
                  />
                </LocalizationProvider>



                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    required
                    label='End Date'
                    format="DD/MM/YYYY"
                    disablePast
                    // shouldDisableDate={checkPublishDate}
                    value={inputs.expiryDate}
                    onChange={(newValue) => handleExpiryDate(newValue)}
                  // disabled={action === 'VIEW' || !(inputs.publishDate)}
                  />
                </LocalizationProvider>


              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <TextField

                  required
                  name="qrLicense"
                  label="QR License"
                  type="number"
                  variant="standard"
                  value={inputs.qrLicense}
                  onChange={handleInputChange}
                />
                <TextField

                  required
                  name="adminLicense"
                  label="Admin License"
                  type="number"
                  variant="standard"
                  value={inputs.adminLicense}
                  onChange={handleInputChange}
                />
              </Box>

            </Stack>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant='contained'>Cancel</Button>
            <LoadingButton loading={isLoading} type='submit' variant='contained'>{action === 'CREATE' ? "Create" : "Update"}</LoadingButton>

          </DialogActions>
        </form>
      </Dialog>
    </Box>
  )
}
