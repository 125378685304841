import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DeleteOutline } from '@mui/icons-material';
import { v4 as uuid } from 'uuid';


// api
import { addQRLicense } from '../../api/superadmin/license';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(userGroupName, userGroup, theme) {
  return {
    fontWeight:
      userGroup.indexOf(userGroupName) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}
const initialState = {
  hospitalId: '',
  row: []
};
const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

export default function QRLicenseDialog({
  dialogOpen,
  setDialogOpen,
  setSnackbarMessage,
  setSnackbarOpen,
  accounts,
  setLicense
}) {
  const theme = useTheme();

  const [inputs, setInputs] = useState(initialState);
  // console.log(inputs);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([{ id: uuid() }]);

  const loggedUser = useSelector((state) => state.adminUser)
  const [selectedItem, setSelectedItem] = useState(null)

  // console.log(loggedUser);

  const { modules } = loggedUser.commonData

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log('submit', inputs);

    setIsLoading(true)
    try {
      const { data } = await addQRLicense(inputs)
      if (data.success) {
        // console.log({ data });
        setLicense(data.qrCodeData)
        setDialogOpen(false);
        setSnackbarMessage({ msg: data.message, success: false });
        setSnackbarOpen(true);
        setSelectedItem(null)
        setIsLoading(false);

      } else {
        setIsLoading(false);
        setSnackbarMessage({ msg: data.message, success: false });
        setSnackbarOpen(true);
      }
    } catch (error) {
      setIsLoading(false);
      setSnackbarMessage({ msg: error.message, success: false });
      setSnackbarOpen(true);
    }
  };
  const handleSelect = (event) => {
    // console.log(event.target.value);
    setSelectedItem(accounts.find((item) => item.hospitalId === event.target.value));
    setInputs({ ...inputs, hospitalId: event.target.value })
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleAddRow = () => {
    // console.log('ck', rows.length, selectedItem.availableQrLicense);
    if (rows.length < selectedItem.availableQrLicense) {
      setRows([...rows, { id: uuid(), bed : '', block:'', department:'', floor:'', nurseStation:'', room:'' }])
    }
    else {
      setSnackbarMessage({ msg: 'QR license limit reached', success: false });
      setSnackbarOpen(true);
    }

  }

  const handleInputChange = (e, item) => {
    setRows(rows.map((it) => {
      if (it.id === item.id) {
        //  console.log('ininin');
        return { ...it, [e.target.name]: e.target.value }
      }
      return it;
    }))
    
    // setQuestion({ ...question, rowLabels: rowLabel });
  };

  const handleDeleteRow = (id) => {
    //  console.log({rows});
    //  console.log(rows.filter(a => a.id !== id))
    setRows(rows.filter(a => a.id !== id))
  };

  useEffect(() => {
    setInputs({ ...inputs, qrInput: rows });
    console.log('ck', rows, rows.some(obj => Object.values(obj).some(value => value === null || value === undefined || value === "")));
  }, [rows])
  useEffect(() => {
    setInputs(initialState);
    setRows([])
  }, [dialogOpen])


  return (
    <Box>
      <Dialog open={dialogOpen} onClose={handleClose} maxWidth={600}>

        <form onSubmit={handleSubmit}>
          <DialogContent>

            <Stack spacing={2} >
              <Typography variant='h5'>Generate QR License</Typography>
              <FormControl fullWidth variant="standard">
                <InputLabel id="demo-simple-select-label">Choose Account</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={inputs.hospitalId}
                  label="Choose Account"
                  onChange={handleSelect}
                >
                  {accounts.map((item) =>
                    item.status === 'active' && <MenuItem key={item.hospitalId} value={item.hospitalId}>{item.hospitalName}</MenuItem>
                  )}


                </Select>
              </FormControl>
              {selectedItem &&
                <>
                  <Box display='flex' justifyContent='space-between'>
                    <Typography>Total License : {selectedItem.totalQrLicense || 0}</Typography>
                    <Typography>Available License : {selectedItem.availableQrLicense || 0}</Typography>
                  </Box>
                  {rows.map((item, ind) =>

                    <Box display='flex' justifyContent='space-between' alignItems='flex-end' key={ind} >
                      <TextField
                        required
                        autoFocus
                        name="bed"
                        label="Bed No"
                        type="number"
                        variant="standard"
                        value={item.bed}
                        onChange={(e) => handleInputChange(e, item)}
                      />
                      <TextField
                        required

                        name="room"
                        label="Room No"
                        type="text"
                        variant="standard"
                        value={item.room}
                        onChange={(e) => handleInputChange(e, item)}
                      />
                      <TextField
                        required
                        name="nurseStation"
                        label="Nurse Station No"
                        type="text"
                        variant="standard"
                        value={item.nurseStation}
                        onChange={(e) => handleInputChange(e, item)}

                      />
                      <TextField
                        required

                        name="floor"
                        label="Floor"
                        type="text"
                        variant="standard"
                        value={item.floor}
                        onChange={(e) => handleInputChange(e, item)}
                      />
                      <TextField
                        required

                        name="department"
                        label="Department"
                        type="text"
                        variant="standard"
                        value={item.department}
                        onChange={(e) => handleInputChange(e, item)}
                      />
                      <TextField
                        required

                        name="block"
                        label="Block"
                        type="text"
                        variant="standard"
                        value={item.block}
                        onChange={(e) => handleInputChange(e, item)}
                      />
                      <DeleteOutline sx={{ cursor: 'pointer' }} onClick={() => handleDeleteRow(item.id)} />
                    </Box>


                  )}

                  <Button
                   variant='contained'
                    onClick={() => handleAddRow()}
                    disabled={rows.length && rows.some(obj =>
                      Object.values(obj).some(value => value === null || value === undefined || value === ""))}>
                    Add new row
                  </Button>
                </>}
            </Stack>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant='contained' >Cancel</Button>
            <LoadingButton loading={isLoading} disabled={selectedItem === null} type='submit' variant='contained' >Generate</LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  )
}
