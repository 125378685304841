import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
    Box,
    Chip,
    CircularProgress,
    FormControl,
    Icon,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';
import { addServices, updateServices, updateSubServices, deleteSubServices } from '../../api/admin/services';

const initialState = {
    serviceTitle: '',
    subServiceTitle: ''
};

const services = [
    { id: 1, serviceTitle: 'Cleaning' },
    { id: 2, serviceTitle: 'Requests' },
    { id: 3, serviceTitle: 'Electrical' },
    { id: 4, serviceTitle: 'AC' },
    { id: 5, serviceTitle: 'Plumbing' },
    { id: 6, serviceTitle: 'Furniture/Carpentary' },
    { id: 7, serviceTitle: 'General' },
    { id: 8, serviceTitle: 'Oxygen' },
]

export default function SubServicesDialog({
    dialogOpen,
    setDialogOpen,
    setDialogOpen1,
    subServTitle,
    servTitle,
    action,
    setSnackbarMessage,
    setSnackbarOpen,
    setAdminManager,
    setServices
}) {
    const { commonData } = useSelector((state) => state.adminUser.user)

    const theme = useTheme();
    const [inputs, setInputs] = useState(initialState);
    const [tempInputs, setTempInputs] = useState();
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);




    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        try {
            const { data } = action === 'CREATE' ? await addServices(inputs) : await updateServices(servTitle, inputs)
            if (data.success) {
                console.log('submitted', { data });
                setServices(data.subServices)
                setIsLoading(false);
                setSnackbarMessage({ msg: data.message, success: true });
                setSnackbarOpen(true);
                setDialogOpen(false);

            } else {
                setIsLoading(false);
                setSnackbarMessage({ msg: data.message, success: false });
                setSnackbarOpen(true);
            }
        } catch (error) {
            setIsLoading(false);
            setSnackbarMessage({ msg: error.message, success: false });
            setSnackbarOpen(true);
        }
    };

    const handleInputChange = (e) => {
        setInputs({ ...inputs, [e.target.name]: e.target.value })
        // console.log(inputs);
    };

    const handleSelect = (event) => {
        console.log(event.target.value, 'a');
        // setRole(event.target.value);
        setInputs({ ...inputs, serviceTitle: event.target.value })
    };

    const handleClose = () => {
        setDialogOpen(false);
        setInputs(initialState);
    };
    const handleSkip = () => {
        setDialogOpen(false);
        setDialogOpen1(true);
    };
    const handleChange = (e) => {
        setInputs({ ...inputs, [e.target.name]: e.target.value });
        setTempInputs({ ...tempInputs, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        // console.log('in hook', action);
        if (action === 'EDIT') {
            // console.log('am in', selectedItem);
            setInputs({
                serviceTitle: servTitle,
                subServiceTitle: subServTitle,
                
            })
            console.log('edit');

        }
        else {
            setInputs(initialState);
            console.log('add');

        }
    }, [dialogOpen])

    return (
        <Box>
            <Dialog open={dialogOpen} onClose={handleClose}>
                <DialogTitle>{action === 'CREATE' ? 'Add Sub-Services' : 'Edit Sub-Services'} </DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <FormControl variant="standard" sx={{ minWidth: 350 }}>
                            <InputLabel id="demo-simple-select-standard-label">Service Title</InputLabel>
                            <Select
                                required
                                name="serviceTitle"
                                labelId="demo-simple-select-standard-label"
                                value={inputs.serviceTitle}
                                onChange={handleSelect}
                                label="Service Title"
                            >
                                {services.map((item) => (
                                    <MenuItem key={item.id} value={item.serviceTitle}>{item.serviceTitle}</MenuItem>

                                ))}

                            </Select>
                        </FormControl>

                        <TextField
                            required
                            margin="dense"
                            name="subServiceTitle"
                            label="Sub-Service Title"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={inputs.subServiceTitle}
                            onChange={handleInputChange}

                        // {...commonData.superAdminRoles.map((item) => (
                        //     <MenuItem key={item._id} value={item.subService} >{item.subService}</MenuItem>
                        // ))}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant='contained' >Cancel</Button>
                        <LoadingButton loading={isLoading} type='submit' variant='contained' >{action === 'CREATE' ? 'Add' : 'Update'}</LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>
        </Box>
    )
}
