import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import axios from 'axios';
import { useSelector } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// @mui
import { styled } from '@mui/material/styles';
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Box,
    Rating,
    CircularProgress,
    Chip,
    TableHead,
    Collapse,
} from '@mui/material';
import { Rowing } from '@mui/icons-material';
import Row from '@mui/material/Paper';
import { LoadingButton } from '@mui/lab';

import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import QrCodeIcon from '@mui/icons-material/QrCode';

// dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// Rating Icons
import PropTypes from 'prop-types';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
// components
import ServicesDialog from '../../sections/dialogs/servicesDialog';
import SubServicesDialog from '../../sections/dialogs/subServicesDialog';
import MuteDialog from '../../sections/dialogs/MuteDialog';

// import AdminUserDialog from 'src/sections/dialogs/adminUserDialog';
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import CustomSnackBar from '../../components/snackbar';
import MenuPopover from '../../components/popover/menuPopover';
// sections
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import QRLicenseDialog from '../../sections/dialogs/qrLicenseDialog';
// import MuteDialog from '../../sections/dialogs/MuteDialog';

// icon
import edit from '../../assets/icons/ic_edit.svg';
import bin from '../../assets/icons/ic_delete.svg';

// api
import { getQRLicense, exportQRLicense, getServices } from '../../api/superadmin/license';

// ----------------------------------------------------------------------

export default function Services() {

    const account = useSelector((state) => state.account.hospitalAccount)
    const [isLoading, setIsLoading] = useState(false)
    // snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState(null)
    const [action, setAction] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogOpen1, setDialogOpen1] = useState(false);
    const [dialogOpen2, setDialogOpen2] = useState(false);
    const [promptOpen, setPromptOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null)
    const [serviceTitle, setServiceTitle] = useState(null);
    const [serviceType, setServiceType] = useState(null);


    // const [open, setOpen] = useState(null)
    const [services, setServices] = useState(null)



    // Dialog
    const handleServicesOpen = (item, str) => {
        // e.stopPropogation();
        if (item) {
            // console.log(item);
            setSelectedItem(item)
        }
        setAction(str)
        // console.log(str);
        setDialogOpen(true);
        console.log(str);

    };

    const handleSubServicesOpen = (serviceTitle, subServiceTitle, str) => {
        if (serviceTitle) {
            // console.log(item);
            setSelectedItem(subServiceTitle)
            console.log(subServiceTitle);
        }
        setServiceTitle(serviceTitle)
        setAction(str)
        setDialogOpen1(true);
        // setOpen(open);
        // console.log(item);
    };

    const handleDelete = () => { }



    function Row(props) {
        const { item } = props;
        const [openCollapse, setOpenCollapse] = useState(false);
        return (
            <>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' }}} key={item.serviceId}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenCollapse(!openCollapse)}
                        >
                            {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell width={'200px'} component="th" scope="row">
                        {item.serviceTitle}
                    </TableCell>
                    <TableCell width={'450px'} component="th" scope="row">
                        {item.serviceType}
                    </TableCell>
                    <TableCell>
                        <Box>
                            <Button onClick={() => handleServicesOpen(item, 'EDIT')}>
                                <img src={edit} alt="edit" />
                            </Button>
                            <Button
                                onClick={() => handleDelete(item.serviceTitle)}
                            >
                                <img src={bin} alt="edit" />
                            </Button>
                        </Box>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>

                                <>
                                    {/* <Typography fontWeight={1000} variant="h6" gutterBottom component="div">
                                                        USERNAME
                                                    </Typography> */}
                                    <Table size="small" aria-label="purchases">
                                        <TableHead  >
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: 1000 }} >Sub Services</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {item.subServices?.map((servreq) =>
                                                <TableRow key={servreq.serviceId}>
                                                    <Box sx={{ margin: 1 }} display='flex' justifyContent='space-between'>
                                                        <TableCell width={'600px'} >{servreq.subServiceTitle}</TableCell>
                                                        <Button onClick={() => handleSubServicesOpen(item.serviceTitle, servreq.subServiceTitle, 'EDIT')}>
                                                            <img src={edit} alt="edit" />
                                                        </Button>
                                                        <Button
                                                            onClick={() => handleDelete(servreq.subServiceTitle)}
                                                        >
                                                            <img src={bin} alt="edit" />
                                                        </Button>
                                                    </Box>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        )
    };

    const fetchData = async () => {
        setIsLoading(true)
        try {
            const { data } = await getServices(account._id)
            if (data.success) {

                setServices(data.housekeepingAndMaintenance.services)
                // console.log(services);
                setIsLoading(false);

            }
            else {
                setSnackbarMessage({ msg: "Request failed", success: false });
                setSnackbarOpen(true);
            }

        }
        catch (error) {
            // setIsLoading(false)
            console.log("err ", error);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])




    return (
        <>
            <Helmet>
                <title> Services | Teller One </title>
            </Helmet>

            {!isLoading ? (

                <Box sx={{ height: '100%' }}>

                    <Box sx={{ backgroundColor: 'aliceblue', padding: '10px' }}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <Box>
                                <Typography variant="h4" gutterBottom>
                                    Services
                                </Typography>
                            </Box>
                            <Box>
                                <Button sx={{ marginLeft: 1 }} variant='contained' type='submit' onClick={() => handleServicesOpen(null, 'CREATE')}>+ Add Services</Button>
                                <Button sx={{ marginLeft: 1 }} variant='contained' type='submit' onClick={() => handleSubServicesOpen(null, null, 'CREATE')}>+ Add SubServices</Button>

                            </Box>
                        </Stack>

                        {services?.map((item) =>
                            <Box  >
                                <Row key={item.serviceId} item={item} />
                            </Box>
                        )}
                    </Box>
                </Box>

            ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}><CircularProgress /></Box>
            )
            }
            <ServicesDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} setSnackbarMessage={setSnackbarMessage} setSnackbarOpen={setSnackbarOpen} item={selectedItem} action={action} />
            <SubServicesDialog dialogOpen={dialogOpen1} setDialogOpen={setDialogOpen1} setSnackbarMessage={setSnackbarMessage} setSnackbarOpen={setSnackbarOpen} subServTitle={selectedItem} action={action} servTitle={serviceTitle} />
            <CustomSnackBar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarMessage={snackbarMessage && snackbarMessage.msg} success={snackbarMessage && snackbarMessage.success} />
            {/* {dialogOpen && <MuteDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} setSnackbarMessage={setSnackbarMessage} setSnackbarOpen={setSnackbarOpen} selectedItem={selectedItem} action={action} />} */}

        </>
    );
}
