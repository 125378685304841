import { createApiConfig } from "../config";

import { SUPER_ADMIN_API_URL } from "../routes"

const apiConfig = createApiConfig();


// super admin crud

export const getSuperAdmin = () => apiConfig.get(SUPER_ADMIN_API_URL);

// CREATE superAdmin User

export const addSuperAdmin =  (data) => apiConfig.post(SUPER_ADMIN_API_URL, data);

// DELETE superAdmin User

export const deleteSuperAdmin = (id) => apiConfig.delete(`${SUPER_ADMIN_API_URL}/${id}`);

// Update superAdmin User

export const updateSuperAdmin = (id,data) => apiConfig.put(`${SUPER_ADMIN_API_URL}/${id}`, data);