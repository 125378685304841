import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import Dropzone, { useDropzone } from 'react-dropzone'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { DeleteOutline, PlusOneOutlined } from '@mui/icons-material';
import { addHospitalInformation, updateHospitalInformation } from '../../api/admin/hospitalInformation';


const initialState = {
  banner: '',
  aboutUs: '',
  doctorsDirectory: '',
  whatsNew: '',
  servicesAndFacilities: '',
  linkToWebsite: '',
  location: '',
};

export default function HospitalInformationDialog({
  dialogOpen,
  setDialogOpen,
  setDialogOpen1,
  selectedItem,
  action,
  setSnackbarMessage,
  setSnackbarOpen,
  setHospitalInformation,
  hospitalInformation
}) {
  const formData = new FormData();
  const { commonData } = useSelector((state) => state.adminUser.user)
  const account = useSelector((state) => state.account)

  const theme = useTheme();
  const [inputs, setInputs] = useState(initialState);
  const [tempInputs, setTempInputs] = useState();
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [tempFile, setTempFile] = useState(null);

  const appendFormData = () => {
    formData.append("aboutUs", inputs.aboutUs);
    formData.append("location", inputs.location);
    formData.append("linkToWebsite", inputs.linkToWebsite);
    if (inputs.fileData) {
      formData.append("file", inputs.fileData);
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    appendFormData()
    console.log('chk', inputs, account);
    setIsLoading(true)

    try {
      const { data } = action === 'CREATE' ? await addHospitalInformation(account.hospitalAccount._id, formData) : await updateHospitalInformation(account.hospitalAccount._id, formData)
      console.log({ data });
      if (data.success) {

        setHospitalInformation(data.hospitalInformation)
        setIsLoading(false);
        setSnackbarMessage({ msg: data.message, success: true });
        setSnackbarOpen(true);
        setDialogOpen(false);

      } else {
        setIsLoading(false);
        setSnackbarMessage({ msg: data.message, success: false });
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
      setSnackbarMessage({ msg: error.message, success: false });
      setSnackbarOpen(true);
    }
  };

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value })
  };

  const handleSelect = (event) => {
    console.log(event.target.value);
    // setRole(event.target.value);
    setInputs({ ...inputs, roleId: event.target.value })
  };

  const handleClose = () => {
    setDialogOpen(false);
  };
  const handleSkip = () => {
    setDialogOpen(false);
    setDialogOpen1(true);
  };
  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
    setTempInputs({ ...tempInputs, [e.target.name]: e.target.value });
  };

  // file handling
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles[0].size / 1000 <= 2048 && (acceptedFiles[0].type === "image/png" || acceptedFiles[0].type === "image/jpeg")) {
      setFile(Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0])
      }));
      setTempFile(acceptedFiles[0])
    }
    else {
      setSnackbarMessage({ msg: "validation error", success: false });
      setSnackbarOpen(true);
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  const handleDiscardFile = (e) => {
    e.preventDefault();
    setFile(null)
    //  console.log('discard');
  };

  useEffect(() => {
    setInputs({ ...inputs, fileData: tempFile })
    setTempInputs({ ...tempInputs, fileData: tempFile })
  }, [tempFile])


  useEffect(() => {
    console.log('in hook', action, hospitalInformation);
    if (action !== 'CREATE' && hospitalInformation) {
      console.log('in');
      setInputs({

        aboutUs: hospitalInformation.aboutUs,
        linkToWebsite: hospitalInformation.linkToWebsite,
        location: hospitalInformation.location,
      })
      setFile({ preview: hospitalInformation.Hospitallogo })
    }
    else {
      setInputs(initialState);
    }
  }, [dialogOpen])

  return (
    <Box>
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>{action === 'CREATE' ? "Add" : "Edit"} Hospital Information</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>

            <TextField
              required
              autoFocus
              multiline
              minRows={4}
              margin="dense"
              name="aboutUs"
              label="About Us"
              type="text"
              fullWidth
              variant="standard"
              value={inputs.aboutUs}
              onChange={handleInputChange}
            />
            <TextField
              required
              margin="dense"
              name="location"
              label="Location"
              type="text"
              fullWidth
              variant="standard"
              value={inputs.location}
              onChange={handleInputChange}
            />

            <TextField
              required
              margin="dense"
              name="linkToWebsite"
              label="Website"
              type="text"
              fullWidth
              variant="standard"
              value={inputs.linkToWebsite}
              onChange={handleInputChange}
            />

            <Box sx={{ border: '1px solid #D0D5DD', borderRadius: 1, mt: 2 }}>

              {file ?
                <Box>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Icon onClick={handleDiscardFile} sx={{ marginLeft: 'auto', cursor: 'pointer' }}><DeleteOutline /></Icon>
                  </Box>

                  <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={file.preview} alt='logo' width={120} />
                    {file.name && <Typography variant="subtitle1" >{file.name}</Typography>}
                    {file.size && <Typography variant="caption" >{(file.size / 1000).toFixed(2)} KB</Typography>}
                  </Stack>
                </Box>


                :
                <Box display="flex" justifyContent="center" alignItems="center" flexDirection='column' p={2}>
                  <div {...getRootProps()} style={{ marginTop: 20 }}>
                    <input {...getInputProps()} />
                    <Stack>
                      <Typography variant='subtitle7' color='psu_subtitle3.main'><font color='#265895'><b>Click to Upload logo</b></font> or drag and drop</Typography>
                      <Typography variant='subtitle8' color='psu_subtitle3.main'>JPEG, PNG File with Maximum size of 2 MB</Typography>
                    </Stack>
                  </div>
                </Box>
              }
            </Box>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant='contained' >Cancel</Button>
            <LoadingButton loading={isLoading} type='submit'  variant='contained' >{action === 'CREATE' ? "Add" : "Update"}</LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </Box >
  )
}
