import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DeleteOutline } from '@mui/icons-material';
import { v4 as uuid } from 'uuid';


// api
import { addHousekeepingService , addMaintenanceService} from '../../api/admin/service';


const initialState = {
  hospitalId: '',
  row: []
};


export default function AddServieceDialog({
  dialogOpen,
  setDialogOpen,
  action,
  setSnackbarMessage,
  setSnackbarOpen,
  setHousekeepingService,
  setMaintenanceService,
  housekeepingService,
  maintenanceService
}) {
  const theme = useTheme();

  const [inputs, setInputs] = useState(initialState);
  // console.log(inputs);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([{ id: uuid() }]);
  const {hospitalAccount} = useSelector((state) => state.account)
  const [sectionItems, setSectionItems] = useState([])

  // console.log(loggedUser);


  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log('submit', sectionItems,inputs);
    setIsLoading(true)
    let postData = inputs
    if(action === 'housekeeping' && housekeepingService.length)
    {
      console.log('hks');
      postData = [...inputs, ...housekeepingService] 
    }
    if(action === 'maintenance' && maintenanceService.length)
    {
      console.log('ms', [...inputs, ...maintenanceService]);
      postData = [...inputs, ...maintenanceService] 
    }
    try {
      const { data } = action === 'housekeeping' ? await addHousekeepingService(hospitalAccount._id, {serviceType:"housekeeping", services:postData}) : await addMaintenanceService(hospitalAccount._id, {serviceType:"maintenance", services:postData})
      if (data.success) {
        if( action === 'housekeeping')
        {
          setHousekeepingService(data.services.services)
        }
        else{
          setMaintenanceService(data.services.services)
        }
        setIsLoading(false);
        setDialogOpen(false);
        setSnackbarMessage({ msg: data.message, success: false });
        setSnackbarOpen(true);
      } else {
        setIsLoading(false);
        setSnackbarMessage({ msg: data.message, success: false });
        setSnackbarOpen(true);
      }
    } catch (error) {
      setIsLoading(false);
      setSnackbarMessage({ msg: error.message, success: false });
      setSnackbarOpen(true);
    }
  };
  
  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleInputChange = (e, item) => {
   console.log(item);
   setSectionItems(item)
  };

  const handleDeleteRow = (id) => {
    //  console.log({rows});
    //  console.log(rows.filter(a => a.id !== id))
    setRows(rows.filter(a => a.id !== id))
  };

  useEffect(() => {
   console.log({sectionItems});
   setInputs(sectionItems.map((item) => ({serviceTitle : item})))
  }, [sectionItems])

  
  useEffect(() => {
    console.log({housekeepingService} , {maintenanceService});
    setInputs(initialState);
    setSectionItems([])
  }, [dialogOpen])


  return (
    <Box>
      <Dialog open={dialogOpen} onClose={handleClose} >

        <form onSubmit={handleSubmit}>
          <DialogContent>

            <Stack spacing={2} >
              <Typography variant='h5'>Add {action === 'housekeeping' ? 'Housekeeping' : 'Maintenance'} service</Typography>
              <Autocomplete
              
              multiple
              freeSolo
              id="tags-outlined"
              options={[]}
              onChange={handleInputChange}
              // value={value}
              renderInput={(params) => (
                <TextField
                  {...params}

                  variant="outlined"
                  label="Add sections"
                />
              )}
            />
            </Stack>
            <Typography variant='subtitle2' color='red'>*Enter to add service items</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant='contained'>Cancel</Button>
            <LoadingButton loading={isLoading} type='submit' variant='contained' >Add</LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  )
}
