import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
    Box,
    Chip,
    CircularProgress,
    FormControl,
    Icon,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';
import { addServices, updateServices, updateSubServices, deleteSubServices } from '../../api/admin/services';

const initialState = {
    serviceType: '',
    serviceTitle: ''
};

const services = [
    { id: 1, serviceType: 'HouseKeeping' },
    { id: 2, serviceType: 'Maintenance' },
]

export default function ServicesDialog({
    dialogOpen,
    setDialogOpen,
    setDialogOpen1,
    item,
    action,
    setSnackbarMessage,
    setSnackbarOpen,
    setAdminManager,
    setServices
}) {
    const { commonData } = useSelector((state) => state.adminUser.user)
    const hospitalId = useSelector((state) => state.account.hospitalAccount._id)

    const theme = useTheme();
    const [inputs, setInputs] = useState(initialState);
    const [tempInputs, setTempInputs] = useState();
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);




    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        try {
            const { data } = action === 'CREATE' ? await addServices(hospitalId, inputs) : await updateServices(hospitalId, inputs)
            if (data.success) {
                // console.log({ data });
                setServices(data.services)
                setIsLoading(false);
                setSnackbarMessage({ msg: data.message, success: true });
                setSnackbarOpen(true);
                setDialogOpen(false);

            } else {
                setIsLoading(false);
                setSnackbarMessage({ msg: data.message, success: false });
                setSnackbarOpen(true);
            }
        } catch (error) {
            setIsLoading(false);
            setSnackbarMessage({ msg: error.message, success: false });
            setSnackbarOpen(true);
        }
    };

    const handleInputChange = (e) => {
        setInputs({ ...inputs, [e.target.name]: e.target.value })
        // console.log(inputs);
    };

    const handleSelect = (event) => {
        console.log(event.target.value, 'a');
        // setRole(event.target.value);
        setInputs({ ...inputs, serviceType: event.target.value })
    };

    const handleClose = () => {
        setDialogOpen(false);
        setInputs(initialState);
    };
    const handleSkip = () => {
        setDialogOpen(false);
        setDialogOpen1(true);
    };
    const handleChange = (e) => {
        setInputs({ ...inputs, [e.target.name]: e.target.value });
        setTempInputs({ ...tempInputs, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        // console.log('in hook', action);
        if (action !== 'CREATE') {
            // console.log('am in', selectedItem);
            setInputs({
                serviceType: item.serviceType,
                serviceTitle: item.serviceTitle,
            })

        }
        else {
            setInputs({ ...initialState });

        }
    }, [dialogOpen])

    return (
        <Box>
            <Dialog open={dialogOpen} onClose={handleClose}>
                <DialogTitle>Add Services</DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <FormControl variant="standard" sx={{ minWidth: 350 }}>
                            <InputLabel id="demo-simple-select-standard-label">Service Type</InputLabel>
                            <Select
                                required
                                labelId="demo-simple-select-standard-label"
                                value={inputs.serviceType}
                                onChange={handleSelect}
                                name="serviceType"
                                label="serviceType"
                            >
                                {services.map((item) => (
                                    <MenuItem key={item.id} value={item.serviceType}>{item.serviceType}</MenuItem>

                                ))}

                            </Select>
                        </FormControl>

                        <TextField
                            required
                            margin="dense"
                            name="serviceTitle"
                            label="Service Title"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={inputs.serviceTitle}
                            onChange={handleInputChange}

                        // {...commonData.superAdminRoles.map((item) => (
                        //     <MenuItem key={item._id} value={item.superAdminRoleId} >{item.superAdminRoleName}</MenuItem>
                        // ))}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant='contained' >Cancel</Button>
                        <LoadingButton loading={isLoading} type='submit'  variant='contained' >Create</LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>
        </Box>
    )
}
