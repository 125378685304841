import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// api
import { muteQRLicense } from '../../api/admin/qrlicense';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(userGroupName, userGroup, theme) {
  return {
    fontWeight:
      userGroup.indexOf(userGroupName) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}
const initialState = {
  hours: '',
  mins: '',
};

export default function MuteDialog({
  dialogOpen,
  setDialogOpen,
  setLicense,
  setAccounts,
  selectedItem,
  action,
  setSnackbarMessage,
  setSnackbarOpen,
  setLength
}) {
  const theme = useTheme();

  const [inputs, setInputs] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [personName, setPersonName] = useState([]);
  const [selectedModules, setSelectedModules] = useState()
  const loggedUser = useSelector((state) => state.adminUser)

  // console.log(loggedUser);

  const { modules } = loggedUser.commonData

  const handleSubmit = async (e) => {
    e.preventDefault()

    // console.log('submit', inputs, selectedModules);

    setIsLoading(true)
    try {
      const { data } = await muteQRLicense(selectedItem, { ...inputs, toMute: true })
      if (data.success) {
        console.log({ data });
        setLicense(data.qrCodeData)
        setDialogOpen(false);

      } else {
        setIsLoading(false);
        setSnackbarMessage({ msg: data.message, success: false });
        setSnackbarOpen(true);
      }
    } catch (error) {
      setIsLoading(false);
      setSnackbarMessage({ msg: error.message, success: false });
      setSnackbarOpen(true);
    }
  };
  const handleSelect = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    // value.map((item) => (
    //   setSelectedModules([...selectedModules, {moduleId:item, moduleName:item}])
    // ))
    setInputs({ ...inputs, modules: event.target.value });

  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleInputChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: parseInt(e.target.value, 10) });
  };

  useEffect(() => {
    // console.log('in hook', action, selectedItem);
    if (action !== 'CREATE') {
      // console.log('am in', selectedItem);
      setInputs({
        hospitalName: selectedItem.hospitalName,
        location: selectedItem.location,
        email: selectedItem.email,
        contactNo: selectedItem.contactNo,
        website: selectedItem.website,
        qrLicense: selectedItem.qrLicense,
        adminLicense: selectedItem.adminLicense,
      })

    }
    else {
      setInputs(initialState);
      setPersonName([]);
    }
  }, [dialogOpen])

  return (
    <Box>
      <Dialog open={dialogOpen} onClose={handleClose}>

        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Typography variant='h6'>{action === 'MUTE' ? "Mute" : "Mute"}  License</Typography>
            <TextField
              autoFocus
              required
              margin="dense"
              name="hours"
              label="Hours"
              type="number"
              fullWidth
              variant="standard"
              value={inputs.hours}
              onChange={handleInputChange}
            />
            <TextField
              required
              margin="dense"
              name="minutes"
              label="Minutes"
              type="number"
              fullWidth
              variant="standard"
              value={inputs.mins}
              onChange={handleInputChange}
            />

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant='contained' >Cancel</Button>
            <Button type='submit' variant='contained' >{action === 'MUTE' ? "Mute" : "Mute"}</Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  )
}
