import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Box, CircularProgress, Stack } from '@mui/material';
// components
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../../sections/@dashboard/app';
import { getRequestData } from '../../api/admin/request';
import allRequestIcon from '../../assets/icons-new/dashboard/all-request.svg'
import assignedRequestIcon from '../../assets/icons-new/dashboard/assigned-request.svg'
import completedRequestIcon from '../../assets/icons-new/dashboard/completed-request.svg'
import openRequestIcon from '../../assets/icons-new/dashboard/open-request.svg'
import requestInprogressIcon from '../../assets/icons-new/dashboard/request-in-progress.svg'
import requestOnHoldIcon from '../../assets/icons-new/dashboard/request-on-hold.svg'

const getColor = ['secondary', 'info', 'warning', 'error']
// ----------------------------------------------------------------------

export default function AdminDashboard() {
  const theme = useTheme();
  const navigate = useNavigate()

  const adminUserState = useSelector((state) => state.adminUser)
  const { isAuthenticated, user } = adminUserState

  const [isLoading, setIsLoading] = useState(false)
  const [dashboardData, setDashboardData] = useState(false)
  const [chartData, setChartData] = useState(false)
  const [currentChartData, setCurrentChartData] = useState(false)
  
  const hospital = useSelector((state) => state.account.hospitalAccount)
  
  // console.log(adminUserState)
  const fetchData = async () => {
    setIsLoading(true)
    try {
      const { data } = await getRequestData(hospital._id);
      setDashboardData(data.dashboard)
      setChartData(data.chart)
      setCurrentChartData(data.chart.dayWise)
      setIsLoading(false)
    }
    catch (e) {
      setIsLoading(false)
      // console.log({ e });
    }
  }

  // get dashboard data
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard | Teller One </title>
      </Helmet>
      <Box sx={{ height: '100%' }}>
        {!isLoading ?
          <Box ml={1}>
            <Typography variant="h4" >
              Hi, Welcome back!
            </Typography>

            <Grid container spacing={3} mt={1}>
              {chartData &&
                <Grid item xs={12} md={5} lg={5} sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <AppWebsiteVisits
                    title="All requests"
                    subheader="(+1.23%) than last year"
                    allChartData={chartData}
                    setCurrentChartData={setCurrentChartData}
                    chartLabels={currentChartData?.map((item) => item.date)}
                    chartData={[
                      {
                        name: 'Requests Raised',
                        type: 'column',
                        fill: 'solid',
                        data: currentChartData?.map((item) => item.cnt),
                      },

                    ]}
                  />
                </Grid>}
              {dashboardData &&
                <Grid item xs={12} md={7} lg={7} mx={{ xs: 1, sm: 0 }}>
                  <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2 }}>
                    <Grid item xs={4} sm={4} md={4} onClick={() => navigate('/requests', { state: { filter: 'all' } })} sx={{ cursor: 'pointer' }}>
                      <Box sx={{ borderRadius: 2, boxShadow: 24, background: 'linear-gradient(135deg, rgba(218,36,43,1) 0%, rgba(149,37,128,1) 48%, rgba(46,52,126,1) 100%)', color: '#fff', width: { xs: 110, sm: 170 }, height: { xs: 150, sm: 220 } }}>
                        <Stack p={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 2, height: '100%' }}>
                          <img src={allRequestIcon} alt='img' width={40} />
                          <Typography mt={3} mb={2} variant='subtitle1'>All requests</Typography>
                          <Typography variant='h3'>{dashboardData.allRequests}</Typography>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} onClick={() => navigate('/requests', { state: { filter: 'all' } })} sx={{ cursor: 'pointer' }}>
                      <Box sx={{ borderRadius: 2, boxShadow: 24, backgroundColor: '#000', color: '#fff', width: { xs: 110, sm: 170 }, height: { xs: 150, sm: 220 } }}>
                        <Stack p={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 2, height: '100%' }}>
                          <img src={openRequestIcon} alt='img' width={40} height={40} />
                          <Typography mt={3} mb={2} variant='subtitle1'>Open requests</Typography>
                          <Typography variant='h3'>{dashboardData.openRequests}</Typography>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} onClick={() => navigate('/requests', { state: { filter: 'all' } })} sx={{ cursor: 'pointer' }}>
                      <Box sx={{ borderRadius: 2, boxShadow: 24, backgroundColor: '#000', color: '#fff', width: { xs: 110, sm: 170 }, height: { xs: 150, sm: 220 } }}>
                        <Stack p={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 2, height: '100%' }}>
                          <img src={assignedRequestIcon} alt='img' width={40} height={40} />
                          <Typography mt={3} mb={2} variant='subtitle1'>Assigned requests</Typography>
                          <Typography variant='h3'>{dashboardData.assignedRequests}</Typography>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} onClick={() => navigate('/requests', { state: { filter: 'all' } })} sx={{ cursor: 'pointer' }}>
                      <Box sx={{ borderRadius: 2, boxShadow: 24, backgroundColor: '#000', color: '#fff', width: { xs: 110, sm: 170 }, height: { xs: 150, sm: 220 } }}>
                        <Stack p={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 2, height: '100%' }}>
                          <img src={requestOnHoldIcon} alt='img' width={40} height={40} />
                          <Typography mt={3} mb={2} variant='subtitle1'>Request on hold</Typography>
                          <Typography variant='h3'>{dashboardData.requestsOnHold}</Typography>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} onClick={() => navigate('/requests', { state: { filter: 'all' } })} sx={{ cursor: 'pointer' }}>
                      <Box sx={{ borderRadius: 2, boxShadow: 24, backgroundColor: '#000', color: '#fff', width: { xs: 110, sm: 170 }, height: { xs: 150, sm: 220 } }}>
                        <Stack p={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 2, height: '100%' }}>
                          <img src={requestInprogressIcon} alt='img' width={40} height={40} />
                          <Typography mt={3} mb={2} variant='subtitle1'>Request in progress</Typography>
                          <Typography variant='h3'>{dashboardData.requestsInProgress}</Typography>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} onClick={() => navigate('/requests', { state: { filter: 'all' } })} sx={{ cursor: 'pointer' }}>
                      <Box sx={{ borderRadius: 2, boxShadow: 24, backgroundColor: '#000', color: '#fff', width: { xs: 110, sm: 170 }, height: { xs: 150, sm: 220 } }}>
                        <Stack p={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 2, height: '100%' }}>
                          <img src={completedRequestIcon} alt='img' width={40} height={40} />
                          <Typography mt={3} mb={2} variant='subtitle1'>Completed requests</Typography>
                          <Typography variant='h3'>{dashboardData.completedRequests}</Typography>
                        </Stack>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>}
              {chartData &&
                <Grid item xs={12} md={5} lg={5} sx={{ display: { xs: 'block', sm: 'none' } }}>
                  <AppWebsiteVisits
                    title="All requests"
                    subheader="(+1.23%) than last year"
                    chartLabels={chartData.dayWise.map((item) => item.date)}
                    chartData={[
                      {
                        name: 'Requests Raised',
                        type: 'column',
                        fill: 'solid',
                        data: [chartData.dayWise.map((item) => item.cnt)],
                      },

                    ]}
                  />
                </Grid>}
            </Grid>
          </Box>
          :
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}><CircularProgress /></Box>}
      </Box>
    </>
  );
}
