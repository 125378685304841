import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, Box, Grid, CircularProgress } from '@mui/material';

// lottie
import Lottie from "lottie-react";
import login from "../assets/lotties/secure-login.json";

// hooks
import useResponsive from '../hooks/useResponsive';
// components
import logo from '../assets/logo.svg';
import passwordImage from '../assets/reset.svg'
import passwordAdminImage from '../assets/reset-admin.svg'
import responsivePasswordImage from '../assets/responsive-reset.svg'

// sections
import { LoginForm } from '../sections/auth/login';
import ForgetPassword from '../sections/auth/login/ForgetPassword';
import { getAccountItem } from '../api/auth';
import CustomSnackBar from '../components/snackbar';
import { setAccountStateAction } from '../appRedux/actions/account';


// ----------------------------------------------------------------------

const ResetPassword = () => {
  const { user } = useParams()
  const dispatch = useDispatch();

  // snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState(null)



  return (
    <>
      <Helmet>
        <title>Reset Password | Teller One </title>
      </Helmet>
      <Grid container sx={{ height: '100vh', width: '100vw' }}>
        <Grid item xs={false} sm={false} md={6} lg={6} xl={6}>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <img src={user === 'superadmin' ? passwordImage : passwordAdminImage} alt='img' width={user === 'superadmin' ? 500 : 400} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%', mx: {xs:2, sm:8}, position: 'relative' }}>
            {/* responsive - large */}
            <Stack spacing={2} sx={{ display: { xs: 'none', sm: 'flex' }, flexDirection: 'column', alignItems: 'flex-end', }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <img src={logo} alt='img' width={200} />
                <Typography variant='caption'>powered by TELLERCOMM</Typography>
              </Box>
              <Typography variant='h3'>Reset your password</Typography>
            </Stack>
            {/* responsive - small */}
            <Stack sx={{ display: { xs: 'block', sm: 'none' } }} top={12} position={'relative'} >
              <img src={logo} alt='img' width={120} style={{marginLeft:20}} />
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <img src={responsivePasswordImage} alt='img' width={240} />
              </Box>
            </Stack>
            <ForgetPassword setSnackbarOpen={setSnackbarOpen} setSnackbarMessage={setSnackbarMessage} />
            <Box sx={{ position: 'absolute', left: {xs:'25%', sm:'35%'}, bottom: '3%' }}
            >
              <Typography color='#B7B7B7' variant='body1'> © Tellercomm {new Date().getFullYear()}</Typography>
            </Box>
          </Box>

        </Grid>
      </Grid>
      <CustomSnackBar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarMessage={snackbarMessage && snackbarMessage.msg} success={snackbarMessage && snackbarMessage.success} />
    </>
  );
}
export default ResetPassword;