import { createApiConfig } from "../config";

import { ADMIN_MANAGER_API_URL } from "../routes"

const apiConfig = createApiConfig();


// super admin crud
export const getAdminManager = () => apiConfig.get(ADMIN_MANAGER_API_URL);

// CREATE admin manager

export const addAdminManager = (data) => apiConfig.post(ADMIN_MANAGER_API_URL, data);

// DELETE admin manager

export const deleteAdminManager = (id) => apiConfig.delete(`${ADMIN_MANAGER_API_URL}/${id}`);

export const suspendAdminManager = (id) => apiConfig.put(`${ADMIN_MANAGER_API_URL}/suspend/${id}`);

// Update admin manager

export const updateAdminManager = (id,data) => apiConfig.put(`${ADMIN_MANAGER_API_URL}/${id}`, data);

export const approveAdminManager = (id,data) => apiConfig.put(`${ADMIN_MANAGER_API_URL}/approve/${id}`, data);