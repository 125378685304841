import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';


import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, Box, Grid, CircularProgress } from '@mui/material';


// components
import logo from '../assets/logo.svg';
import successImage from '../assets/reset-success.svg'
import successAdminImage from '../assets/reset-success-admin.svg'

import responsivePasswordImage from '../assets/responsive-reset.svg'
// sections

import CustomSnackBar from '../components/snackbar';


// ----------------------------------------------------------------------

const ResetSuccess = () => {
  const { user } = useParams()
  const navigate = useNavigate()

  // snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState(null)


  return (
    <>
      <Helmet>
        <title>Password Success | Teller One </title>
      </Helmet>
      <Grid container sx={{ height: '100vh', width: '100vw' }}>
        <Grid item xs={false} sm={false} md={6} lg={6} xl={6}>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <img src={user === 'superadmin' ? successImage : successAdminImage} alt='img' width={500} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%', mx: {xs:2, sm:8}, position: 'relative' }}>
           {/* responsive - large */}
            <Stack spacing={2} sx={{ display: {xs:'none', sm:'flex'}, flexDirection: 'column', alignItems: 'flex-end', }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <img src={logo} alt='img' width={200} />
                <Typography variant='caption'>powered by TELLERCOMM</Typography>
              </Box>
            </Stack>
            {/* responsive - small */}
            <Stack sx={{ display: { xs: 'block', sm: 'none' } }} top={12} position={'relative'} >
              <img src={logo} alt='img' width={120} style={{marginLeft:20}} />
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <img src={responsivePasswordImage} alt='img' width={300} />
              </Box>
            </Stack>
            <Stack mt={5} spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant='h3'>Password reset successful!</Typography>
              <Button sx={{ borderRadius: 4, width: 250 }} onClick={() => navigate( user === 'superadmin' ? '/login/superadmin' : '/login')} variant="contained">
                Back to login
              </Button>
            </Stack>
            <Box sx={{ position: 'absolute', left: {xs:'20%', sm:'35%'}, bottom: '5%' }}
            >
              <Typography color='#B7B7B7' variant='body1'> © Tellercomm {new Date().getFullYear()}</Typography>
            </Box>
          </Box>

        </Grid>
      </Grid>
      <CustomSnackBar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarMessage={snackbarMessage && snackbarMessage.msg} success={snackbarMessage && snackbarMessage.success} />
    </>
  );
}
export default ResetSuccess;