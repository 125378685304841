import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';

// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Box, Button, Typography, Autocomplete, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import Iconify from '../../../components/iconify';
import { sendEmailOnForgetPassword, resetPassword } from '../../../api/auth';
import { getAccountList } from '../../../api/superadmin/commonAPIs';

const HOSPITAL_LIST = [
  { id: 1, name: 'ABC Hospital', location: 'Chennai' },
  { id: 2, name: 'Nalam Hospital', location: 'Chennai' },
  { id: 3, name: 'KMC Hospital', location: 'Chennai' },
]
// ----------------------------------------------------------------------

export default function ForgetPassword({ setLoginState, setSnackbarMessage, setSnackbarOpen }) {
  const { user } = useParams()
  const navigate = useNavigate();
  // console.log({account});
  const [resetPasswordState, setResetPasswordState] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const [email, setEmail] = useState("");
  const [inputs, setInputs] = useState({
    email: "",
    otp: "",
    password: "",
  })

  const [open, setOpen] = useState(false);

  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    if (resetPasswordState) {
      setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    }
    else {
      setEmail(e.target.value)
      setInputs({ ...inputs, email: e.target.value })
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const { data } = resetPasswordState ? await resetPassword(inputs) : await sendEmailOnForgetPassword({ "email": inputs.email, hospitalId: inputs.hospitalId });

      // console.log(data);
      if (data.success) {
        setIsLoading(false)
        setSnackbarMessage({ msg: data.message, success: true })
        setSnackbarOpen(true)
        if (resetPasswordState) { navigate(user === 'superadmin' ? `/reset-password/${user}/success` : '/reset-password/success') }
        else { setResetPasswordState(true) }
      }
      else {
        setIsLoading(false)
        setSnackbarMessage({ msg: data.message, success: false })
        setSnackbarOpen(true)

      }
    } catch (error) {
      setIsLoading(false)
      setSnackbarMessage({ msg: error.message, success: false })
      setSnackbarOpen(true)

    }

    
  };
  const getHospitals = async () => {
    setOpen(true)
    setLoading(true)
    try {
      const { data } = await getAccountList();
      if (data.success) {
        console.log({ data });
        setAccounts(data.hospitalSummary)
      }
      else {
        // setIsLoading(false)
        setSnackbarMessage({ msg: data.message, success: false })
        setSnackbarOpen(true)
        // console.log('c');
      }
    }
    catch (error) {
      setLoading(false)
      setSnackbarMessage({ msg: error.message, success: false })
      setSnackbarOpen(true)
      console.log("err ", error);
    }
    setLoading(false)
  }
  return (
    <>

      <Box component='form' onSubmit={handleSubmit} mt={{xs:0, sm:5}} >
        <Box sx={{ backgroundColor: { xs: '#000', sm: 'inherit' }, borderRadius: 2 }} >
        {resetPasswordState ?
          <Stack spacing={2}  p={{xs:2, sm:0}} position='relative'>
            <Typography sx={{display:{xs:'block',sm:'none'}}} color='#fff'>Reset your password</Typography>
            <TextField
              name="otp"
              label="OTP"
              value={inputs.otp}
              onChange={handleChange}
              required
              sx={{ backgroundColor: '#fff', borderRadius: 2 }}
            />

            <TextField
              name="password"
              label="New Password"
              onChange={handleChange}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              required
              sx={{ backgroundColor: '#fff', borderRadius: 2 }}
            />
          </Stack>
          :
          <Stack spacing={2} p={{xs:2, sm:0}}>
            <Typography sx={{display:{xs:'block',sm:'none'}}} variant='h5' color='#fff'>Reset your password</Typography>
            <TextField
              fullWidth
              name="email"
              label="Email address"
              value={email}
              onChange={handleChange}
              required
              sx={{ backgroundColor: '#fff', borderRadius: 2 }}
            />
            {user !== 'superadmin' &&
               <Autocomplete
               disablePortal
               fullWidth
               open={open}
               onOpen={getHospitals}
               onClose={() => {
                 setOpen(false);
               }}
               loading={loading}
               options={accounts}
               getOptionLabel={(hospital) => `${hospital.hospitalName} - ${hospital.location}`}
               onChange={(event, newValue) => {
                 console.log({newValue});
                 setInputs({ ...inputs, hospitalId: newValue.hospitalId })
               }}
               renderInput={(params) => <TextField
                 {...params}
                 label="Hospitals"
                 InputProps={{
                   ...params.InputProps,
                   endAdornment: (
                     <>
                       {loading ? <CircularProgress color="inherit" size={20} /> : null}
                       {params.InputProps.endAdornment}
                     </>
                   ),
                 }}
               />}
               sx={{ backgroundColor: '#fff', borderRadius: 2 }}
             />}
          </Stack>
        }
        </Box>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Link variant="subtitle2" underline="hover" href={user === 'superadmin' ? `/login/${user}` : '/login'}>
            Back To Login
          </Link>
        </Stack>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <LoadingButton sx={{ borderRadius: 4, width: 250 }} loading={isLoading} size="large" type="submit" variant="contained">
            {resetPasswordState ? 'Change password' : 'Send OTP'}          </LoadingButton>
        </Box>
      </Box>

      {/* <Button startIcon={<ArrowBackIcon />} onClick={() => navigate('/login', { replace: true })}>
        <Typography>
          Back to log in
        </Typography>
      </Button> */}
    </>
  );
}
