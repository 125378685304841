import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import axios from 'axios';
import { useSelector } from 'react-redux';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  CircularProgress,
  Chip,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import QrCodeIcon from '@mui/icons-material/QrCode';
// dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import CustomSnackBar from '../../components/snackbar';
import MenuPopover from '../../components/popover/menuPopover';
// sections
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import QRLicenseDialog from '../../sections/dialogs/qrLicenseDialog';
import MuteDialog from '../../sections/dialogs/MuteDialog';

// mock
import USERLIST from '../../_mock/user';
// api
import { muteModules } from '../../api/superadmin/license';
// ----------------------------------------------------------------------

const LICENSE_MANAGER_TABLE_HEAD = [
  { id: 'sno', label: 'S No', alignRight: false },
  { id: 'module', label: 'Module Name', alignRight: false },
  { id: 'startdate', label: 'Start Date', alignRight: false },
  { id: 'enddate', label: 'End Date', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },

  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ModulesList() {

  const loggedUser = useSelector((state) => state.adminUser.user)
  
  const [account, setAccount] = useState(useSelector((state) => state.account.hospitalAccount))

  console.log({account});

  const [license, setLicense] = useState(null);

  // console.log(accounts);

  const [isLoading, setIsLoading] = useState(false)
  const [isExportLoading, setIsExportLoading] = useState(false)

  // snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState(null)

  const [selectedItem, setSelectedItem] = useState(null)
  const [selectedAccount, setSelectedAccount] = useState(null)

  const [dialogOpen, setDialogOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const [action, setAction] = useState('');

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);



  const handleMute = async (id,status) => {
    setIsLoading(true)
    console.log({status});
    const postData = { hospitalId: account._id, modules: [id], isMute: status === 'active' }
    try {
      const { data } = await muteModules(id, postData);
      // setLicense(data.qrCodeData)
      setAccount(data.hospitalAccount)
      console.log(data);
      setIsLoading(false)
    }
    catch (e) {
      setIsLoading(false)
      setSnackbarMessage({ msg: 'Request Failed', success: false })
      setSnackbarOpen(true)
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;


  return (
    <>
      <Helmet>
        <title> Modules | Teller One </title>
      </Helmet>

      <Box sx={{ height: '100%' }}>
        
          <Box>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                Modules
              </Typography>
              
            </Stack>


            {account.modules &&
              <Card>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={LICENSE_MANAGER_TABLE_HEAD}
                        rowCount={USERLIST.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {account.modules && account.modules.map((row, index) => {
                          const { _id, moduleId, moduleName, licenseStartDate, licenseEndDate, licenseStatus } = row;

                          return (
                            <TableRow hover key={index} tabIndex={-1} role="checkbox" >
                              {/* <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                        </TableCell> */}

                              <TableCell component="th" scope="row" >
                                <Typography variant="subtitle2" noWrap>
                                  {index + 1}
                                </Typography>
                              </TableCell>

                              <TableCell align="left">{moduleName}</TableCell>
                              <TableCell align="left">{licenseStartDate.split('T')[0]}</TableCell>
                              <TableCell align="left">{licenseEndDate.split('T')[0]}</TableCell>
                              <TableCell align="left">
                                <Label color={licenseStatus !== 'active' ? 'error' : 'success'}>{licenseStatus}</Label>
                              </TableCell>
                              
                                <TableCell align="right">
                                  <Button variant='contained' onClick={() => handleMute(moduleId, licenseStatus)}>{licenseStatus !== 'active' ? 'Unmute':'Mute'}</Button>
                                </TableCell>
                            </TableRow>
                          );
                        })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>

                      {isNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <Paper
                                sx={{
                                  textAlign: 'center',
                                }}
                              >
                                <Typography variant="h6" paragraph>
                                  Not found
                                </Typography>

                                <Typography variant="body2">
                                  No results found for &nbsp;
                                  <strong>&quot;{filterName}&quot;</strong>.
                                  <br /> Try checking for typos or using complete words.
                                </Typography>
                              </Paper>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>

                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={account.modules.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>}
          </Box>
       
      </Box>
      <CustomSnackBar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarMessage={snackbarMessage && snackbarMessage.msg} success={snackbarMessage && snackbarMessage.success} />
      </>
  );
}
