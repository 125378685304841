import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DeleteOutline } from '@mui/icons-material';
import { v4 as uuid } from 'uuid';


// api
import { updateFeedbackQuestion } from '../../api/admin/feedback';

const initialState = {
  sectionTypeId: '',
  questions: ''
};


export default function FeedbackQuestionDialog({
  dialogOpen,
  setDialogOpen,
  action,
  setSnackbarMessage,
  setSnackbarOpen,
  feedbackSection,
  feedbackQuestion,
  setFeedbackQuestion
}) {
  const theme = useTheme();

  const [inputs, setInputs] = useState(initialState);
  // console.log(inputs);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([{ id: uuid() }]);
  const [addedQuestion, setAddedQuestion] = useState([])
  const { hospitalAccount } = useSelector((state) => state.account)

  const [selectedItem, setSelectedItem] = useState(null)


  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log('submit', inputs);
    let postData = [...inputs.questions];
    if (feedbackQuestion.length) {
      // console.log([...feedbackQuestion, ...inputs.questions]);
      postData = [...feedbackQuestion, ...inputs.questions]
    }
    setIsLoading(true)
    try {
      const { data } = await updateFeedbackQuestion(hospitalAccount._id, { sectionTypeId: inputs.sectionTypeId, questions: postData })
      if (data.success) {
        // console.log({ data });
        setFeedbackQuestion(data.feedbackQuestions.questions)

        setDialogOpen(false);
        setSnackbarMessage({ msg: data.message, success: false });
        setSnackbarOpen(true);
        setSelectedItem(null)
        setIsLoading(false);

      } else {
        setIsLoading(false);
        setSnackbarMessage({ msg: data.message, success: false });
        setSnackbarOpen(true);
      }
    } catch (error) {
      setIsLoading(false);
      setSnackbarMessage({ msg: error.message, success: false });
      setSnackbarOpen(true);
    }
  };
  const handleSelect = (event) => {
    console.log(event.target.value);
    setInputs({ ...inputs, sectionTypeId: event.target.value })
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleInputChange = (e, val) => {
    setAddedQuestion(val)
  };

  const handleDeleteRow = (id) => {
    //  console.log({rows});
    //  console.log(rows.filter(a => a.id !== id))
    setRows(rows.filter(a => a.id !== id))
  };

  useEffect(() => {
    setInputs({...inputs, questions: addedQuestion.map((item) => ({question : item}))})
    // setInputs({ ...inputs, qrInput: rows });
  }, [addedQuestion])
  useEffect(() => {
    setInputs(initialState);
    setRows([])
  }, [dialogOpen])


  return (
    <Box>
      <Dialog open={dialogOpen} onClose={handleClose} maxWidth={600}>

        <form onSubmit={handleSubmit}>
          <DialogContent>

            <Stack spacing={2} >
              <Typography variant='h5'>Add Feedback Question</Typography>
            </Stack>
            <FormControl fullWidth variant="standard">
              <InputLabel id="demo-simple-select-label">Choose Section Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={inputs.sectionTypeId}
                label="Choose Account"
                onChange={handleSelect}
              >
                {feedbackSection.map((item) =>
                  <MenuItem key={item.sectionTypeId} value={item.sectionTypeId}>{item.sectionTypeName}</MenuItem>
                )}


              </Select>
            </FormControl>

            <Box my={2}>
              <Autocomplete
                multiple
                freeSolo
                id="tags-outlined"
                options={[]}
                onChange={handleInputChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Add questions"
                  />
                )}
              />

            </Box>
            <Typography variant='subtitle2' color='red'>*Enter to add questions</Typography>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant='contained'>Cancel</Button>
            <LoadingButton loading={isLoading} type='submit' variant='contained'>Submit</LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  )
}
