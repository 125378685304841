import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack, Divider } from '@mui/material';
// mock
import { CloseOutlined } from '@mui/icons-material';
import account from '../../../_mock/account';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import logoImage from '../../../assets/logo_white.svg'
import logoPureWhite from '../../../assets/logo_pure_white.svg'

import tcImage from '../../../assets/tc-icon.svg'
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import { logoutUser } from '../../../appRedux/actions/adminUser';

import { adminHeadNavConfig, liceneseManagerNavConfig, financeControllerNavConfig, accountManagerNavConfig, adminManagerNavConfig, powerUserNavConfig, userNavConfig, viewerNavConfig } from './config';

// ----------------------------------------------------------------------

const NAV_WIDTH = 240;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const navigate = useNavigate()
  const loggedUser = useSelector((state) => state.adminUser.user)
  const account = useSelector((state) => state.account.hospitalAccount)

  console.log({ account })
  const isDesktop = useResponsive('up', 'lg');
  const handleLogout = () => {
    dispatch(logoutUser())
    navigate(loggedUser.isSuperAdmin ? '/login/superadmin' : '/login')
  };
  const checkModules = (navConfig) => {
    let tempConfig = navConfig
    if (account.modules.some((item) => item.moduleName === "hospitalinformation" && item.licenseStatus === "inactive")) {
      console.log('am in,hi');
      tempConfig = tempConfig.filter((item) => item.title !== "Hospital Information")
    }
    // if (account.modules.some((item) => item.moduleName === "housekeeping" && item.licenseStatus === "inactive")) {
    //   console.log('am in,hk');
    //   tempConfig = tempConfig.filter((item) => item.title !== "Hospital Information")
    // }
    // if (account.modules.some((item) => item.moduleName === "maintenance" && item.licenseStatus === "inactive")) {
    //   console.log('am in,m');
    // }
    if (account.modules.some((item) => item.moduleName === "feedback" && item.licenseStatus === "inactive")) {
      console.log('am in,f');
      tempConfig = tempConfig.filter((item) => item.title !== "Feedbacks" && item.title !== "Feedback Questions")
    }
    if (account.modules[1].licenseStatus === "inactive" && account.modules[3].licenseStatus === "inactive") {
      console.log('am in,both');
      tempConfig = tempConfig.filter((item) => item.title !== "Dashboard" && item.title !== "Requests" && item.title !== "Services" && item.title !== "QR License" && item.title !== "Admin Users")
    }
    console.log({ tempConfig });
    return tempConfig
  }
  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent1 = (

    <Stack sx={{ display: 'flex', justifyContent: 'space-between', height: '100%' }} >
      <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => navigate('/dashboard')}>
        <img src={logoImage} alt='logo' width={140} height={40} />
        <Typography color='#fff' variant='caption' ml={1}>powered by TELLERCOMM</Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {loggedUser.role === 'adminhead' && <NavSection data={adminHeadNavConfig} />}
        {loggedUser.role === 'licensemanager' && <NavSection data={liceneseManagerNavConfig} />}
        {loggedUser.role === 'financecontroller' && <NavSection data={financeControllerNavConfig} />}
        {loggedUser.role === 'accountmanager' && <NavSection data={accountManagerNavConfig} />}
        {loggedUser.role === 'adminmanager' && <NavSection data={checkModules(adminManagerNavConfig)} />}
        {loggedUser.role === 'poweruser' && <NavSection data={checkModules(powerUserNavConfig)} />}
        {loggedUser.role === 'user' && <NavSection data={checkModules(userNavConfig)} />}
        {loggedUser.role === 'viewer' && <NavSection data={checkModules(viewerNavConfig)} />}
      </Box>


      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

        <StyledAccount>
          <Avatar src={loggedUser.role === 'adminhead' ? tcImage : account.photoURL} alt={loggedUser.firstName} sx={{ bgcolor: '#fff', color: '#7A7A7A' }} />

          <Box sx={{ ml: 2 }}>
            {loggedUser.role === 'adminhead' ?
              <Typography variant="body1" sx={{ color: '#fff' }}>
                Ultimate Admin
              </Typography>
              :
              <>
                <Typography variant="body1" sx={{ color: '#fff' }}>
                  {loggedUser.firstName} {loggedUser.lastName}
                </Typography>
                <Typography variant="subtitle2" sx={{ color: '#fff' }}>
                  {loggedUser.role}
                </Typography>
              </>
            }

          </Box>
        </StyledAccount>

      </Box>
    </Stack>

  );

  const renderContent2 = (

    <Stack sx={{ display: 'flex', justifyContent: 'space-between', height: '100%' }} >
      <Stack>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mx: 2 }}>
          <StyledAccount>
            <Avatar src={loggedUser.role === 'adminhead' ? tcImage : account.photoURL} alt={loggedUser.firstName} sx={{ bgcolor: '#fff', color: '#7A7A7A' }} />

            <Box ml={2}>
              {loggedUser.role === 'adminhead' ?
                <Typography variant="body1" sx={{ color: '#fff' }}>
                  Ultimate Admin
                </Typography>
                :
                <>
                  <Typography variant="body1" sx={{ color: '#fff' }}>
                    {loggedUser.firstName} {loggedUser.lastName}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: '#fff' }}>
                    {loggedUser.role}
                  </Typography>
                </>
              }

            </Box>
          </StyledAccount>
          <CloseOutlined sx={{ color: '#fff' }} onClick={onCloseNav} />

        </Box>
        <Divider sx={{ backgroundColor: '#fff', fontSize: 10 }} variant='middle' />
      </Stack>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {loggedUser.role === 'adminhead' && <NavSection data={adminHeadNavConfig} />}
        {loggedUser.role === 'licensemanager' && <NavSection data={liceneseManagerNavConfig} />}
        {loggedUser.role === 'financecontroller' && <NavSection data={financeControllerNavConfig} />}
        {loggedUser.role === 'accountmanager' && <NavSection data={accountManagerNavConfig} />}
        {loggedUser.role === 'adminmanager' && <NavSection data={adminManagerNavConfig} />}
        {loggedUser.role === 'poweruser' && <NavSection data={powerUserNavConfig} />}
        {loggedUser.role === 'user' && <NavSection data={userNavConfig} />}
        {loggedUser.role === 'viewer' && <NavSection data={viewerNavConfig} />}
      </Box>

      <Stack spacing={1} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Button variant='contained' sx={{ backgroundColor: 'white', color: 'black', borderRadius: 4, width: 100 }} onClick={handleLogout}>Log out</Button>
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <img src={logoPureWhite} alt='logo' width={60} />
          <Typography color={'white'} variant='subtitle2' ml={2}>Powered by TELLERCOMM</Typography>

        </Box>
        <Typography color={'white'} variant='subtitle2'>© Telleromm {new Date().getFullYear()}</Typography>
      </Stack>

    </Stack>

  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: '20%' },
        backgroundColor: '#000'
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: '20%',
              bgcolor: '#000',
            },
          }}
        >
          {renderContent1}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: '90%', bgcolor: '#000', },
          }}
        >
          {renderContent2}
        </Drawer>
      )}
    </Box>
  );
}
