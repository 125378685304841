import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Box, CircularProgress, Stack } from '@mui/material';
// components
import { useSelector } from 'react-redux';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../../sections/@dashboard/app';

import totalIcon from '../../assets/icons-new/dashboard/total.svg'
import activeLicenseIcon from '../../assets/icons-new/dashboard/active.svg'
import modifiedLicenseIcon from '../../assets/icons-new/dashboard/mod.svg'
import suspendedLicenseIcon from '../../assets/icons-new/dashboard/suspend.svg'
import licenseManagerIcon from '../../assets/icons-new/dashboard/license-manager.svg'
import accountManagerIcon from '../../assets/icons-new/dashboard/account-manager.svg'
import financeControllerIcon from '../../assets/icons-new/dashboard/finance-controller.svg'
import { getDashboardData } from '../../api/superadmin/commonAPIs';

const getColor = ['secondary', 'info', 'warning', 'error']
// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const navigate = useNavigate()
  const adminUserState = useSelector((state) => state.adminUser)
  const { isAuthenticated, user } = adminUserState
  const hospital = useSelector((state) => state.account.hospitalAccount)

  const [isLoading, setIsLoading] = useState(false)
  const [dashboardData, setDashboardData] = useState(false)
  const [chartData, setChartData] = useState(false)
  const [currentChartData, setCurrentChartData] = useState(false)

  // console.log(hospital)
  const fetchData = async () => {
    setIsLoading(true)
    try {
      const { data } = await getDashboardData();
      setDashboardData(data.dashboard)
      setChartData(data.chart)
      setCurrentChartData(data.chart.dayWise)

      setIsLoading(false)
    }
    catch (e) {
      setIsLoading(false)
      // console.log({ e });
    }
  }

  // get admins list
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard | Teller One </title>
      </Helmet>
      <Box>
        {!isLoading ?
          <Box sx={{height:'100%'}} >
            <Typography variant="h4" >
              Hi, Welcome back!
            </Typography>
            {dashboardData &&
              <Grid container spacing={3} mt={1}>
                {user.role === 'adminhead' &&
                  <>
                    <Grid item xs={12} sm={6} md={3} onClick={() => navigate('/superadmin', { state: { filter:  'all'  } })} sx={{ cursor: 'pointer' }}>
                      <Box sx={{ borderRadius: 2, boxShadow: 24, background: 'linear-gradient(135deg, rgba(218,36,43,1) 0%, rgba(149,37,128,1) 48%, rgba(46,52,126,1) 100%)', color: '#fff', width: 180, height: 220 }}>
                        <Stack p={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 2, height: '100%' }}>
                          <img src={totalIcon} alt='img' width={40} />
                          <Typography mt={3} mb={2} variant='subtitle1'>Total</Typography>
                          <Typography variant='h3'>{dashboardData.superAdmins.total}</Typography>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} onClick={() => navigate('/superadmin', { state: { filter: 'financecontroller'} })} sx={{ cursor: 'pointer' }}>
                      <Box sx={{ borderRadius: 2, boxShadow: 24, backgroundColor: '#000', color: '#fff', width: 180, height: 220 }}>
                        <Stack p={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 2, height: '100%' }}>
                          <img src={financeControllerIcon} alt='img' width={40} />
                          <Typography mt={3} mb={2} variant='subtitle1'>Finance controller</Typography>
                          <Typography variant='h3'>{dashboardData.superAdmins.financecontroller}</Typography>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} onClick={() => navigate('/superadmin', { state: { filter: 'accountmanager'} })} sx={{ cursor: 'pointer' }}>
                      <Box sx={{ borderRadius: 2, boxShadow: 24, backgroundColor: '#000', color: '#fff', width: 180, height: 220 }}>
                        <Stack p={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 2, height: '100%' }}>
                          <img src={accountManagerIcon} alt='img' width={40} />
                          <Typography mt={3} mb={2} variant='subtitle1'>Account manager</Typography>
                          <Typography variant='h3'>{dashboardData.superAdmins.accountmanager}</Typography>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} onClick={() => navigate('/superadmin', { state: { filter: 'licensemanager'} })} sx={{ cursor: 'pointer' }}>
                      <Box sx={{ borderRadius: 2, boxShadow: 24, backgroundColor: '#000', color: '#fff', width: 180, height: 220 }}>
                        <Stack p={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 2, height: '100%' }}>
                          <img src={licenseManagerIcon} alt='img' width={40} />
                          <Typography mt={3} mb={2} variant='subtitle1'>License manager</Typography>
                          <Typography variant='h3'>{dashboardData.superAdmins.licensemanager}</Typography>
                        </Stack>
                      </Box>
                    </Grid>
                  </>
                }
                {user.role !== 'adminhead' && 
                  <>
                  <Grid item xs={12} sm={6} md={3} onClick={() => navigate('/accounts', { state: { filter:  'all'  } })} sx={{ cursor: 'pointer' }}>
                    <Box sx={{ borderRadius: 2, boxShadow: 24, background: 'linear-gradient(135deg, rgba(218,36,43,1) 0%, rgba(149,37,128,1) 48%, rgba(46,52,126,1) 100%)', color: '#fff', width: 180, height: 220 }}>
                      <Stack p={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 2, height: '100%' }}>
                        <img src={totalIcon} alt='img' width={40} />
                        <Typography mt={3} mb={2} variant='subtitle1'>Total</Typography>
                        <Typography variant='h3'>{dashboardData.hospitalAccounts.total}</Typography>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} onClick={() => navigate('/accounts', { state: { filter:  'active'  } })} sx={{ cursor: 'pointer' }}>
                    <Box sx={{ borderRadius: 2, boxShadow: 24, backgroundColor: '#000', color: '#fff', width: 180, height: 220 }}>
                      <Stack p={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 2, height: '100%' }}>
                        <img src={activeLicenseIcon} alt='img' width={60} height={30}/>
                        <Typography mt={3} mb={2} variant='subtitle1'>Active Accounts</Typography>
                        <Typography variant='h3'>{dashboardData.hospitalAccounts.active}</Typography>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} onClick={() => navigate('/accounts', { state: { filter:  'inreview'  } })} sx={{ cursor: 'pointer' }}>
                    <Box sx={{ borderRadius: 2, boxShadow: 24, backgroundColor: '#000', color: '#fff', width: 180, height: 220 }}>
                      <Stack p={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 2, height: '100%' }}>
                        <img src={modifiedLicenseIcon} alt='img' width={40} />
                        <Typography mt={3} mb={2} variant='subtitle1'>Modified Accounts</Typography>
                        <Typography variant='h3'>{dashboardData.hospitalAccounts.modified}</Typography>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} onClick={() => navigate('/accounts', { state: { filter:  'suspended'  } })} sx={{ cursor: 'pointer' }}>
                    <Box sx={{ borderRadius: 2, boxShadow: 24, backgroundColor: '#000', color: '#fff', width: 180, height: 220 }}>
                      <Stack p={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 2, height: '100%' }}>
                        <img src={suspendedLicenseIcon} alt='img' width={60} height={30} />
                        <Typography mt={3} mb={2} variant='subtitle1'>Suspended Accounts</Typography>
                        <Typography variant='h3'>{dashboardData.hospitalAccounts.suspended}</Typography>
                      </Stack>
                    </Box>
                  </Grid>
                </>
                }
                {chartData && 
                <Grid item xs={12} md={6} lg={6}>
                  <AppWebsiteVisits
                    title="Total Accounts"
                    subheader="(+1.23%) than last year"
                    allChartData={chartData}
                    setCurrentChartData={setCurrentChartData}
                    chartLabels={currentChartData?.map((item) => item.date)}
                    chartData={[
                      {
                        name: 'Accounts Added',
                        type: 'column',
                        fill: 'solid',
                        data: currentChartData?.map((item) => item.cnt),
                      },

                    ]}
                  />
                </Grid>}

                <Grid item xs={12} md={6} lg={6}>
                  <AppCurrentVisits
                    title="Account Summary"
                    chartData={
                      Object.entries(dashboardData.hospitalAccounts).map(([label, value], ind) => ({ label, value })).filter((item) => item.label !== 'total')
                    }
                    chartColors={[
                      theme.palette.primary.main,
                      theme.palette.info.main,
                      theme.palette.warning.main,
                      theme.palette.error.main,
                    ]}
                  />
                </Grid>
              </Grid>}
          </Box>
          :
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 700,}}><CircularProgress /></Box>}
      </Box>
    </>
  );
}
