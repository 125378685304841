import { createApiConfig } from "./config";
import { LOGIN_URL , SEND_EMAIL_URL, RESET_PASSWORD_URL, GET_ACCOUNT_ITEM_URL} from "./routes";


const apiConfig = createApiConfig();



/**
 * @desc  Login User API Service
 * @param  {string} email
 * @param  {string} password
 */
export const loginUserWithEmailAndPassword = (inputs) => {
  return apiConfig.post(LOGIN_URL, inputs);
};

export const sendEmailOnForgetPassword = (inputs) => apiConfig.post(SEND_EMAIL_URL, inputs);

export const resetPassword = (inputs) => apiConfig.post(RESET_PASSWORD_URL, inputs);


export const getAccountItem = (id) => {
  return apiConfig.get(`${GET_ACCOUNT_ITEM_URL}/${id}`);
};