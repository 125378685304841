import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Box, CircularProgress, Button, Stack, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField } from '@mui/material';
// components
import StarIcon from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';


import DeleteIcon from '../../assets/icons-new/ic-delete.svg';
import EditIcon from '../../assets/icons-new/ic-edit.svg';


// sections
import { getFeedbackSection, getFeedbackQuestion, updateFeedbackSection, updateFeedbackQuestion } from '../../api/admin/feedback';
import CustomSnackBar from '../../components/snackbar';
import FeedbackSectionDialog from '../../sections/dialogs/feedbackSectionDialog';
import FeedbackQuestionDialog from '../../sections/dialogs/feedbackQuestionDialog';

const getColor = ['secondary', 'info', 'warning', 'error']
// ----------------------------------------------------------------------

export default function FeedbackSection() {
  const theme = useTheme();
  const { hospitalAccount } = useSelector((state) => state.account)

  const [isLoading, setIsLoading] = useState(false)

  const [feedbackSection, setFeedbackSection] = useState(false)
  const [feedbackQuestion, setFeedbackQuestion] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState(null)
  const [action, setAction] = useState('');
  const [dialogOpen1, setDialogOpen1] = useState(false);
  const [dialogOpen2, setDialogOpen2] = useState(false);
  const [editState, setEditState] = useState(false)
  const [tempValue, setTempValue] = useState(false)
  const [selectedId, setSelectedId] = useState(false)  // Dialog

  const handleDialogOpen1 = () => {

    setAction('CREATE')
    setDialogOpen1(true);
  };

  // Dialog
  const handleDialogOpen2 = () => {
    if (feedbackSection.length === 0) {
      setSnackbarMessage({ msg: 'Add feedback section', success: false });
      setSnackbarOpen(true);
    }
    else {
      setAction('CREATE')
      setDialogOpen2(true);
    }

  };

  const handleEdit = (id, title) => {
    console.log('am here', id);
    setTempValue(title)
    setEditState(true);
    setSelectedId(id)
  };

  const handleEditSubmit = async (id) => {
    console.log('am edit here', id);
    let postData = [];
    if (feedbackSection.length) {
      postData = feedbackSection.map((item) => { if (item.sectionTypeId === id) { return { ...item, sectionTypeName: tempValue } } return item })
    }
    try {
      const { data } = await updateFeedbackSection(hospitalAccount._id, { sectionTypes: postData })
      if (data.success) {

        setFeedbackSection(data.feedbackSections.sectionTypes)

        setEditState(false)
        setSnackbarMessage({ msg: data.message, success: false });
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage({ msg: data.message, success: false });
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage({ msg: error.message, success: false });
      setSnackbarOpen(true);
    }
  };

  const handleDelete = async (id) => {
    let postData = [];
    if (feedbackSection.length) {
      postData = feedbackSection.filter((item) => item.sectionTypeId !== id)
    }

    try {
      const { data } = await updateFeedbackSection(hospitalAccount._id, { sectionTypes: postData })
      if (data.success) {
        setFeedbackSection(data.feedbackSections.sectionTypes)
        setSnackbarMessage({ msg: data.message, success: false });
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage({ msg: data.message, success: false });
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage({ msg: error.message, success: false });
      setSnackbarOpen(true);
    }
  };

  const handleQuestionDelete = async (id, qid) => {
    let postData = [];
    if (feedbackQuestion.length) {
      postData = feedbackQuestion.filter((item) => item.questionId !== qid)
    }

    try {
      const { data } = await updateFeedbackQuestion(hospitalAccount._id, { sectionTypeId: id, questions: postData })
      if (data.success) {
        setFeedbackQuestion(data.feedbackQuestions.questions)
        setSnackbarMessage({ msg: data.message, success: false });
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage({ msg: data.message, success: false });
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage({ msg: error.message, success: false });
      setSnackbarOpen(true);
    }
  };

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const res1 = await getFeedbackSection(hospitalAccount._id);
      const res2 = await getFeedbackQuestion(hospitalAccount._id);
      if (res1.data.success) {
        setFeedbackSection(res1.data.feedbackSections.sectionTypes)
      }

      if (res2.data.success) {
        setFeedbackQuestion(res2.data.feedbackQuestions.questions)
      }

      console.log('data1', res1);
      console.log('data2', res2);
      setIsLoading(false)
    }
    catch (e) {
      setIsLoading(false)
      // console.log({ e });
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title> Feedback | Teller One </title>
      </Helmet>
      <Box sx={{ height: '100%' }}>
        {!isLoading ?
          <>
            <Typography variant="h5" gutterBottom>
              Feedback Sections
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
              <Typography variant="h6" >
                Services
              </Typography>
              <Box>
                <Button variant="contained" sx={{ borderRadius: 4, mr: 2 }} onClick={handleDialogOpen1}>
                  Add feedback section
                </Button>
              </Box>
            </Stack>


            <List
              sx={{ width: '100%', bgcolor: '#D9D9D9', borderRadius: 2 }}
              aria-label="contacts"
            >
              {feedbackSection ? feedbackSection.map((item) =>
                <ListItem disablePadding key={item.sectionTypeId} sx={{ bgcolor: '#FFF', ml: 1, mt: 1,  borderRadius: 2,width: '98%', }} >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', p: 1 }} >
                    <Box >
                      {(editState && selectedId === item.sectionTypeId) ? <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                        <TextField value={tempValue} onChange={(e) => setTempValue(e.target.value)} />
                        <Button sx={{ ml: 2 }} onClick={() => handleEditSubmit(item.sectionTypeId)} variant='contained'>Submit</Button>
                      </Box>
                        :
                        <ListItemText primary={item.sectionTypeName} />}
                    </Box>
                    <Box display='flex'>
                      <ListItemIcon sx={{ cursor: 'pointer' }} onClick={() => handleEdit(item.sectionTypeId, item.sectionTypeName, 'service')}>
                        <img src={EditIcon} alt='ico' width={16} />
                      </ListItemIcon>
                      <ListItemIcon sx={{ cursor: 'pointer' }} onClick={() => handleDelete(item.sectionTypeId)}>
                        <img src={DeleteIcon} alt='ico' width={16} />
                      </ListItemIcon>
                    </Box>


                  </Box>

                </ListItem>
              ) : <Typography>No Item</Typography>}

            </List>
            <Stack direction="row" alignItems="center" justifyContent="space-between" my={2}>
              <Typography variant="h6" >
                Feedback Questions
              </Typography>
              <Box>

                <Button variant="contained" sx={{ borderRadius: 4, mr: 2 }} onClick={handleDialogOpen2}>
                  Add feedback question
                </Button>
              </Box>
            </Stack>

            <List
              sx={{ width: '100%', bgcolor: '#D9D9D9', borderRadius: 2 }}
              aria-label="contacts"
            >
              {feedbackQuestion ? feedbackQuestion.map((item) =>
                <ListItem disablePadding key={item.questionId} sx={{ bgcolor: '#FFF', ml: 1, mt: 1,  borderRadius: 2 , width: '98%',}} >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', p: 1 }} >

                    <Box >

                      {false ? <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                        <TextField value={tempValue} onChange={(e) => setTempValue(e.target.value)} />
                        <Button onClick={() => handleEditSubmit(item.sectionTypeId)} variant='contained' sx={{ ml: 2 }}>Submit</Button>
                      </Box>
                        :
                        <ListItemText primary={item.question} />}
                    </Box>
                    <Box display='flex'>
                      {/* <ListItemIcon sx={{ cursor: 'pointer' }} onClick={() => handleEdit(item.sectionTypeId, item.sectionTypeName, 'question')}>
                        <img src={EditIcon} alt='ico' width={16} />
                      </ListItemIcon> */}
                      <ListItemIcon sx={{ cursor: 'pointer' }} onClick={() => handleQuestionDelete(item.sectionTypeId, item.questionId)}>
                        <img src={DeleteIcon} alt='ico' width={16} />
                      </ListItemIcon>
                    </Box>
                  </Box>
                </ListItem>

              ) : <Typography>No Item</Typography>}
            </List>
          </>
          :
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}><CircularProgress /></Box>}
      </Box>

      <FeedbackSectionDialog dialogOpen={dialogOpen1} setDialogOpen={setDialogOpen1} feedbackSection={feedbackSection} setFeedbackSection={setFeedbackSection} setSnackbarMessage={setSnackbarMessage} setSnackbarOpen={setSnackbarOpen} action={action} />
      {feedbackSection && <FeedbackQuestionDialog dialogOpen={dialogOpen2} setDialogOpen={setDialogOpen2} feedbackSection={feedbackSection} feedbackQuestion={feedbackQuestion} setFeedbackQuestion={setFeedbackQuestion} setSnackbarMessage={setSnackbarMessage} setSnackbarOpen={setSnackbarOpen} action={action} />}
      <CustomSnackBar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarMessage={snackbarMessage && snackbarMessage.msg} success={snackbarMessage && snackbarMessage.success} />

    </>
  );
}
