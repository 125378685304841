import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import axios from 'axios';
import { useSelector } from 'react-redux';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  CircularProgress,
  Chip,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import QrCodeIcon from '@mui/icons-material/QrCode';
// dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import CustomSnackBar from '../../components/snackbar';
import MenuPopover from '../../components/popover/menuPopover';
// sections
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import QRLicenseDialog from '../../sections/dialogs/qrLicenseDialog';

// mock
import USERLIST from '../../_mock/user';
// api
import { getAdminLicense } from '../../api/superadmin/license';
import { suspendAdminManager } from '../../api/superadmin/adminmanager';

// ----------------------------------------------------------------------

const LICENSE_MANAGER_TABLE_HEAD = [
  { id: 'sno', label: 'S No', alignRight: false },
  { id: 'firstname', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
 
  { id: 'status', label: 'Status', alignRight: false },

  { id: '' },
];

const tempRow = { _id: 'qwer34r34r34r3r4', hospitalInformation: true, housekeeping: true, maintenance: true, feedback: false, status: 'true' }
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function AdminLicense() {

  const loggedUser = useSelector((state) => state.adminUser)


  const [accounts, setAccounts] = useState(loggedUser.commonData.hospitals);

  const [license, setLicense] = useState(null);

  // console.log(accounts);

  const [isLoading, setIsLoading] = useState(false)

  // snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState(null)

  const [selectedItem, setSelectedItem] = useState('')
  const [selectedAccount, setSelectedAccount] = useState(null)


  const [dialogOpen, setDialogOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const [action, setAction] = useState('');

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);




  const handleSelect = async (event) => {
    // console.log(event.target.value);
    setSelectedItem(accounts.find((item) => item.hospitalId === event.target.value));
    setSelectedAccount(event.target.value);
    setIsLoading(true)
    try {
      const { data } = await getAdminLicense(event.target.value);
      setLicense(data)
      // console.log(data.hospitalUsers);
      setIsLoading(false)
    }
    catch (e) {
      setIsLoading(false)
      // console.log({ e });
    }
  };

  const handleOpenMenu = (event, item) => {
    // console.log(item);
    setSelectedItem(item)
    setMenuOpen(event.currentTarget);
  };

  // Dialog
  const handleDialogOpen = (item, str) => {
    if (item) {
      // console.log(item);
      setSelectedItem(item)
    }
    setAction(str)
    setDialogOpen(true);
  };

  // const handleSuspend = async (id) => {
  //   // console.log(id);
  //   // setIsButtonLoading(true)
  //   try {
  //     const { data } = await suspendAdminManager(id);
  //     setMenuOpen(null)
  //     setAdminManager(data.hospitalAdmins)
  //     // setIsButtonLoading(false)
  //     setSnackbarMessage({ msg: data.message, success: true });
  //     setSnackbarOpen(true);
  //   }
  //   catch (e) {
  //     // setIsButtonLoading(false)
  //     setSnackbarMessage({ msg: e.message, success: false });
  //     setSnackbarOpen(true);
  //   }
  // }


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;


  return (
    <>
      <Helmet>
        <title> Admin License | Teller One </title>
      </Helmet>

      <Box sx={{ height: '100%' }}>
        {!isLoading ? (
          <Box>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                Admin License
              </Typography>
              {/* {loggedUser.role !== 'adminhead' &&
                <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleDialogOpen}>
                  Create Admin License
                </Button>} */}
            </Stack>

            <Box mb={2}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Choose Account</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedAccount}
                  label="Choose Account"
                  onChange={handleSelect}
                >
                  {accounts.map((item) =>
                    item.status === 'active' && <MenuItem key={item.hospitalId} value={item.hospitalId}>{item.hospitalName}</MenuItem>
                  )}


                </Select>
              </FormControl>
            </Box>
            {license &&
              <Card>
                {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}
                <Box display='flex' justifyContent='space-between' p={1}>
                  <Typography>Total License : {license.adminLicense.totalAdminLicense || 0}</Typography>
                  <Typography>Available License : {license.adminLicense.remainingAdminLicense || 0}</Typography>
                </Box>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={LICENSE_MANAGER_TABLE_HEAD}
                        rowCount={USERLIST.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {license && license.adminList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                          const { firstName, email, role, status } = row;
                          return (
                            <TableRow hover key={index} tabIndex={-1} role="checkbox" >
                              {/* <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                        </TableCell> */}

                              <TableCell component="th" scope="row" >
                                <Typography variant="subtitle2" noWrap>
                                  {index + 1}
                                </Typography>
                              </TableCell>

                              <TableCell align="left"><Typography>{firstName}</Typography></TableCell>
                              <TableCell align="left"><Typography>{email}</Typography></TableCell>
                              <TableCell align="left"><Typography>{role}</Typography></TableCell>
                              <TableCell align="left">
                                <Label color={status !== 'active' ? 'error' : 'success'}>{status}</Label>
                              </TableCell>
                              {/* {loggedUser.role !== 'adminhead' &&
                              <TableCell align="right">
                                <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                                  <Iconify icon={'eva:more-vertical-fill'} />
                                </IconButton>
                              </TableCell>} */}
                            </TableRow>
                          );
                        })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>

                      {isNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <Paper
                                sx={{
                                  textAlign: 'center',
                                }}
                              >
                                <Typography variant="h6" paragraph>
                                  Not found
                                </Typography>

                                <Typography variant="body2">
                                  No results found for &nbsp;
                                  <strong>&quot;{filterName}&quot;</strong>.
                                  <br /> Try checking for typos or using complete words.
                                </Typography>
                              </Paper>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>

                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={license?.adminList?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>}
          </Box>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}><CircularProgress /></Box>
        )
        }
      </Box>
      <CustomSnackBar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarMessage={snackbarMessage && snackbarMessage.msg} success={snackbarMessage && snackbarMessage.success} />
      {/* <MenuPopover menuOpen={menuOpen} setMenuOpen={setMenuOpen} handleDialogOpen={handleDialogOpen} handleSuspend={handleSuspend} selectedItem={selectedItem} /> */}
    </>
  );
}
