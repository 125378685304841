import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Box, CircularProgress, Stack, Button, TextField, List, ListItem, ListItemText, ListItemIcon, IconButton, Dialog, DialogActions, DialogTitle } from '@mui/material';
// components
import { useSelector } from 'react-redux';
// sections
import { deleteAmenity, deleteDoctor, deleteSpeciality, deleteWhatsNew, getHospitalInformation } from '../../api/admin/hospitalInformation';
import HospitalInformationDialog from '../../sections/dialogs/hospitalInformationDialog';
import CustomSnackBar from '../../components/snackbar';
import sampleLogo from '../../assets/sample-logo.avif'
import DeleteIcon from '../../assets/icons-new/ic-delete.svg';
import EditIcon from '../../assets/icons-new/ic-edit.svg';
import SpecialitiesDialog from '../../sections/dialogs/specialitiesDialog';
import DoctorDialog from '../../sections/dialogs/doctorDialog';
import AmenityDialog from '../../sections/dialogs/amenityDialog';
import WhatsNewDialog from '../../sections/dialogs/whatsNewDialog';

const getColor = ['secondary', 'info', 'warning', 'error']
// ----------------------------------------------------------------------

export default function HospitalInformation() {
  const theme = useTheme();
  const account = useSelector((state) => state.account)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedType, setSelectedType] = useState(false);

  const [isLoading, setIsLoading] = useState(false)
  const [hospitalInformation, setHospitalInformation] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState(null)
  const [action, setAction] = useState('');
  const [selectedItem, setSelectedItem] = useState(null)
  // hospital information
  const [dialogOpen, setDialogOpen] = useState(false);
  // specialty
  const [dialogOpen1, setDialogOpen1] = useState(false);
  // doctor
  const [dialogOpen2, setDialogOpen2] = useState(false);
  // amenity
  const [dialogOpen3, setDialogOpen3] = useState(false);
  // whats new
  const [dialogOpen4, setDialogOpen4] = useState(false);

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const { data } = await getHospitalInformation(account.hospitalAccount._id);
      setHospitalInformation(data.hospitalInformation)
      console.log(data);
      setIsLoading(false)
    }
    catch (e) {
      setIsLoading(false)
      // console.log({ e });
    }
  }

  // Dialog
  const handleDialogOpen = (action, item) => {
    setAction(action)
    setSelectedItem(item)
    setDialogOpen(true);
  };
  const handleDialogOpen1 = (action, item) => {
    setAction(action)
    setSelectedItem(item)
    setDialogOpen1(true);
  };
  const handleDialogOpen2 = (action, item) => {
    setAction(action)
    setSelectedItem(item)
    setDialogOpen2(true);
  };
  const handleDialogOpen3 = (action, item) => {
    setDialogOpen3(true);
  };
  const handleDialogOpen4 = (action, item) => {
    setDialogOpen4(true);
  };

  // Delete
  
  const handleConfirmDelete = async () => {
    try {
      let response;
      switch (selectedType) {
        case 'specialty':
          response = await deleteSpeciality(account.hospitalAccount._id, selectedItem._id)
          break;
          case 'doctor':
          response = await deleteDoctor(account.hospitalAccount._id, selectedItem._id)
          break;
          case 'amenity':
          response = await deleteAmenity(account.hospitalAccount._id, selectedItem._id)
          break;
          case 'whatsnew':
          response = await deleteWhatsNew(account.hospitalAccount._id, selectedItem._id)
          break;
        default:
          console.log("No matched type");
      }
      setHospitalInformation(response.data.hospitalInformation)
      console.log(response.data);
      setSnackbarMessage({ msg: response.data.message, success: true })
      setSnackbarOpen(true)
      setIsLoading(false)
      setOpenDeleteDialog(false);
    }
    catch (e) {
      setIsLoading(false)
      setSnackbarMessage({ msg: e.message, success: false })
      setSnackbarOpen(true)
    }
  };

  const handleDelete = async (item, type) => {
    setOpenDeleteDialog(true)
    setSelectedItem(item)
    setSelectedType(type)
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title> Service | Teller One </title>
      </Helmet>
      <Box sx={{ height: '100%' }}>
        {!isLoading ?

          <>
            <Stack direction="row" alignItems="center" justifyContent="space-between" >
              <Typography variant="h4" gutterBottom>
                Hospital Information
              </Typography>
              <Button variant="contained" onClick={() => handleDialogOpen(hospitalInformation?.aboutUs ? "EDIT" : "CREATE")} sx={{ borderRadius: 4, mr: 2 }}>
                {hospitalInformation?.aboutUs ? 'Update' : 'Add'} Hospital Information
              </Button>
            </Stack>

            <Stack spacing={2} mt={3}>
              {hospitalInformation && <>
                <Typography> {hospitalInformation ? hospitalInformation.aboutUs : ''}</Typography>
                <Typography>Location : {hospitalInformation ? hospitalInformation.location : ''}</Typography>

                <Box sx={{ display: 'flex' }}>
                  <Typography color='#407BFF' width={180}>Website </Typography>
                  <Typography color='#407BFF'>Logo </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography width={180}>{hospitalInformation ? hospitalInformation.linkToWebsite : ''} </Typography>
                  <img src={hospitalInformation.Hospitallogo} alt='logo' width={60} />
                </Box>
              </>}
              {/* Specialities */}
              <Stack direction="row" alignItems="center" justifyContent="space-between" >
                <Typography variant="h4" gutterBottom>
                  Specialities
                </Typography>
                <Button variant="contained" onClick={() => handleDialogOpen1('CREATE')} sx={{ borderRadius: 4, mr: 2 }}>
                  Add specialities
                </Button>
              </Stack>
              <List
                sx={{ width: '100%', bgcolor: '#D9D9D9', borderRadius: 2 }}
                aria-label="contacts"
              >
                <Grid container spacing={2} px={1}>
                  {hospitalInformation?.specialties ? hospitalInformation.specialties.map((item) =>
                    <Grid item lg={6} key={item.sectionTypeId} >
                      <ListItem disablePadding sx={{ bgcolor: '#FFF', borderRadius: 2 }} >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', p: 1 }} >
                          <Box >
                            <ListItemText primary={item.specialty_name} />
                          </Box>
                          <Box display='flex'>
                            <ListItemIcon sx={{ cursor: 'pointer' }} onClick={() => handleDialogOpen1("EDIT", item)} >
                              <img src={EditIcon} alt='ico' width={16} />
                            </ListItemIcon>
                            <ListItemIcon sx={{ cursor: 'pointer' }} onClick={() => handleDelete(item, "specialty")} >
                              <img src={DeleteIcon} alt='ico' width={16} />
                            </ListItemIcon>
                          </Box>
                        </Box>
                      </ListItem>
                    </Grid>)
                    : <></>}
                </Grid>
              </List>

              {/* Amenities */}
              <Stack direction="row" alignItems="center" justifyContent="space-between" >
                <Typography variant="h4" gutterBottom>
                  Amenities
                </Typography>
                <Button variant="contained" onClick={handleDialogOpen3} sx={{ borderRadius: 4, mr: 2 }}>
                  Add amenities
                </Button>
              </Stack>
              <Box sx={{ width: '100%', bgcolor: '#D9D9D9', borderRadius: 2 }}>
                <Stack direction='row' spacing={2} sx={{ display: 'flex', p: 1 }}>
                  {hospitalInformation?.Amenities && hospitalInformation.Amenities.map((item, ind) =>
                    <Stack spacing={2} sx={{ display: 'flex', alignItems: 'center' }} key={ind}>
                      <img src={item.imageUrl} alt='img' width={180} height={120} style={{ borderRadius: '10%' }} />
                      <IconButton onClick={() => handleDelete(item, "amenity")}><img src={DeleteIcon} alt='ico' width={20} /></IconButton>
                    </Stack>
                  )}
                </Stack>
              </Box>
              {/* Doctors */}
              <Stack direction="row" alignItems="center" justifyContent="space-between" >
                <Typography variant="h4" gutterBottom>
                  Doctors
                </Typography>
                <Button variant="contained" onClick={() => handleDialogOpen2('CREATE')} sx={{ borderRadius: 4, mr: 2 }}>
                  Add doctor
                </Button>
              </Stack>

              <List
                sx={{ width: '100%', bgcolor: '#D9D9D9', borderRadius: 2 }}
                aria-label="contacts"
              >
                <Grid container spacing={2} px={1}>
                  <Grid item lg={5}>
                    <Typography align='center' variant='h5'>Name</Typography>
                  </Grid>
                  <Grid item lg={5}>
                    <Typography align='center' variant='h5'>Speciality</Typography>
                  </Grid>
                  {hospitalInformation?.doctors ? hospitalInformation.doctors.map((item) =>
                    <Grid item lg={12} key={item._id} >
                      <ListItem disablePadding sx={{ bgcolor: '#FFF', borderRadius: 2 }} >
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', p: 1 }} >

                          <Grid item lg={5}>
                            <ListItemText align='center' primary={item.doctor_name} />
                          </Grid>
                          <Grid item lg={5}>
                            <ListItemText align='center' primary={item.specialty_name} />
                          </Grid>
                          <Grid item lg={2} display='flex'>
                            <ListItemIcon sx={{ cursor: 'pointer' }} onClick={() => handleDialogOpen2("EDIT", item)} >
                              <img src={EditIcon} alt='ico' width={16} />
                            </ListItemIcon>
                            <ListItemIcon sx={{ cursor: 'pointer' }} onClick={() => handleDelete(item, "doctor")}>
                              <img src={DeleteIcon} alt='ico' width={16} />
                            </ListItemIcon>
                          </Grid>
                        </Grid>
                      </ListItem>
                    </Grid>)
                    : <></>}
                </Grid>
              </List>

              {/* Whats new */}
              <Stack direction="row" alignItems="center" justifyContent="space-between" >
                <Typography variant="h4" gutterBottom>
                  Whats new
                </Typography>
                <Button variant="contained" onClick={handleDialogOpen4} sx={{ borderRadius: 4, mr: 2 }}>
                  Add New updates
                </Button>
              </Stack>
              <Box sx={{ width: '100%', bgcolor: '#D9D9D9', borderRadius: 2 }}>
                <Stack direction='row' spacing={2} sx={{ display: 'flex', p: 2 }}>
                  {hospitalInformation?.WhatsNew && hospitalInformation.WhatsNew.map((item, ind) =>
                    <Stack spacing={2} sx={{ display: 'flex', alignItems: 'center' }} key={ind}>
                      <img src={item.imageUrl} alt='img' width={180} height={120} style={{ borderRadius: '10%' }} />
                      <IconButton onClick={() => handleDelete(item, "whatsnew")}><img src={DeleteIcon} alt='ico' width={20} /></IconButton>
                    </Stack>
                  )}
                </Stack>
              </Box>
            </Stack>
          </>
          :
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}><CircularProgress /></Box>}
      </Box>
      {dialogOpen && <HospitalInformationDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} setSnackbarMessage={setSnackbarMessage} setSnackbarOpen={setSnackbarOpen} setHospitalInformation={setHospitalInformation} action={action} hospitalInformation={hospitalInformation} />}
      {dialogOpen1 && <SpecialitiesDialog dialogOpen={dialogOpen1} setDialogOpen={setDialogOpen1} setSnackbarMessage={setSnackbarMessage} setSnackbarOpen={setSnackbarOpen} setHospitalInformation={setHospitalInformation} action={action} hospitalInformation={hospitalInformation} selectedItem={selectedItem} />}
      {dialogOpen2 && <DoctorDialog dialogOpen={dialogOpen2} setDialogOpen={setDialogOpen2} setSnackbarMessage={setSnackbarMessage} setSnackbarOpen={setSnackbarOpen} setHospitalInformation={setHospitalInformation} action={action} hospitalInformation={hospitalInformation} selectedItem={selectedItem} />}
      {dialogOpen3 && <AmenityDialog dialogOpen={dialogOpen3} setDialogOpen={setDialogOpen3} setSnackbarMessage={setSnackbarMessage} setSnackbarOpen={setSnackbarOpen} setHospitalInformation={setHospitalInformation} action={action} hospitalInformation={hospitalInformation} selectedItem={selectedItem} />}
      {dialogOpen4 && <WhatsNewDialog dialogOpen={dialogOpen4} setDialogOpen={setDialogOpen4} setSnackbarMessage={setSnackbarMessage} setSnackbarOpen={setSnackbarOpen} setHospitalInformation={setHospitalInformation} action={action} hospitalInformation={hospitalInformation} selectedItem={selectedItem} />}
      <CustomSnackBar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarMessage={snackbarMessage && snackbarMessage.msg} success={snackbarMessage && snackbarMessage.success} />
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete..?
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} variant='contained' >Cancel</Button>
          <Button onClick={handleConfirmDelete} color='error' variant='contained' autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
