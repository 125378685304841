import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux'

// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, FormControlLabel, Box, Typography, Autocomplete, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { setAdminUserStateAction } from "../../../appRedux/actions/adminUser";
import Iconify from '../../../components/iconify';
import { setUserTokenInLocal, setUserEssentials } from '../../../utils/storage'
// api
import { loginUserWithEmailAndPassword } from '../../../api/auth'
import { getAccountList, getCommonData } from '../../../api/superadmin/commonAPIs';
import { getAccount } from '../../../api/superadmin/account'
import { setAccountStateAction } from '../../../appRedux/actions/account';
// ----------------------------------------------------------------------
const HOSPITAL_LIST = [
  { id: 1, name: 'ABC Hospital', location: 'Chennai' },
  { id: 2, name: 'Nalam Hospital', location: 'Chennai' },
  { id: 3, name: 'KMC Hospital', location: 'Chennai' },
]
export default function LoginForm({ setLoginState, setSnackbarMessage, setSnackbarOpen }) {
  const { user } = useParams()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [showPassword, setShowPassword] = useState(false);
  const [inputs, setInputs] = useState({ hospitalId: '' })
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false);

  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value })
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(accounts.find((item) => item.hospitalId === inputs.hospitalId));
    if (accounts.find((item) => item.hospitalId === inputs.hospitalId)?.status === 'inactive') {
      console.log('in if');
      navigate('/license-expiry')
    }
    else {
      setIsLoading(true)
      try {
        const { data } = await loginUserWithEmailAndPassword(inputs);
        if (data.success) {
          setIsLoading(false)
          const result = await getCommonData(data.data.token);
          const result1 = await getAccount(inputs.hospitalId);

          const userStateData = {
            "firstName": data.data.firstName,
            "lastName": data.data.lastName,
            "email": data.data.email,
            "role": data.data.role,
            "isSuperAdmin": data.data.isSuperAdmin
          }
          setUserEssentials(userStateData);
          setUserTokenInLocal(data.data.token);
          dispatch(setAdminUserStateAction({
            user: userStateData,
            token: localStorage.getItem("token"),
            isAuthenticated: true,
            commonData: result.data.commondata,
          }))
          if (user === 'superadmin') { navigate('/dashboard', { replace: true }) }
          else {
            dispatch(setAccountStateAction({
              hospitalAccount: result1.data.hospitalAccount
            }))
            if (result1.data.hospitalAccount.modules[1].licenseStatus === "active" && result1.data.hospitalAccount.modules[3].licenseStatus === "active") {
             
              navigate('/dashboard', { replace: true })
            }
            else if (result1.data.hospitalAccount.modules[0].licenseStatus === "inactive") {
              navigate('/feedback-response', { replace: true })

            }
            else {
              navigate('/qrlicense', { replace: true })
            }
          }
        }
        else {
          setIsLoading(false)
          setSnackbarMessage({ msg: data.message, success: false })
          setSnackbarOpen(true)
          // console.log('c');
        }
      }
      catch (error) {
        setIsLoading(false)
        setSnackbarMessage({ msg: error.message, success: false })
        setSnackbarOpen(true)
        console.log("err ", error);
      }
    }

  };

  const getHospitals = async () => {
    setOpen(true)
    setLoading(true)
    try {
      const { data } = await getAccountList();
      if (data.success) {
        console.log({ data });
        setAccounts(data.hospitalSummary)
      }
      else {
        // setIsLoading(false)
        setSnackbarMessage({ msg: data.message, success: false })
        setSnackbarOpen(true)
        // console.log('c');
      }
    }
    catch (error) {
      setLoading(false)
      setSnackbarMessage({ msg: error.message, success: false })
      setSnackbarOpen(true)
      console.log("err ", error);
    }
    setLoading(false)
  }


  return (
    <Stack component='form' onSubmit={handleSubmit} mt={3} spacing={2}>
      <Box sx={{ backgroundColor: { xs: '#000', sm: 'inherit' }, borderRadius: 2 }}>
        <Stack spacing={2} sx={{ p: { xs: 2, sm: 0 } }}>
          <Typography variant="h3" gutterBottom sx={{ display: { xs: "none", sm: "block" }, }}>
            Sign in
          </Typography>
          <Typography variant="h3" color={{ xs: '#fff', sm: '#000' }} gutterBottom sx={{ display: { xs: "block", sm: "none" }, }}>
            Log in
          </Typography>

          <TextField
            required
            name="email"
            label="Email"
            onChange={handleChange}
            sx={{ backgroundColor: { xs: '#fff' }, borderRadius: 2, }}

          />
          <TextField
            required
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            onChange={handleChange}
            InputProps={{
              
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ backgroundColor: '#fff', borderRadius: 2}}
          />
          {user !== 'superadmin' &&
            <Autocomplete
              disablePortal
              fullWidth
              open={open}
              onOpen={getHospitals}
              onClose={() => {
                setOpen(false);
              }}
              loading={loading}
              options={accounts}
              getOptionLabel={(hospital) => `${hospital.hospitalName} - ${hospital.location}`}
              onChange={(event, newValue) => {
                console.log({ newValue });
                setInputs({ ...inputs, hospitalId: newValue.hospitalId })
              }}
              renderInput={(params) => <TextField
                {...params}
                label="Hospitals"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />}
              sx={{ backgroundColor: '#fff', borderRadius: 2 }}
            />
          }
        </Stack>
      </Box>
      <Link variant="subtitle2" underline="hover" href={user === 'superadmin' ? `/forget-password/${user}` : '/forget-password'} sx={{ cursor: 'pointer' }}>
        Forgot password?
      </Link>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <LoadingButton sx={{ borderRadius: 4, width: 250 }} loading={isLoading} size="large" type="submit" variant="contained">
          Login
        </LoadingButton>
      </Box>
    </Stack>
  );
}
