import { useEffect, useState } from 'react';
import axios from 'axios'
import { useSelector } from 'react-redux';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';

// login
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';

// super admin
import DashboardApp from './pages/superadmin/DashboardAppPage';
import Accounts from './pages/superadmin/Accounts';
import Superadmin from './pages/superadmin/SuperadminList';
import Adminmanager from './pages/superadmin/AdminmanagerList'
import AdminLicense from './pages/superadmin/AdminLicense';
import QRLicense from './pages/superadmin/QRLicense';

// admin
import AdminUser from './pages/admin/AdminUserList';
import AdminDashboard from './pages/admin/AdminDashboard';
import QRLicenseAdmin from './pages/admin/QRLicense';
import ModulesList from './pages/admin/ModulesList';
import Feedback from './pages/admin/Feedback';
import AccountManager from './pages/AccountManager';
import FeedbackSection from './pages/admin/FeedbackSection';
import HospitalInformation from './pages/admin/HospitalInformation';
import ServiceSection from './pages/admin/ServiceSection';
import Services from './pages/admin/Services';
import RequestList from './pages/admin/RequestList';
import RequestDashboard from './pages/admin/RequestDashboard';
import ResetPassword from './pages/ResetPassword';
import ResetSuccess from './pages/ResetSuccess';
import LicenseExpiry from './pages/LicenseExpiry';


// ----------------------------------------------------------------------
const RoutesProvider = () => {
  const path = window.location.pathname.split('/')[2]

  if (path === "superadmin") {
    localStorage.setItem('user', path)
  }

 const { isAuthenticated, user } = useSelector((state) => state.adminUser)
  //  console.log(isAuthenticated, user)
  const routes = useRoutes([
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to={localStorage.getItem('user') === "superadmin" ? "/login" : `/login`} />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: 'login/:user',
      element: <LoginPage />,
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'forget-password/:user',
      element: <ResetPassword />,
    },

    {
      path: 'forget-password',
      element: <ResetPassword />,
    },
    {
      path: 'reset-password/:user/success',
      element: <ResetSuccess />,
    },
    {
      path: 'license-expiry',
      element: <LicenseExpiry />,
    },
    {
      path: 'reset-password/success',
      element: <ResetSuccess />,
    },
    {
      path: '/',
      element: isAuthenticated ? <DashboardLayout /> : <Navigate to={localStorage.getItem('user') === "superadmin" ? "/login" : `/login`} />,
      children: [
        { element: <Navigate to={`/dashboard`} />, index: true },
        { path: 'dashboard', element: user?.isSuperAdmin ? <DashboardApp /> : <AdminDashboard /> },
        { path: 'superadmin', element: <Superadmin /> },
        { path: 'adminmanager', element: <Adminmanager /> },
        { path: 'adminuser', element: <AdminUser /> },
        { path: 'accounts', element: <Accounts /> },
        { path: 'adminlicense', element: <AdminLicense /> },
        { path: 'qrlicense', element: user?.isSuperAdmin ? <QRLicense /> : <QRLicenseAdmin /> },
        { path: 'modules', element: <ModulesList /> },
        { path: 'feedback-response', element: <Feedback /> },
        { path: 'services', element: <Services /> },
        { path: 'accountmanager', element: <AccountManager /> },
        { path: 'hospital-information', element: <HospitalInformation /> },
        { path: 'service', element: <ServiceSection /> },
        { path: 'feedback', element: <FeedbackSection /> },
        { path: 'requests', element: <RequestList /> },
        { path: 'request-dashboard', element: <RequestDashboard /> },

      ],
    },
  ]);


  return routes;
}
export default RoutesProvider;