import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  CircularProgress,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// components
import PhotoIcon from '@mui/icons-material/Photo';
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import CustomSnackBar from '../../components/snackbar';
import MenuPopover from '../../components/popover/menuPopover'
// sections
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import AdminUserDialog from '../../sections/dialogs/adminUserDialog';
// mock
import USERLIST from '../../_mock/user';
// api
import { getRequest, postRequestStatus } from '../../api/admin/request';
import StatusPopover from '../../components/popover/statusPopover';
import { RequestFilter } from '../../sections/@dashboard/request';
import StatusDialog from '../../sections/dialogs/StatusDialog';
import ImageDialog from '../../sections/dialogs/imageDialog';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'service', label: 'Service', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'mobile', label: 'mobile', alignRight: false },
  { id: 'bed', label: 'Bed', alignRight: false },
  { id: 'room', label: 'Room', alignRight: false },
  { id: 'Floor', label: 'Floor', alignRight: false },
  { id: 'Attachments', label: 'Attachments', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },

  { id: '' },
];
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function RequestList() {

  const formData = new FormData();

  const location = useLocation();
  // console.log('loc',location?.state?.filter);
  const {hospitalAccount} = useSelector((state) => state.account)
  const userRole = useSelector((state) => state.adminUser.user.role)

  // snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState(null)


  const [requests, setRequests] = useState(null);
  const [tempRequests, setTempRequests] = useState(null);
  const [isLoading, setIsLoading] = useState(false)

  const [selectedId, setSelectedId] = useState(false)
  const [selectedItem, setSelectedItem] = useState(false)

  const [dialogOpen, setDialogOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const [action, setAction] = useState('');

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [filterData, setFilterData] = useState();

  const [imageDialogOpen, setImageDialogOpen] = useState(false)

  const [selectedImage, setSelectedImage] = useState(false)


  const fetchData = async () => {
    setIsLoading(true)
    try {
      const {data} = await getRequest();
      if(data.success)
      {
        console.log('data');
        setRequests(data.requests)
        setTempRequests(data.requests)
        setFilterData(location.state?.filter ? location.state?.filter : 'all')

      }

      setIsLoading(false)
    }
    catch (e) {
      setIsLoading(false)
      // console.log({ e });
    }
  }

  // get admins list
  useEffect(() => {
    fetchData();
  }, []);

   // get admins list
   useEffect(() => {
    // console.log({filterData});
    if(requests)
    {
      handleFilterByStatus();
    }

  }, [filterData]);


  const handleFilterByStatus = () => {


      if(filterData === 'all' )
      {
        setRequests(tempRequests)
      }
      else{
          // console.log('out', tempRequests?.filter((item) => item.status === filterData));

        setRequests(tempRequests?.filter((item) => item.status === filterData))
      }

  };

  const handleImageDialogOpen = (file) => {
    // console.log(item);
    setSelectedImage(file)
   setImageDialogOpen(true)
  };


  const handleOpenMenu = (event, id) => {
    // console.log(item);
    setSelectedId(id)
    setMenuOpen(event.currentTarget);
  };

  // Dialog
  const handleDialogOpen = async (str) => {

   setSelectedItem(str)
   if(str === 'done')
    {
      setMenuOpen(false)
      setDialogOpen(true);
    }
    else{
      formData.append('status', str)
      try {
        const { data } = await postRequestStatus(selectedId && selectedId, formData);
        if(data.success)
        {
          const {data:requestData} = await getRequest();
          setRequests(requestData.requests)
        }
        setMenuOpen(null)
        setSnackbarMessage({ msg: data.message, success: true });
        setSnackbarOpen(true);
      }
      catch (e) {
        setSnackbarMessage({ msg: e.message, success: false });
        setSnackbarOpen(true);
      }
    }
  };



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;


  return (
    <>
      <Helmet>
        <title> Requests | Teller One </title>
      </Helmet>

      <Box sx={{ height: '100%' }}>
        {!isLoading ? (
          <Box >

            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                Total Requests
              </Typography>
             <RequestFilter setFilterData = {setFilterData} filterData = {filterData}/>
            </Stack>

            <Card>
              {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={USERLIST.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {requests && requests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const { _id, patientName, mobileNo,  status, serviceTitle,  subServices, fileLink, qrDetails : { bedNo, roomNo, floor} } = row;

                        return (
                          <TableRow hover key={_id} tabIndex={-1} role="checkbox" >
                            {/* <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                        </TableCell> */}

                            <TableCell component="th" >
                              <Stack alignItems="center" spacing={2}>
                                {/* <Avatar alt={name} src={avatarUrl} /> */}
                                <Typography variant="subtitle1" noWrap>
                                  {serviceTitle}
                                </Typography>
                                {subServices.map((item) => item.checked && <Typography variant="subtitle2">{item.subServiceTitle}</Typography> ) }
                              </Stack>
                            </TableCell>


                            <TableCell align="left">{patientName}</TableCell>
                            <TableCell align="left">{mobileNo} </TableCell>
                            <TableCell align="left">{bedNo}</TableCell>
                            <TableCell align="left">{roomNo} </TableCell>
                            <TableCell align="left">{floor}</TableCell>
                            <TableCell align="left"><PhotoIcon sx={{cursor : 'pointer'}} onClick={() => handleImageDialogOpen(fileLink)}/></TableCell>
                            <TableCell align="left">
                              <Label >{status}</Label>
                            </TableCell>
                          {(userRole === 'user' || userRole === 'poweruser' || userRole === 'viewer') &&
                            <TableCell align="right">
                              <Button variant='contained' onClick={(e) => handleOpenMenu(e, _id)}>Change Status</Button>
                            </TableCell>}
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                Not found
                              </Typography>

                              <Typography variant="body2">
                                No results found for &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                                <br /> Try checking for typos or using complete words.
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>

              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={requests?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>

          </Box>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}> <CircularProgress /> </Box>
        )}
      </Box>
      <ImageDialog dialogOpen={imageDialogOpen} setDialogOpen={setImageDialogOpen} imageFile={selectedImage}/>
      <StatusDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} setSnackbarOpen={setSnackbarOpen} setSnackbarMessage={setSnackbarMessage} selectedId={selectedId} selectedItem={selectedItem} setRequests = {setRequests}/>
      <StatusPopover menuOpen={menuOpen} setMenuOpen={setMenuOpen} handleDialogOpen={handleDialogOpen} />
      <CustomSnackBar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarMessage={snackbarMessage && snackbarMessage.msg} success={snackbarMessage && snackbarMessage.success} />
    </>
  );
}
