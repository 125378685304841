// Auth
export const SET_AUTH_DATA = "setAuthData";
export const SHOW_OTP_MODAL = "showOTPModal";

//
export const SET_ADMIN_USER_STATE = "setAdminUserState";
export const SET_USER_TOKEN = "setUserToken";
export const SET_USER_DETAILS = "setUserDetails";
export const SET_USER_DETAILS_AND_TOKEN = "setUserDetailsAndToken";

export const LOGIN_SUCCESS = "setLoginSuccess";
export const LOGIN_FAILURE = "setLoginFailure";

export const REGISTER_SUCCESS = "setRegisterSuccess";
export const REGISTER_FAILURE = "setRegisterFailure";

// General Alert
export const SET_SUCCESS_ALERT = "setSuccessAlert";
export const SET_FAILURE_ALERT = "setFailureAlert";

// Admin User
export const SET_ADMIN_USER_LIST = "setAdminUserList"
export const GET_ADMIN_USER = "getAdminUser"
export const CREATE_ADMIN_USER = "createAdminUser"
export const UPDATE_ADMIN_USER = "updateAdminUser"
export const DELETE_ADMIN_USER = "deleteAdminUser"

// Account
export const SET_ACCOUNT_STATE = "setAccountState"


export const LOGOUT_USER = 'logoutUser';