import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Box, CircularProgress } from '@mui/material';
// components
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../../sections/@dashboard/app';

import { getRequestData } from '../../api/admin/request';

const getColor = ['secondary', 'info', 'warning', 'error']
// ----------------------------------------------------------------------

export default function RequestDashboard() {
  const theme = useTheme();
  const navigate = useNavigate();
  const adminUserState = useSelector((state) => state.adminUser)
  const { isAuthenticated, user } = adminUserState
  const hospital = useSelector((state) => state.account.hospitalAccount)

  const [isLoading, setIsLoading] = useState(false)
  const [requestData, setRequestData] = useState(false)
  // console.log(hospital)
  const fetchData = async () => {
    setIsLoading(true)
    try {
      const { data } = await getRequestData(hospital._id);
      setRequestData(data)
      console.log(data);
      setIsLoading(false)
    }
    catch (e) {
      setIsLoading(false)
      // console.log({ e });
    }
  }

  // get admins list
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard | Teller One </title>
      </Helmet>
      <Box sx={{ height: '100%' }}>
        {!isLoading ?
          <Box >
            <Typography variant="h4" sx={{ mb: 5 }}>
              Hi, Welcome back
            </Typography>
            {requestData &&
              <Grid container spacing={3}>
                {Object.entries(requestData.statusCount).map(([key, value], ind) => (
                  <Grid item xs={12} sm={6} md={3} key={ind} onClick = {() => navigate('/requests', {state: {filter : key}})} sx={{cursor: 'pointer'}}>
                    <AppWidgetSummary title={key} total={value} icon={'icon-park-outline:pull-requests'} color={getColor[ind]} />
                  </Grid>
                ))}
               
              </Grid>}
          </Box>
          :
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}><CircularProgress /></Box>}
      </Box>
    </>
  );
}
