import { ADD_SERVICES_API_URL, ADD_SUB_SERVICES_API_URL, SERVICES_API_URL} from "../routes";
import { createApiConfig } from "../config";


const apiConfig = createApiConfig();

// CREATE services

export const addServices = (hospitalId,data) => {
    console.log('Services Added');
    return apiConfig.post(`${ADD_SERVICES_API_URL}/${hospitalId}`, data);
};

// Update services

export const updateServices = (hospitalId,data) => {
    console.log('Service Updated');
    return apiConfig.put(`${ADD_SERVICES_API_URL}/${hospitalId}`, data);
};

// // Delete services

// export const updateServices = (id,data) => {
//     return apiConfig.put(`${SERVICES_API_URL}/${id}`, data);
// };

// CREATE subservices

export const addSubServices = (serviceId,data) => {
    return apiConfig.post(`${ADD_SUB_SERVICES_API_URL}/${serviceId}`, data);
};

// Update services

export const updateSubServices = (id,data) => {
    return apiConfig.put(`${SERVICES_API_URL}/${id}`, data);
};

// DELETE subServices

export const deleteSubServices = (id) => {
    return apiConfig.delete(`${SERVICES_API_URL}/${id}`);
};
