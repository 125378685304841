import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  CircularProgress,
  Chip,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import CustomSnackBar from '../../components/snackbar';
import MenuPopover from '../../components/popover/menuPopover';
// sections
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import AccountDialog from '../../sections/dialogs/accountDialog';
import { setAdminUserStateAction } from "../../appRedux/actions/adminUser";

// mock
import USERLIST from '../../_mock/user';
// api
import { getAccounts, deleteAccount, approveAccount, exportAccounts, suspendAccount, holdAccount } from '../../api/superadmin/account';
import { getCommonDataWithToken } from '../../api/superadmin/commonAPIs';
import AccountFilter from '../../sections/@dashboard/request/AccountFilter';

// ----------------------------------------------------------------------

const LICENSE_MANAGER_TABLE_HEAD = [
  { id: 'name', label: 'Account Name', alignRight: false },
  { id: 'accountmanager', label: 'Account Manager', alignRight: false },
  { id: 'location', label: 'Location', alignRight: false },
  { id: 'modules', label: 'Modules', alignRight: false },
  { id: 'qrlicense', label: 'QR License', alignRight: false },
  { id: 'adminlicense', label: 'Admin License', alignRight: false },
  { id: 'startdate', label: 'Start Date', alignRight: false },
  { id: 'expirydate', label: 'Expiry Date', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Accounts() {

  const dispatch = useDispatch()

  const location = useLocation();

  const loggedUser = useSelector((state) => state.adminUser)

  const { accountManagers } = loggedUser.commonData

  const [accounts, setAccounts] = useState(null);
  const [tempAccounts, setTempAccounts] = useState(null);


  const [isLoading, setIsLoading] = useState(false)

  const [isRejectButtonLoading, setIsRejectButtonLoading] = useState(false)

  const [isApproveButtonLoading, setIsApproveButtonLoading] = useState(false)
  const [isHoldButtonLoading, setIsHoldButtonLoading] = useState(false)
  const [isExportLoading, setIsExportLoading] = useState(false)

  // snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState(null)

  const [selectedItem, setSelectedItem] = useState(false)
  const [selectedId, setSelectedId] = useState(false)

  const [dialogOpen, setDialogOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const [action, setAction] = useState('');

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [filterData, setFilterData] = useState();

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const { data } = await getAccounts();
      setAccounts(data.hospitalAccounts)
      setTempAccounts(data.hospitalAccounts)
      setFilterData(location.state?.filter ? location.state?.filter : 'all')
      // console.log(data.hospitalAccounts);
      setIsLoading(false)
    }
    catch (e) {
      setIsLoading(false)
      // console.log({ e });
    }

  }

  // get accounts list
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    console.log({ filterData });
    if (tempAccounts) {
      handleFilterByStatus();
    }

  }, [filterData]);


  const handleFilterByStatus = () => {


    if (filterData === 'all') {
      setAccounts(tempAccounts)
    }
    else if(filterData === 'inreview'){
      setAccounts(tempAccounts?.filter((item) => item.status === 'create-review' || item.status === 'edit-review' || item.status === 'suspend-review' || item.status === 'delete-review'))
    }
    else {
      // console.log('out', tempAccounts?.filter((item) => item.status === filterData));

      setAccounts(tempAccounts?.filter((item) => item.status === filterData))
    }

  };

  const handleDelete = async (id) => {
    // console.log(id);
    try {
      const { data } = await deleteAccount(id);
      setMenuOpen(false)
      setAccounts(data.hospitalAccounts)
      setIsLoading(false)
      setSnackbarMessage({ msg: data.message, success: true });
      setSnackbarOpen(true);
    }
    catch (e) {
      setIsLoading(false)
      setSnackbarMessage({ msg: e.message, success: false });
      setSnackbarOpen(true);
    }
  }

  const handleSuspend = async (id) => {
    // console.log(id);
    try {
      const { data } = await suspendAccount(id);

      setMenuOpen(false)
      setAccounts(data.hospitalAccounts)
      setIsLoading(false)
      setSnackbarMessage({ msg: data.message, success: true });
      setSnackbarOpen(true);
    }
    catch (e) {
      setIsLoading(false)
      setSnackbarMessage({ msg: e.message, success: false });
      setSnackbarOpen(true);
    }
  }


  const handleOpenMenu = (event, item) => {
    // console.log(item);
    setSelectedItem(item)
    setMenuOpen(event.currentTarget);
  };

  // Dialog
  const handleDialogOpen = (item, str) => {
    // console.log(str,item);
    if (item) {
      setSelectedItem(item)
    }
    setAction(str)
    setDialogOpen(true);
  };

  const handleApprove = async (id, flag) => {
    setSelectedId(id)
    if (flag) {
      setIsApproveButtonLoading(true)
    }
    else {
      setIsRejectButtonLoading(true)
    }

    try {
      const { data } = await approveAccount(id, { approve: flag });
      if (data.success) {
        const result = await getCommonDataWithToken();
        dispatch(setAdminUserStateAction({
          commonData: result.data.commondata
        }))
      }
      setIsApproveButtonLoading(false)
      setIsRejectButtonLoading(false)
      setAccounts(data.hospitalAccounts)
      setSnackbarMessage({ msg: data.message, success: true });
      setSnackbarOpen(true);
    }
    catch (e) {
      setIsApproveButtonLoading(false)
      setIsRejectButtonLoading(false)
      setSnackbarMessage({ msg: e.message, success: false });
      setSnackbarOpen(true);
    }
  };

  const handleHold = async (id) => {
setSelectedId(id)
    setIsHoldButtonLoading(true)

    try {
      const { data } = await holdAccount(id);
      setIsHoldButtonLoading(false)

      setMenuOpen(false)
      setAccounts(data.hospitalAccounts)
      setSnackbarMessage({ msg: data.message, success: true });
      setSnackbarOpen(true);
    }
    catch (e) {
      setIsHoldButtonLoading(false)
      setSnackbarMessage({ msg: e.message, success: false });
      setSnackbarOpen(true);
    }
  };

  const handleExport = async (event) => {
    setIsExportLoading(true)
    try {
      const { data } = await exportAccounts();
      if (data) {
        const filename = `Accounts.xlsx`
        // console.log({ data });
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        if (typeof window.navigator.msSaveBlob === 'function') {
          window.navigator.msSaveBlob(
            data,
            filename
          );
        } else {
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        }
        setIsExportLoading(false);
        setSnackbarMessage({ msg: 'Successfully exported', success: true })
        setSnackbarOpen(true)
      }
    }
    catch (e) {
      setIsExportLoading(false)
      setSnackbarMessage({ msg: 'Export failed', success: false })
      setSnackbarOpen(true)
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;
  return (
    <>
      <Helmet>
        <title> Accounts | Teller One </title>
      </Helmet>

      <Box sx={{ height: '100%' }}>
        {!isLoading ? (
          <Box>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                Account List
              </Typography>
              {loggedUser.user.role === 'licensemanager' &&
                <Button variant="contained" sx={{borderRadius:2}} startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => handleDialogOpen(null, 'CREATE')}>
                  Create Account
                </Button>}
              {loggedUser.user.role === 'accountmanager' &&
                <LoadingButton loading={isExportLoading} variant="contained" startIcon={<Iconify icon="material-symbols:download" />} onClick={handleExport}>
                  Export
                </LoadingButton>}
            </Stack>
            <Card >
              {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}
              <Stack direction="row" alignItems="center" justifyContent="flex-end" mx={5}>
                <AccountFilter setFilterData={setFilterData} filterData={filterData} />
              </Stack>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 1000 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={LICENSE_MANAGER_TABLE_HEAD}
                      rowCount={USERLIST.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {accounts && accounts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const { _id, hospitalName, modules, adminLicense, qrLicense, location, status, accountManager, startDate,endDate} = row;

                        return (
                          <TableRow hover key={_id} tabIndex={-1} role="checkbox" >
                            {/* <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                        </TableCell> */}

                            <TableCell component="th" scope="row" >
                              <Stack direction="row" alignItems="center" spacing={2}>
                                {/* <Avatar alt={name} src={avatarUrl} /> */}
                                <Typography variant="subtitle2" noWrap>
                                  {hospitalName}
                                </Typography>
                              </Stack>
                            </TableCell>
                            {/* <TableCell align="left">{license.length ? license.map((item,ind) => { return (`${item.licenseName}${license.length !== ind + 1 ? ', ' : ''} `) }) : <p>NIL</p>}</TableCell> */}
                            <TableCell align="left">{accountManager?.firstName} {accountManager?.lastName}</TableCell>
                            <TableCell align="left">{location}</TableCell>
                            <TableCell align="left">{modules.map((item, ind) => item.licenseStatus === 'active' && <Chip label={item.moduleName} key={ind} />)}</TableCell>
                            <TableCell align="left">{qrLicense}</TableCell>
                            <TableCell align="left">{adminLicense}</TableCell>
                            <TableCell align="left" sx={{width:160}}>{startDate.split('T')[0]}</TableCell>
                            <TableCell align="left" sx={{width:160}}>{endDate.split('T')[0]}</TableCell>
                            <TableCell align="left">
                              <Label color={status !== 'active' ? 'error' : 'success'}>{status}</Label>
                            </TableCell>

                            <TableCell align="right">
                              {loggedUser.user.role === 'licensemanager' &&
                                <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, row)}>
                                  <Iconify icon={'eva:more-vertical-fill'} />
                                </IconButton>
                              }
                              {loggedUser.user.role === 'financecontroller' && (status === 'create-review' || status === 'edit-review' || status === 'suspend-review' || status === 'delete-review') &&
                                <Box display='flex'>
                                  <LoadingButton loading={selectedId === _id && isApproveButtonLoading} variant="outlined" color="success" size="small" onClick={() => handleApprove(_id, true)}>
                                    Approve
                                  </LoadingButton>
                                  <LoadingButton loading={ selectedId === _id && isRejectButtonLoading} variant="outlined" color="error" size="small" onClick={() => handleApprove(_id, false)}>
                                    Reject
                                  </LoadingButton>
                                  <LoadingButton loading={selectedId === _id && isHoldButtonLoading} variant="outlined" color="warning" size="small" onClick={() => handleHold(_id)}>
                                    Hold
                                  </LoadingButton>
                                </Box>}
                              {loggedUser.user.role === 'financecontroller' && (status === 'hold' || status === 'rejected' || status === 'suspended') &&
                                <Box display='flex'>
                                  <LoadingButton loading={selectedId === _id && isApproveButtonLoading} variant="outlined" color="success" size="small" onClick={() => handleApprove(_id, true)}>
                                    Activate
                                  </LoadingButton>
                                </Box>}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                Not found
                              </Typography>

                              <Typography variant="body2">
                                No results found for &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                                <br /> Try checking for typos or using complete words.
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>

              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={accounts ? accounts.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}><CircularProgress /></Box>
        )
        }
      </Box>
      {dialogOpen && <AccountDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} setAccounts={setAccounts} setSnackbarMessage={setSnackbarMessage} setSnackbarOpen={setSnackbarOpen} selectedItem={selectedItem} action={action} />}
      <CustomSnackBar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarMessage={snackbarMessage && snackbarMessage.msg} success={snackbarMessage && snackbarMessage.success} />
      <MenuPopover menuOpen={menuOpen} setMenuOpen={setMenuOpen} handleDialogOpen={handleDialogOpen} handleDelete={handleDelete} handleSuspend={handleSuspend} selectedItem={selectedItem} />
    </>
  );
}
