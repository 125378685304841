import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DeleteOutline } from '@mui/icons-material';
import { v4 as uuid } from 'uuid';


// api
import { addFeedbackSection, updateFeedbackSection } from '../../api/admin/feedback';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(userGroupName, userGroup, theme) {
  return {
    fontWeight:
      userGroup.indexOf(userGroupName) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}
const initialState = {
  hospitalId: '',
  row: []
};
const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

export default function FeedbackSectionDialog({
  dialogOpen,
  setDialogOpen,
  action,
  setSnackbarMessage,
  setSnackbarOpen,
  feedbackSection,
  setFeedbackSection
}) {
  const theme = useTheme();

  const [inputs, setInputs] = useState(initialState);
  // console.log(inputs);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([{ id: uuid() }]);
  const {hospitalAccount} = useSelector((state) => state.account)
  const [sectionItems, setSectionItems] = useState([])

  // console.log(loggedUser);


  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log('submit', sectionItems);
    setIsLoading(true)
    let postData = inputs;
    if(feedbackSection)
    {
      postData = [...inputs, ...feedbackSection]
    }
    try {
      const { data } = await updateFeedbackSection(hospitalAccount._id, {sectionTypes:postData})
      if (data.success) {
        // console.log({ data });
        setFeedbackSection(data.feedbackSections.sectionTypes)
        setDialogOpen(false);
        setSnackbarMessage({ msg: data.message, success: false });
        setSnackbarOpen(true);
        setIsLoading(false);

      } else {
        setIsLoading(false);
        setSnackbarMessage({ msg: data.message, success: false });
        setSnackbarOpen(true);
      }
    } catch (error) {
      setIsLoading(false);
      setSnackbarMessage({ msg: error.message, success: false });
      setSnackbarOpen(true);
    }
  };
  
  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleInputChange = (e, val) => {
   console.log(val);
   setSectionItems(val)
  };

  const handleDeleteRow = (id) => {
    //  console.log({rows});
    //  console.log(rows.filter(a => a.id !== id))
    setRows(rows.filter(a => a.id !== id))
  };

  useEffect(() => {
    setInputs(sectionItems.map((item) => ({sectionTypeName : item})))
  }, [sectionItems])

  useEffect(() => {
    setInputs(initialState);
    setRows([])
  }, [dialogOpen])


  return (
    <Box>
      <Dialog open={dialogOpen} onClose={handleClose} >

        <form onSubmit={handleSubmit}>
          <DialogContent>

            <Stack spacing={2} >
              <Typography variant='h5'>Add Feedback Section</Typography>
              <Autocomplete
              multiple
              freeSolo
              id="tags-outlined"
              options={[]}
              onChange={handleInputChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Add sections"
                />
              )}
            />
            </Stack>
            <Typography variant='subtitle2' color='red'>*Enter to add service items</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant='contained'>Cancel</Button>
            <LoadingButton loading={isLoading} type='submit' variant='contained'>Generate</LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  )
}
